import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { postFunctionsApi } from '../../utils';
import { ButtonFooter, ContentCol, RadioList } from '../../components';
import { ModalBase } from '../../components/Modal';
import { toast } from 'react-toastify';
import { PiCustomer, User, UserLabel } from '../../../../shared/schema/index';

type ChangeUserRefModalProps = {
    piCustomer: PiCustomer;
    admins: UserLabel[],
    open: boolean;
    onClose: () => void;
};

const ChangeUserRefModal = ({piCustomer, admins, open, onClose}: ChangeUserRefModalProps) => {
    const { t } = useTranslation('admin');
    const [userRef, setUserRef] = useState<UserLabel>(piCustomer.user as User);

    const onChangeUserRef = async() => {
        if (userRef?.objectId === (piCustomer.user as User).objectId) return onClose();
        const changeUserRef = await postFunctionsApi<string>('changeCustomerUserRef', {
            piCustomerId: piCustomer.objectId,
            userRefId: userRef.objectId
        })
        if (typeof changeUserRef !== 'string') {
            piCustomer.user = userRef;
            toast.success(t('customerAdmin.successChange') as string, {theme: 'colored'});
            return onClose()
        }
        return toast.error(t('customerAdmin.errorChange') as string, {theme: 'colored'});
    }


    return (
        
        <ModalBase open={open} onClose={onClose} containerClass='p-2 sm:p-4' >
      
            <ContentCol >

                <RadioList
                    items={admins}
                    name='userRef'
                    valueSelected={userRef}
                    setValue={setUserRef}
                    col={true}
                />

                <ButtonFooter
                    action='confirm'
                    onValid={() => onChangeUserRef()}
                    onCancel={onClose}
                />

            </ContentCol>          

        </ModalBase>
    );
};

export {ChangeUserRefModal};