import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { PiProductTranslate, postFunctionsApi } from '../../../utils';
import { Address, PiCustomer, PiProduct, Site } from '../../../../../shared/schema/index';
import { ButtonAction, ButtonFooterBase, ContentCol, RadioList, StripeModal, Suspense, TextCenter } from '../../../components';
import AddCustomer from './AddCustomer';
import { SetupIntent } from '@stripe/stripe-js';
import { piProducts } from '../../../params';

type AddSubscriptionProps = {
    setSiteId: (s: string) => void;
    setStep: (n: number) => void;   
};

const AddSubscription = ({setSiteId, setStep}: AddSubscriptionProps) => {
    const { t } = useTranslation('admin');
    const [piPlanSelected, setPiPlanSelected] = useState<PiProduct>();
    const [newPiCustomer, setNewPiCustomer] = useState<Partial<PiCustomer>>({});

    const [navAddSubscription, setNavAddSubscription] = useState<'plan' | 'customer'>('plan');
    const [openModalSubscription, setOpenModalSubscription] = useState<boolean>(false);
    const [saving, setSaving] = useState<boolean>(false);

    const piPlans = piProducts.filter(p => p.name === 'plan'); //TODO and Currency choice
    piPlans.map(p => PiProductTranslate(p))

    const checkCustomer = () => { //TODO adress
        if (newPiCustomer) {
            if (!(newPiCustomer.typeCustomer === 'individual' || newPiCustomer.typeCustomer === 'society')) {
                toast.error(t('addCustomer.error.typeCustomer') as string, {theme: 'colored'}); 
                return false
            }
        
            if (newPiCustomer.typeCustomer === 'society' &&
            (!newPiCustomer.society || newPiCustomer.society.name === '')) {       
                toast.error(t('addCustomer.error.society') as string, {theme: 'colored'});
                return false
            }

            if ((newPiCustomer.address as Address).country === '' ||
                (newPiCustomer.address as Address).street === '' ||
                (newPiCustomer.address as Address).city === '' ||
                (newPiCustomer.address as Address).zipCode === ''
            ) return toast.error(t('address.errorAddress') as string, {theme: 'colored'})

            return true
        }

        return false
    }

    const createStripeSub = async(): Promise<void> => {
        if (piPlanSelected && newPiCustomer && checkCustomer()) {
            setOpenModalSubscription(true)
        } else {
            //TODO error
        }
    };

    const createNewSite = async(stripeSI?: SetupIntent) => {
        setSaving(true);
        const newSite = await postFunctionsApi<Site>('createNewSite', {
            productCode: piPlanSelected?.code,
            piCustomer: newPiCustomer,
            stripeSI: stripeSI
        });
        console.log(newSite)
        setSaving(false);
        if (typeof newSite !== 'string' && newSite.siteId) {
            setSiteId(newSite.siteId)
            return setStep(3)
        }
        toast.error(t('addCustomer.error.retry') as string, {theme: 'colored'});
    }

    const onNav = async() => {
        if (navAddSubscription === 'plan') {

            if (!piPlanSelected) toast.error(t('addCustomer.error.piPlan') as string, {theme: 'colored'}); 
            
            if (piPlanSelected && piPlanSelected.type !== 'free') return setNavAddSubscription('customer');

            if (piPlanSelected && piPlanSelected.type === 'free') return createNewSite()

            return setNavAddSubscription('plan');
        }
        
        if (navAddSubscription === 'customer') createStripeSub()
    }

    return (
        <>
        <Suspense showIf={saving} />

        <ContentCol>

            <TextCenter text={t('addSubscribe.title')} containerClass="text-lg mb-2" />

            <RadioList
                items={piPlans}
                name='plan'
                valueSelected={piPlanSelected}
                setValue={setPiPlanSelected}
                col={true}
            />

            <ContentCol showIf={piPlanSelected !== undefined && piPlanSelected.type !== 'free'}>

                <AddCustomer
                    customer={newPiCustomer}
                    setCustomer={setNewPiCustomer}
                />

            </ContentCol>

            <ButtonFooterBase>

                <ButtonAction showIf={piPlanSelected !== undefined}
                    action={piPlanSelected?.type === 'free' ? 'next' : 'payment'}
                    className="w-full"
                    onClick={() => onNav()}
                />

            </ButtonFooterBase>

           
            <StripeModal
                product={piPlanSelected}
                open={openModalSubscription}
                onConfirm={createNewSite}
                onClose={() => setOpenModalSubscription(false)}
            />

        </ContentCol>
        </>
    );
};

export default AddSubscription;