import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonText, Card, ContentWrap } from '../../../components';
import { PlaceList } from './PlaceList';
import { ArticleList } from './ArticleList';


type DataNav = 'place' | 'presentation' | 'calendar' | 'price' | 'article' 


const DataList = () => {
    const { t } = useTranslation(['datasite']);
    const [nav, setNav] = useState<DataNav>('article');  

    return (


        <Card>

            <ContentWrap className='justify-center'>

                <ButtonText showIf={nav !== 'place'}
                    text={t('data.place')}
                    variant='filled-primary-lg'
                    onClick={() => setNav('place')}
                    className='mx-1 mb-1'
                />

                <ButtonText showIf={nav !== 'presentation'}
                    text={t('data.presentation')}
                    variant='filled-primary-lg'
                    onClick={() => setNav('presentation')}
                    className='mx-1 mb-1'
                />

                <ButtonText showIf={nav !== 'calendar'}
                    text={t('data.calendar')}
                    variant='filled-primary-lg'
                    onClick={() => setNav('calendar')}
                    className='mx-1 mb-1'
                />

                <ButtonText showIf={nav !== 'price'}
                    text={t('data.price')}
                    variant='filled-primary-lg'
                    onClick={() => setNav('price')}
                    className='mx-1 mb-1'
                />

                <ButtonText showIf={nav !== 'article'}
                    text={t('data.article')}
                    variant='filled-primary-lg'
                    onClick={() => setNav('article')}
                    className='mx-1 mb-1'
                />

            </ContentWrap>

            {nav === 'place' &&
            <PlaceList />
            }

            {nav === 'presentation' &&
            <div></div>
            }

            {nav === 'calendar' &&
            <div></div>
            }

            {nav === 'price' &&
            <div></div>
            }

            {nav === 'article' &&
            <ArticleList />
            }
            
        </Card>

    )
}

export { DataList };