import { useTranslation } from 'react-i18next';
import { Member, RelationType } from '../../../../../shared/schema/index';
import { ButtonIcon, ContentCol, ContentWrap, SubCard, TextBase, Content} from '../../../components';

type MemberPosterCardProps = {
    member: Member,
    relationType?: RelationType;
    onAction: (a: 'edit' | 'delete') => void
}

const MemberPosterCard = ({member, relationType, onAction}: MemberPosterCardProps) => { 
    const { t } = useTranslation(['member']);

    return (
        <SubCard className={relationType ? 'bg-primary bg-opacity-10'  : ''} >

            <Content className='flex justify-between'>

                <ContentCol className='text-lg'>
                    <ContentWrap className='text-lg font-semibold'>
                        <div className={['h-2 w-2 rounded-full mr-1', member.isMember ? 'bg-success' : 'bg-danger'].join(' ')} />
                        {member.gender && <div className='mr-1'>{t('gender', {context: member.gender})}</div>}
                        <div className='mr-1'>{member.firstName}</div>
                        <div className='mr-1'>{member.lastName}</div>
                    </ContentWrap>

                    {relationType &&
                    <>
                    <div className='-mt-2'>{relationType ? t('relationTitle') + ' ' + t('relationType', {context: relationType} ) : null}</div>
                    <div className='-mt-2'>{relationType && member.emailing ? member.email : null}</div>
                    </>
                    }

                    {member.phone && !relationType &&
                        <a href={'tel:' + member.phone} >
                            <TextBase icon='phone' title={member.phone} iconClass='mr-1'/>
                        </a>
                    }

                    {!relationType && member.email && member.emailing &&
                        <a href={'mailto:' + member.email} >
                            <TextBase icon='email' title={member.email} iconClass='mr-1'/>
                        </a>
                    }

                </ContentCol>

                <Content className='w-8'>
                    <ButtonIcon icon='edit' className='h-8 w-8 flex justify-center items-center rounded-full bg-primary text-secondary mb-2' iconClass='w-5 h-5'
                        onClick={() => onAction('edit')}
                    >
                    </ButtonIcon>

                    <ButtonIcon icon='delete' className='h-8 w-8 flex justify-center items-center rounded-full bg-danger text-white' iconClass='w-5 h-5'
                        onClick={() => onAction('delete')}
                    >
                    </ButtonIcon>
                </Content>

            </Content>

        </SubCard>
    );
};


export { MemberPosterCard };