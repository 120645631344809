import { useTranslation } from 'react-i18next';
import { Article } from '../../../../../shared/schema/';
import { SubCard, Text, ButtonIcon, Content, ContentCol, Title, ContentWrap, TextEditorReader, ButtonAction } from '../../../components';
import { useState } from 'react';

type ArticlePostCardProps = {
    key: React.Key;
    article: Article;
    onEdit: (a: Article) => void
    onDelete: (a: Article) => void;
}

const ArticlePostCard = ({article, onDelete, onEdit}: ArticlePostCardProps) => { 
    const { t } = useTranslation(['member']);
    const [showView, setShowView] = useState<boolean>(false)
    
    return (

        <SubCard >

            <Content className='flex justify-between'>

                <ContentCol className='text-lg'>

                    <Title containerClass='truncate' text={article.title}/>

                    <ContentWrap>

                        <Text containerClass='text-left mr-3' text={new Date(article.updatedAt as string).toLocaleDateString()}/>
                        <Text containerClass='text-left italic' text={article.authorName}/>

                    </ContentWrap>

                    <ButtonAction showIf={showView}
                        action='modify'
                        className='w-fit'
                        onClick={() => onEdit(article)}
                    />

                </ContentCol>

                <Content className='w-8'>

                    <ButtonIcon
                        icon={showView ? 'visibilityOff' : 'visibility'  }
                        className='h-8 w-8 flex justify-center items-center rounded-full bg-primary text-secondary mb-2'
                        iconClass='w-5 h-5'
                        onClick={() => setShowView(!showView)}
                    />

                    <ButtonIcon
                        icon='delete'
                        className='h-8 w-8 flex justify-center items-center rounded-full bg-danger text-white'
                        iconClass='w-5 h-5'
                        onClick={() => onDelete(article)}
                    />
                    
                </Content>

            </Content>

            {showView &&
            <SubCard>
                <TextEditorReader value={article.text as string}/>
            </SubCard>
            }

        </SubCard>
  
    );
};


export { ArticlePostCard };