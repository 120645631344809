import { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { fontList } from '../../params';
import { AuthCtx } from '../_context/Context';
import { ButtonAction, ButtonFooter, Card, CardTitle, ContentCol, RadioList } from '../../components';

type FontSettingProps = {
  font: string;
  setFont: (u: string) => void;
  saveSetting: () => void;
  open?: boolean;
}

const FontSetting = ({font, setFont, saveSetting, open}:FontSettingProps) => {
    const [auth] = useContext(AuthCtx);
    const { t } = useTranslation(['siteSetting', 'error']);
    const [openFont, setOpenFont] = useState<boolean>(open ? open : false);

    return (

        <Card>
            <CardTitle
                title={t('font')}
                text={auth.siteSetting.font}
            >
                <ButtonAction action='modify' showIf={!openFont} className='ml-2' onClick={() => setOpenFont(true)} />
            </CardTitle>
       
            <ContentCol showIf={openFont} >
        
                <RadioList
                    items={fontList}
                    name='font'
                    valueSelected={font}
                    setValue={setFont}
                    containerClass='font-Cormorant font-DancingScript font-Roboto font-Montserrat font-Eczar font-Merriweather'
                    font={true}
                />
                  

                <ButtonFooter
                    action='apply'
                    ifAction={font !== auth.siteSetting.font}
                    onValid={() => {
                        saveSetting();
                        setOpenFont(false)
                    }}
                    onCancel={() => {
                        setFont(auth.siteSetting.font)
                        setOpenFont(false)
                    }}
                />

            </ContentCol>
            
        </Card>

    
    );
};

export { FontSetting };