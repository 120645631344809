import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getClassApi } from '../../../utils';
import { AuthCtx } from '../../_context/Context';
import { MemberImport } from '../../../../../shared/schema/index';
import { Pagination, Suspense, SubCard, Text } from '../../../components';


const ImportList = () => {
    const [auth] = useContext(AuthCtx);
    const { t } = useTranslation(['member']);

    const [imports, setImports] = useState<MemberImport[]>([]);
    const [memberFilterPagination, setMemberFilterPagination] = useState<number>(0);     
    const [refreshList, setRefreshList] = useState<boolean>(true);


    const [loading, setLoading] = useState<boolean>(true);
    const nbItemsPerPage: number = 20; 

    useEffect(() => {
        async function fetchData() {
            const getImport = await getClassApi<MemberImport>('MemberImport', {
                "count": 1,
                "order": 'createdAt',
                "limit": nbItemsPerPage,
                "skip": memberFilterPagination*nbItemsPerPage,
            });
            
            if (typeof getImport !== 'string') {
                setImports(getImport.results);
                setRefreshList(false);   
            }
            setLoading(false);        
        }
        fetchData();
    },[memberFilterPagination, refreshList, setLoading]);
    
    return (


        <SubCard className='mt-4'>

            <Text text={t('fileList.title')} textClass='font-semiBold' />

            {imports.map((i, k) => (
            
                <div key={k}>
                    
                </div>
            
            ))}


            <Pagination
                nbItems={imports.length}
                nbItemsPerPage={nbItemsPerPage}
                onChangePage={setMemberFilterPagination}
                initPagination={memberFilterPagination}
            />



            <Suspense showIf={loading} />
                    
        </SubCard>

    );
};

export { ImportList };