import { View } from "../../components";


export interface EventNav {
  action: 'list' | 'default',
  section: 'week' | 'day',
};

const EventView = () => {
    

  return (
    <View>


      <div className='mb-2'>


      </div>


    </View>
  );
};

export { EventView };