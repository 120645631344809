import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ButtonText } from '../../components';

//type NoRoleProps = {
  //setCreateSite: (c: boolean) => void;
  //setNoRole: (c: boolean) => void;
//}

const NoRole = () => {
    const { t } = useTranslation(['auth', 'nav']);

    return (
      <div className="w-full h-full bg-primary">
        <div className="w-full h-full flex flex-col items-center bg-login-pattern bg-cover bg-no-repeat overflow-auto">
          <div className="w-80 sm:w-96 flex justify-center bg-primary/80 flex-col px-1 my-12">
            <header className="w-80 sm:w-96 mb-10 pr-4 pt-4">
              <h1 className='text-center text-2xl text-white mt-2'>
                {t('siteName')}
              </h1>
              <h2 className='text-center text-xl italic text-white mt-4'>
                {t('noRole.title')}
              </h2>
            </header>
  
            <div className='flex flex-col jusify-center mb-6'>

              <ButtonText text={t('noRole.createSite')}
                className='text-white text-lg font-bold border-2 border-white w-full py-2 rounded-full transition duration-300 hover:bg-white hover:text-primary my-2'
                onClick={() => {}}
              />
            </div>

            <div className="mt-6 flex justify-center">
              <Link to={t('home.path', {ns: ['nav']})} className="text-white font-bold text-lg transition duration-300 hover:opacity-80">
                {t('backHome')}
              </Link>
            </div>
          
          </div>
        </div>
      </div>
    );
  };

export { NoRole };