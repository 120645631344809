import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

type SuspensProps = {
  showIf?: boolean;
}

const Suspense = ({showIf = true}: SuspensProps) => {
  const navigate = useNavigate();
  const {t} = useTranslation('nav');

  useEffect(() => {
    if (showIf) {
      let newRender = setInterval(() => {
        navigate('/login');
        toast.error(t('error.connexion') as string , {theme: 'colored'});
      }, 10000);
      
      return ()=> { clearInterval(newRender) };
    }

  }, [t, navigate, showIf]);
  
  return (
    <>
      {showIf &&
      <div className="absolute left-0 top-0 w-full h-full flex justify-center items-center bg-black opacity-70 text-white">
        <div className="w-10 h-10 inline-block border-4 border-white border-r-transparent rounded-full mr-4 spinner"></div>
        <div className="text-2xl">{t('suspense')}</div>
      </div>
      }
    </>
  );
};

export default Suspense;
