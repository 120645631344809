import { useState } from "react";
import ReactQuill from 'react-quill';
import '../quillConfig/piTheme.css';
import { QuillToolbar, modules, formats } from "../quillConfig/Toolbar";


type TextEditorReaderProps = {
    value: string;
    className?: string;
};

const TextEditorReader = ({value, className}: TextEditorReaderProps) => {
    return (
  
        <ReactQuill
            id="reader"
            className={["font-none font-size-none", className].join(' ')}
            theme="snow"
            value={value}
            readOnly={true}
            modules={{toolbar: false}}
        />
   
    )
}

type TextEditorSimplyProps = {
    value: string;
    onChange?: any;
    placeholder?: string;
    readOnly?: boolean;
    theme?: string;
    className: string;
};

const TextEditorSimply = ({value, onChange, placeholder, readOnly, className}: TextEditorSimplyProps) => {
    const module = { toolbar: [ ['bold', 'italic', 'underline']]}
    const formats = [
        "bold",
        "italic",
        "underline"
    ];

    return (
        <div className="text-editor">

            <QuillToolbar />
            
            <ReactQuill
                id="quillsimply"
                className={[
                    "font-none font-size-none",
                    className
                ].join(' ')}
                theme="bubble"
                value={value}
                onChange={onChange}
                placeholder={placeholder}
                modules={module}
   
            />

        </div>
    )
}


type TextDoc = {content: any, delta: any, source: any, editor: any}



type TextEditorProps = {
    text: string;
    onChange: any;
    placeholder?: string;
    className: string;
}

const TextEditor = ({text, onChange, placeholder, className }: TextEditorProps) => {

    return (
        <div className="text-editor">

            <QuillToolbar />
                
            <ReactQuill
                theme='snow'
                value={text}
                onChange={onChange}
                placeholder={placeholder}
                modules={modules}
                formats={formats}
                className={className}
            />

            <div id='modal-editor' ></div>
        
        </div>
    )
}

export  { TextEditor, TextEditorSimply, TextEditorReader};


