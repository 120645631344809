import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { emailCheckFormat, postFunctionsApi } from '../../utils';
import { User } from '../../../../shared/schema/index';
import { Card, CardTitle, ContentCol, InputText, Modal, Text, ButtonAction, InputAlignLeft, ButtonFooterBase } from '../../components';

export type AccountMailProps = {
    user: Partial<User>;
    setUser: (u: Partial<User>) => void;
    onClose: () => void;
};

const EditEmail = ({onClose, user, setUser}: AccountMailProps) => {
    const { t } = useTranslation('account');
    const [email, setEmail] = useState<string>(user.email as string);
    const [changingEmail, setChangingEmail] = useState<string | undefined>(user.emailTmp?.email);
    const [openEmailModal, setOpenEmailModal] = useState<boolean>(false);
    
    const saveEmail = async(cancel?: boolean) => {
        if (!cancel) {
            if (email === user.email) return setOpenEmailModal(false);
            if (!emailCheckFormat(email))
                return toast.error(t('editEmail.invalidEmail') as string, {theme: 'colored'});
        }
        
        const changeEmail = await postFunctionsApi<any>('changeEmailRequest', {email: cancel ? user?.emailTmp : email, old: user.email, cancel: cancel});
        if (typeof changeEmail !== 'string') {
            setOpenEmailModal(false)
            setChangingEmail(cancel ? undefined : email);
            setUser({...user, emailTmp: cancel ? undefined : {email: email, token: ''}});
            if (cancel) setEmail(user.email as string)
            if (!cancel) toast.success(t('editEmail.success.toast') + email, {theme: 'colored'});
            return
        }
        return toast.error(t('editEmail.errorSave') as string, {theme: 'colored'});
    }
    
    return (

        <Card>

            <CardTitle text={t('editEmail.title')} icon='email' />

            <ContentCol>

                <Text text={t('editEmail.description')}/>        
                
                <InputAlignLeft>
                    <InputText
                        showIf={!changingEmail}
                        label={t('editEmail.email')}
                        value={email}
                        labelPosition='leftAlign'
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => setEmail(event.target.value.trim())}
                        autoFocus={true}
                    />
                </InputAlignLeft>
                
                <Text showIf={changingEmail !== undefined} text={t('editEmail.changingEmail') + ' ' + changingEmail}/>

                <ButtonFooterBase>

                    <ButtonAction action='save'
                        showIf={email !== user.email && !changingEmail}
                        onClick={() => setOpenEmailModal(true)}
                        className='mr-4'
                    />
            
                    <ButtonAction action='cancelChange'
                        showIf={changingEmail !== undefined }
                        onClick={() => saveEmail(true)}
                        className=''
                    />

                    <ButtonAction action='cancel'
                        onClick={() => onClose()}
                        className='justify-self-end'
                    />

                </ButtonFooterBase>
                
            </ContentCol>

            <Modal
                variant="warning"
                open={openEmailModal}
                onValid={() => saveEmail()}
                onClose={() => {
                    setOpenEmailModal(false);
                    onClose()
                }}
                title={t('editEmail.modalTitle')}//"Voulez-vous changer d'email ?"
            >
                {t('editEmail.modalText')}
            </Modal>

        </Card>
    );
};

export {EditEmail};