import { ParseObject, Help } from '../../../../shared/schema/index';

const helpObject = (data?: Help): Help => {
    return {
        page: data?.page,
        section: data?.section,
        article: data?.article
    };
}

export { helpObject }
