import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ButtonAction } from '../../components';

const NoMatchView = () => {
  const { t } = useTranslation('auth');
  const navigate = useNavigate()


  return (
    <div className="w-full h-full bg-piPrimary text-white ">
      <div className="w-full h-full flex flex-col items-center bg-login-pattern bg-cover bg-no-repeat overflow-auto">
        <div className="w-80 sm:w-96 flex justify-center flex-col px-1 my-12">
          <header className="w-80 sm:w-96 mb-10 pt-4">
            <h1 className='text-center text-2xl mt-2'>
              {t('siteName')}
            </h1>
            <h2 className='text-center text-xl italic mt-4'>
                {t('noMatch.page404')}
            </h2>
          </header>
        
          <ButtonAction action='comeback' className='w-full' onClick={() => navigate(-1)} />

        </div>
      </div>  
    </div>
  );
};

export default NoMatchView;
