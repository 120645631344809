import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ButtonAction, InputText } from '../../components';
import { postApi } from '../../utils';

const ForgotPasswordView = () => {
  const { t } = useTranslation(['auth', 'nav']);
  const [email, setEmail] = useState('');
  const [isSend, setIsSend] = useState(false);

  const onRequestPasswordReset = async() => {
    const request = await postApi('/requestPasswordReset', {
      email: email.toLowerCase()
    }, {forceInstance: true});
    if (typeof request !== 'string') return setIsSend(true);

    toast.error(t('forgotPassword.retry') as string, {theme: 'colored'});
  };

  return (
    <div className="w-full h-full bg-primary">
      <div className="w-full h-full flex flex-col items-center bg-login-pattern bg-cover bg-no-repeat overflow-auto">
        <div className="w-80 sm:w-96 flex justify-center bg-primary/80 flex-col px-1 my-12">
          <header className="w-80 sm:w-96 mb-10 pr-4 pt-4">
            <h1 className='text-center text-2xl text-white mt-2'>
              {t('siteName')}
            </h1>
            <h2 className='text-center text-xl italic text-white mt-4'>
              {t('forgotPassword.title')}
            </h2>
          </header>

            {!isSend &&
            <>
            <div className='text-center'>
              <h1 className='text-bg font-bold text-white mt-6'>
                {t('forgotPassword.description1')}
              </h1>
              <p className='text-bg font-bold text-white mt-1 mt-2 mb-6'>
                {t('forgotPassword.description2')}
              </p>
            </div>


            <div className="mb-8">
              <label
                htmlFor='email'
                className="block text-white pl-4"
              >
                {t('forgotPassword.emailTitle')}
              </label>
              <InputText id='email'
                type="email"
                value={email}
                onChange={(event: any) => setEmail(event.target.value.trim())}
                placeholder={t('forgotPassword.emailPlaceholder')}
                className="w-full rounded-full py-4 px-8 outline-none text-secondary"
              />
            </div>

            <ButtonAction action='valid'
              className="text-white text-lg font-bold border-2 border-white w-full py-2 rounded-full transition duration-300 hover:bg-white hover:text-primary"
              onClick={() => onRequestPasswordReset()}
            />

            </>
            }

            {isSend &&
            <div className="text-white text-center mt-6">
              <h1 className="text-xl mb-4">
                <div className='mb-4'>{t('forgotPassword.responseTitle')}</div>
                {t('forgotPassword.responseText') + email}
              </h1>
            </div>
            }

            <Link to={'/login'} className="font-bold text-white text-center mt-10">
              {t('backLogin')}
            </Link>
          </div>
        
      </div>
    </div>
  );
};

export default ForgotPasswordView;
