import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Member, MemberRelation, RelationType } from "../../../../../shared/schema";
import { ModalConfirm } from "../../../components";
import { MemberPosterCard } from "./MemberPosterCard";
import { MemberRelationEditCard } from "./MemberRelationEditCard";

type MemberRelationCardProps = {
    key: React.Key;
    relation: MemberRelation;
    memberParent: Member;
    relationTypes: {name: string, label:String}[];
    onSaveRelation: (child: Member, relationTypeParent: RelationType, isUpdateNameEmail: boolean, isUpdateRelation: boolean) => void;
    onDeleteRelation: () => void;
    showEdit: string[];
    setShowEdit: (s: string[]) => void;
}

const MemberRelationCard = ({relation, memberParent, relationTypes, onSaveRelation, onDeleteRelation, showEdit, setShowEdit}: MemberRelationCardProps) => { 
    const { t } = useTranslation(['member']);
    const [showModalDelete, setShowModalDelete] = useState<boolean>(false);

    const switchEdit = () => {
        const id = showEdit.find(id => id === relation.member.objectId)
        if (id) {
            showEdit = showEdit.filter(s => s !== relation.member.objectId);
            setShowEdit(showEdit);
        } else {
            setShowEdit([...showEdit, relation.member.objectId as string])
        }
    }

    return (
        <>
            {!showEdit.find(id => id === relation.member.objectId) &&
            <MemberPosterCard
                member={relation.member as Member}
                relationType={relation.relationType}
                onAction={a => a === 'edit' ? switchEdit() : setShowModalDelete(true)}
            />
            }

            {showEdit.find(id => id === relation.member.objectId) &&
            <MemberRelationEditCard
                relation={relation}
                lastNameParent={memberParent.lastName}
                relationTypes={relationTypes}
                onSaveRelation={onSaveRelation}
                onCancel={() => switchEdit()}
            />
            }

            <ModalConfirm
                title={t('modal.deleteRelation')}
                open={showModalDelete}
                onValid={() => {
                    onDeleteRelation();
                    setShowModalDelete(false);
                }}
                onClose={() => setShowModalDelete(false)}
            />
            
        </>
    );
};

export { MemberRelationCard }