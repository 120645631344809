import { useState } from 'react';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { postFunctionsApi } from '../../utils';
import { ButtonFooter, Card, CardTitle, ContentCol, InputPassword, Modal } from '../../components';

type AccountPasswordProps = {
  onClose: () => void;
};

const ChangePassword = ({onClose}: AccountPasswordProps) => {
  const { t } = useTranslation('account');
  const navigate = useNavigate();
  const [oldPassword, setOldPassword] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [openPasswordModal, setOpenPasswordModal] = useState<boolean>(false);

  const onCheckPassword = () => {
    return (
      oldPassword !== '' &&
      password !== '' &&
      password === passwordConfirmation
    ) ? true : false;
  }

  const savePassword = async () => { 
    setOpenPasswordModal(false);
    const changePassword = await postFunctionsApi<any>('updatePassword', {
      old: oldPassword,
      password: password,
    });

    if (typeof changePassword !== 'string') {
      if (changePassword === true) {
        toast.success(t('editPassword.passwordMofdify') as string, {theme: 'colored'});
        localStorage.removeItem('t');
        navigate('/')

      } else if (changePassword.code === 142) {
        toast.error(t('editPassword.errorSamePassword') as string, {theme: 'colored'});
      } else if (changePassword.code === 141) {
        toast.error(t('editPassword.error') as string, {theme: 'colored'});
      } else if (changePassword.code === 101) {
        toast.error(t('editPassword.errorOld') as string, {theme: 'colored'});
      } else {
        toast.error(t('editPassword.error') as string, {theme: 'colored'});
      }
      return
    }
    toast.error(t('editPassword.error') as string, {theme: 'colored'});
  };

  return (

    <Card>

        <CardTitle text={t('editPassword.title')} icon='key' />

        <ContentCol>

          <ContentCol>  

            <InputPassword
              type='old'
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => setOldPassword(event.target.value)}
              value={oldPassword}
              placeholder={t('editPassword.oldPassword')}
            />
     
            <InputPassword
              type='new'
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => setPassword(event.target.value)}
              value={password}
              placeholder={t('editPassword.newPassword')}
            />
    
            <InputPassword
              type='confirm'
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => setPasswordConfirmation(event.target.value)}
              value={passwordConfirmation}
              placeholder={t('editPassword.confirmPassword')}
            />

          </ContentCol> 

        <ButtonFooter
          action='save'
          ifAction={onCheckPassword()}
          onValid={() => setOpenPasswordModal(true)}
          onCancel={onClose}
        />

      </ContentCol>

      <Modal
        variant="warning"
        open={openPasswordModal}
        onValid={() => savePassword()}
        onClose={() => setOpenPasswordModal(false)}
        title={t('editPassword.modalTitle')}
      >
        {t('editPassword.modalText')}
      </Modal>

    </Card>
  );
};

export default ChangePassword;
