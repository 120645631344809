import { useContext, useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { Gallery, Image, ThumbnailImageProps } from "react-grid-gallery";
import Lightbox from "yet-another-react-lightbox";
import Captions from "yet-another-react-lightbox/plugins/captions";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/captions.css";
import { AuthCtx } from "../_context/Context";
import { b64toBlob, postFunctionsApi } from "../../utils";

export interface CustomImage extends Image {
    filename: string,
    description: string,
    uploadedAt: string
}

let imagess: CustomImage[] = []

const ImageComponent = (props: ThumbnailImageProps) => {
    const [image, setImage] = useState<string>('')
    const { src, alt, style, title } = props.imageProps;
    const siteId = JSON.parse(localStorage.getItem('s') as string);

    console.log(props.imageProps)
    console.log(style)

    useEffect(() => {
        async function fetchImage() {
            const data = await postFunctionsApi<string>('getImage', {siteId: siteId, filename: src})

            if (data !== 'error') {
                
              const blob = b64toBlob(data);
              const blobUrl = URL.createObjectURL(blob);

              //const index = imagess.findIndex(i => i.filename === src)
              //imagess[index].src = blobUrl

              setImage(blobUrl)
            }
        }
        fetchImage()
    },[])
  

    return (
        <img src={image} alt={title ? title : ''} style={style} />
    );
};

const GalleryView = () => {
    const [auth] = useContext(AuthCtx);
    const { t } = useTranslation(['contactpi', 'button']);
    
    const [images, setImages] = useState<CustomImage[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [index, setIndex] = useState(-1);
    
    //const hasSelected = images.some((image) => image.isSelected);

    useEffect(() => {
        async function fetchData() {
            const listImage = await postFunctionsApi<any[]>('listFile', {fileType: 'image', siteId: JSON.parse(localStorage.getItem('s') as string)});
            if (listImage && typeof listImage !== 'string') {
                console.log(listImage)
                const list = listImage.map(i => { 
                    console.log(i.metadata.tags)
                    const filname = i.src.split('/')[2].split('.')[0];
                    const uploadedAt = new Date(parseInt(filname.slice(0, -4))).toLocaleDateString()
                    
                    const img = {
                        filename: filname,
                        src: filname,
                        alt: i.metadata.w + ',' + i.metadata.h,
                        width: parseInt(i.metadata.w),
                        height: parseInt(i.metadata.h),
                        caption: i.metadata.alt,
                        title: i.metadata.alt,
                        uploadedAt: uploadedAt,
                        description: t('uploadedBy') + i.metadata.author + t('at') + uploadedAt,
                        //tags: i.medatada.tags.split()
                    }
                    const newlist = images
                    newlist.push(img)
                    setImages(newlist)
                })
                //imagess = list
                //setLoading(true)
                //setImages(list)
            }
        };

        fetchData();
    },[])

    console.log(images)

    /*
    const handleSelect = (index: number) => {
        const nextImages = images.map((image, i) =>
            i === index ? { ...image, isSelected: !image.isSelected } : image
        );
        setImages(nextImages);
    };

    const handleSelectAllClick = () => {
        const nextImages = images.map((image) => ({
            ...image,
            isSelected: !hasSelected,
        }));
        setImages(nextImages);
    };
    */

    const handleClick = (index: number, item: CustomImage) => setIndex(index);

    return (

        <div className="w-full h-full flex justify-center items-center bg-primary bg-opacity-90">

            {images.length === 0 && 
                <div className="text-xl font-semibold">
                    {t('noImage')}
                </div>
            }
            
            {images.length > 0 &&
            <div className="w-full h-full p-4">

                <Gallery
                    images={images}
                    onClick={handleClick}
                    thumbnailImageComponent={ImageComponent}
                    enableImageSelection={false}
                />
             
             {/*
                <Lightbox
                    slides={imagess}
                    open={index >= 0}
                    index={index}
                    close={() => setIndex(-1)}
                    plugins={[Captions, Zoom]}
                    animation={{ zoom: 500 }}
                    zoom={{maxZoomPixelRatio: 5, zoomInMultiplier: 2}}
                />
            */}
                

            </div>
            }
        
        </div>

    );
};

export { GalleryView };
