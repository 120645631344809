import { useContext, useState } from 'react';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import { postFunctionsApi, putClassApi } from '../../../utils';
import { AuthCtx } from '../../_context/Context';
import { MemberLabel, memberNameDefault } from '../../../params';
import { Site, SiteSetting, UseSiteSetting } from '../../../../../shared/schema/index';
import { siteSettingObject } from '../../_object';
import { UseSetting } from '../../adminConfig/UseSetting';
import { ButtonAction, ButtonFooterBase, ChooseMemberName, ContentCenter, InputText, TextCenter } from '../../../components';


interface AddSiteSettingProps {
    siteId: string;
};

const AddSiteSetting = ({siteId}: AddSiteSettingProps) => {
    const { t } = useTranslation('admin');
    const navigate = useNavigate();
    const [auth, setAuth] = useContext(AuthCtx);
    const [step, setStep] = useState<number>(1);
    const [nameSite, setNameSite] = useState<string>('');

    const [memberName, setMemberName] = useState<MemberLabel>(memberNameDefault);
    const [otherMemberName, setOtherMemberName] = useState<MemberLabel | undefined>();
    const [showOther, setShowOther] = useState<boolean>(false);

    const [use, setUse] = useState<UseSiteSetting>({presenceView: true, eventView: true});
    
    const [saving, setSaving] = useState<boolean>(false);
    //TODO MemberName button

    const saveSetting = async() => {
        if (siteId) {
            setSaving(true);
            const siteUpdate: Site = {};
            siteUpdate.name = nameSite;
            const newSetting: SiteSetting = siteSettingObject();
            newSetting.memberName = memberName;
            newSetting.otherMemberName = otherMemberName;
            newSetting.use = use;
            siteUpdate.siteSetting = newSetting;
            const siteSaved = await putClassApi('Site', siteId, siteUpdate);
            if (typeof siteSaved !== 'string') {
                await postFunctionsApi('removeUserAdminTmp');
                setAuth({...auth, siteSetting: newSetting});
                return setSaving(false);
            }
        }
        //TODO Error
    };

    const onStep = () => {
        if (step === 1) setStep(2);
        if (step === 2) setStep(3);
        if (step === 3) {
            saveSetting();
            setStep(4);
        }
        if (step === 4) navigate(0);
    }

    return (
        
        <>

            <ContentCenter showIf={step === 1}>

                <TextCenter text={t('addSiteSetting.nameSite')} containerClass="text-lg mb-2" />

                <InputText id='name'
                    value={nameSite}
                    onChange={(e) => setNameSite(e.target.value)}
                    placeholder={t('addSiteSetting.nameSite')}
                    containerClass='w-80'
                    autoFocus
                />

                <TextCenter text={t('addSiteSetting.modification')} containerClass="italic mb-4" />
            
            </ContentCenter>


            <ContentCenter showIf={step === 2}>

                <TextCenter text={t('addSiteSetting.titleMember')} containerClass="text-lg mb-2" />
                
                <ContentCenter className='w-full'>

                    <ChooseMemberName
                        memberName={memberName}
                        setMemberName={setMemberName}
                        otherMemberName={otherMemberName}
                        setOtherMemberName={setOtherMemberName}
                        showOther={showOther}
                        setShowOther={setShowOther}
                        saveSetting={saveSetting}
                    />

        
                </ContentCenter>

                <TextCenter text={t('addSiteSetting.modification')} containerClass="italic mb-4" />

            </ContentCenter>

            <ContentCenter showIf={step === 3}>

                <TextCenter text={t('addSiteSetting.titleUse')} containerClass="text-lg mb-2" />
    
                <div className='w-full'>
                    <UseSetting
                        use={use}
                        setUse={setUse}
                        saveSetting={saveSetting}
                        open={true}
                    />
                </div>

                <TextCenter text={t('addSiteSetting.modification')} containerClass="italic mb-4" />

            </ContentCenter>

            <ContentCenter showIf={step === 4}>

                <TextCenter text={t('addSiteSetting.titleTuto')} containerClass="text-lg mb-2" />
                
                <div className='w-full text-center'>
                    TODO TUTORIEL
                </div>

                <TextCenter text={t('addSiteSetting.modificationTuto')} containerClass="italic mb-4" />
            
            </ContentCenter>

            <ButtonFooterBase>

                <ButtonAction action='previous' showIf={(step === 2 || step === 3)}
                    onClick={() => setStep(step -1)}
                /> 

                <ButtonAction action='next'
                    className={(step === 2 || step === 3) ? '' : "w-full"}
                    onClick={() => saving ? null : onStep()}
                />

            </ButtonFooterBase>

        </>
    );
};

export default AddSiteSetting;