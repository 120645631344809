import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { AuthCtx } from '../_context/Context';
import { LANG_LIST } from '../../params';
import { saveClassApi } from '../../utils';
import { UserSetting } from '../../../../shared/schema/index';
import { userSettingObject } from '../_object';
import { ButtonFooter, Card, CardTitle, ContentCol, TextImportant, RadioList, ButtonAction } from '../../components';


const EditUserSetting = () => {
    const [auth, setAuth] = useContext(AuthCtx);
    const { t } = useTranslation('account');
    const [lang, setLang] = useState(auth.me.userSetting.language);

    const [openLang, setOpenLang] = useState<boolean>(false);

    //TODO change i18n lang in live
    
    const saveSetting = async() => {
        const newSetting: UserSetting = userSettingObject();
        newSetting.language = lang;
        const setting = await saveClassApi('_User', {userSetting: newSetting}, auth.me.objectId);
        if (typeof setting !== 'string') {
            const me = auth.me
            me.userSetting = newSetting;
            setAuth({...auth, me: me});
            return
        }
        toast.error(t('editSetting.retry') as string, {theme: 'colored'});
    };

    return (
  
        <Card>
        
            <CardTitle text={t('editSetting.language')} >
                <TextImportant showIf={!openLang} text={LANG_LIST.find((l) => l.name === auth.me.userSetting.language)?.label} />

                <ButtonAction action='modify'
                    showIf={!openLang}
                    onClick={() => setOpenLang(true)}
                />
            </CardTitle>

            <ContentCol showIf={openLang} >
                
                <RadioList name='lang' items={LANG_LIST} valueSelected={lang} setValue={setLang} />
          
                <ButtonFooter
                    action='apply'
                    ifAction={lang !== auth.me.userSetting.language}
                    onValid={() => {
                        saveSetting();
                        setOpenLang(false)
                    }}
                    onCancel={() => {
                        setLang(auth.me.userSetting.language)
                        setOpenLang(false)
                    }}
                />

            </ContentCol>

            
        </Card>
        
    );
};

export default EditUserSetting;