import React, { InputHTMLAttributes, forwardRef, useContext, useEffect, useImperativeHandle, useRef, useState } from 'react';
import ReactCrop, { centerCrop, makeAspectCrop, Crop, PixelCrop} from 'react-image-crop';
import Compressor from 'compressorjs';
import { b64toBlob, imagePreview, postFunctionsApi } from '../utils';
import { ButtonAction, ButtonFooterBase, ContentCol, Suspense } from '.';
import { AuthCtx } from '../views/_context/Context';


type ImageProps = {
  filename: string;
  alt?: string
  height?: number
  width?: number;
  className?: string;
}

const Image = ({filename, alt, height, width, className}: ImageProps) => {
  const [src, setSrc]= useState<string>('')
  
  useEffect(() => {
    async function fetchImage() {
      const data = await postFunctionsApi<string>('getImage', {filename: filename})

      if (data !== 'error') {
        const blob = b64toBlob(data);
        //const url = window.URL || window.webkitURL;
        const blobUrl = URL.createObjectURL(blob);
        setSrc(blobUrl)
      } else {
        setSrc('')
      }
    }
    if (!src) fetchImage()

  }, [filename])


  return (
    <img src={src} alt={alt} height={height} width={width} className={className}/>
  )
}

type AvatarProps = {
  userId?: string;
  firstName: string
  lastName: string
  height?: number
  width?: number;
  className?: string;
  isUserAvatar?: boolean
}

const Avatar = ({userId, firstName, lastName, height, width, className, isUserAvatar}: AvatarProps) => {
  const [auth, setAuth] = useContext(AuthCtx)
  const [src, setSrc]= useState<string>('')
  
  useEffect(() => {
    async function fetchImage() {
      const data  = await postFunctionsApi<string>('getAvatar', {userId: userId})

      if (data !== 'error') {
        const blob = b64toBlob(data);
        const blobUrl = URL.createObjectURL(blob);
        const me = auth.me
        me.avatar = blobUrl
        setAuth({...auth, me: me});
        setSrc(blobUrl)
      } else {
        setSrc('')
      }
    }
    isUserAvatar && auth.me.avatar ? setSrc(auth.me.avatar) : fetchImage()
  }, [userId, auth, setAuth, auth.me.avatar])


  return src ? (
    <img src={src} alt={firstName + " " + lastName} height={height} width={width} className={className}/>
  ) : <span>{firstName[0].toUpperCase() + lastName[0].toUpperCase()}</span>
}

interface LoadImageProps extends InputHTMLAttributes<HTMLInputElement> {
  multiple?: boolean;
  quality?: number;
  setFiles: (f: any) => void;
  className?: string
}

const LoadImage = ({quality =0.8, multiple = false, setFiles, className = '', ...rest}: LoadImageProps) => {
  const ref = useRef<HTMLInputElement>(null)
  const handleClick = () => ref.current?.click();
  const accept = 'image/*'
  //const capture -> TODO

  const selectFiles = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      let files = Array.from(event.target.files).map(file => {
        return new Promise(resolve => {
          new Compressor(file, {
            quality: quality,
            height: 1200,
            success: (compressedResult) => {
              let reader = new FileReader()
              reader.onload = () => resolve(reader.result?.toString());
              reader.readAsDataURL(compressedResult);
            },
          });
        })
      })

      let res = await Promise.all(files)
      multiple ?  setFiles(res) : setFiles(res[0])
    } 
  };

  return (
    <div className={className}>
      <input {...rest} ref={ref}
        type="file"
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => selectFiles(event)}
        className='hidden'
        multiple={multiple}
        accept={accept}
      />
      <ButtonAction action='loadImage'
        onClick={handleClick}
      />
    </div>

  );
};


function centerAspectCrop(mediaWidth: number, mediaHeight: number, ratio: number) {
  return centerCrop( makeAspectCrop({unit: '%', width: 100 }, ratio, mediaWidth, mediaHeight ), mediaWidth, mediaHeight)
};


type ImageFormat = 'circular' | 'square' | 'noDefine'

interface ImageCropProps {
  imgSrc: string;
  imgFormat?: ImageFormat; 
  showIf?: boolean;
  removeIf?: boolean;
  className?: string;
  quality?: number;
  height?: number
  setImgSrc: (i: string) => void;
  onSaveImage: (file: File | Blob, name?: string, metadata?: Object) => void;
  onRemoveImage: () => void;
  onCancel: () => void;
  saving: boolean;
};

const ImageCrop = ({ imgSrc, imgFormat = 'noDefine', quality=0.8, height=800, setImgSrc, onSaveImage, onRemoveImage, onCancel, saving, showIf = true, removeIf= false, className}: ImageCropProps) => {
  const imgRef = useRef<HTMLImageElement>(null);
  const [crop, setCrop] = useState<Crop>();
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>();
  const [fileLoaded, setFileLoaded] = useState<boolean>(false);
  const [ratio, setRatio] = useState<number>(1);

  const loadImg = (event: React.SyntheticEvent<HTMLImageElement>) => {
    const { width, height } = event.currentTarget;
    setCrop(centerAspectCrop(width, height, imgFormat === 'noDefine' ? width/height : 1));
  };

  const loadFiles = (files: any) => {
    if (typeof files === 'string' || files) {
      setCrop(undefined)
      setImgSrc(files)
      setFileLoaded(true)
    } 
  };

  const onImagePreview = async () => {
    const image = await imagePreview(imgRef.current as HTMLImageElement,completedCrop as PixelCrop);
    if (image) {
      new Compressor(image, {
        quality: quality,
        height: height,
        success: async (compressedResult) => {
          return onSaveImage(compressedResult, image.name, {}) //metadata
        }
      });
    }
  }

  return ( showIf ? (
    <ContentCol>

      <LoadImage setFiles={loadFiles} />

      {imgSrc !== '' &&
      <div className={['w-80 sm:w-96 flex justify-center place-self-center my-2', className].join(' ')}>

        <ReactCrop
          crop={fileLoaded ? crop : undefined}
          circularCrop={imgFormat === 'circular'}
          onChange={(_, percentCrop) => setCrop(percentCrop)}
          onComplete={(img) => setCompletedCrop(img)}
          aspect={imgFormat === 'noDefine' ? undefined : 1}
        >
          <img
            ref={imgRef}
            alt=""
            src={imgSrc}
            className='max-h-80'
            onLoad={loadImg}
          />
        </ReactCrop>
      </div>
      }

     <Suspense showIf={saving} />

      <ButtonFooterBase>

        <div className='flex items-center'>
          <ButtonAction action='apply'
            showIf={fileLoaded && !saving }
            onClick={() => {setFileLoaded(false); onImagePreview()}}
          />

          <ButtonAction action='remove'
            showIf={removeIf && !fileLoaded}
            onClick={() => {setFileLoaded(false); onRemoveImage()}}
          />
        </div>

        <ButtonAction action='cancel' onClick={() => {setFileLoaded(false); onCancel() }} />
      </ButtonFooterBase>

    </ContentCol>
  ) : null);
};

type LoadImageCropProps = {
  image: string;
  imgFormat?: ImageFormat; 
  showIf?: boolean;
  removeIf?: boolean;
  className?: string;
  quality?: number;
  height?: number
  setImage: (i: string) => void;
  onSaveImage: (name: string, type: string, file: File | Blob, h: number, w: number,  metadata?: Object) => void;
  onRemoveImage: () => void;
  onCancel: () => void;
  saving: boolean;
};


const LoadImageCrop = ({ image, imgFormat = 'noDefine', quality=0.8, height=800, setImage, onSaveImage, onRemoveImage, onCancel, saving, showIf = true, removeIf= false, className}: LoadImageCropProps) => {
  const [fileLoaded, setFileLoaded] = useState<boolean>(false);
  const imageCrop = useRef<RefImageCrop>(null);
  
  const loadFiles = (files: any) => {
    if (typeof files === 'string' || files) {
      //setCrop(undefined)
      setImage(files)
      setFileLoaded(true)
    } 
  };

  const save = async () => {
    const img = await imageCrop.current?.onSave()
    if (img) {
      const width = Math.round(height*img.ratio)
      new Compressor(img.blob, {
        quality: quality,
        height: height,
        width: width,
        success: async (compressedResult) => {
          return onSaveImage(img.blob.name, img.blob.type, compressedResult, height, width, {})
        }
      });
    }
  }

  return ( showIf ? (
    <ContentCol>

      <LoadImage
        setFiles={loadFiles}
      />

      <EditImage
        ref={imageCrop}
        image={image}
        imgFormat={imgFormat}
        className={className}
      />

     <Suspense showIf={saving} />

      <ButtonFooterBase>

        <div className='flex items-center'>
          <ButtonAction action='apply'
            showIf={fileLoaded && !saving }
            onClick={() => {
              setFileLoaded(false);
              save()
            }}
          />

          <ButtonAction action='remove'
            showIf={removeIf && !fileLoaded}
            onClick={() => {
              setFileLoaded(false);
              onRemoveImage()
            }}
          />
        </div>

        <ButtonAction action='cancel'
          onClick={() => {
            setFileLoaded(false);
            onCancel()
          }}
        />
      </ButtonFooterBase>

    </ContentCol>
  ) : null);
};

type RefImageCrop = {
  onSave: () => Promise<{blob: Blob, ratio: number}| undefined>
}
type EditImageProps = {
  image: string;
  imgFormat?: ImageFormat; 
  className?: string;
};

const EditImage = forwardRef<RefImageCrop, EditImageProps>(({image, imgFormat = 'noDefine', className}: EditImageProps, ref) => {
  const imgRef = useRef<HTMLImageElement>(null);
  const [crop, setCrop] = useState<Crop>();
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>();

  const loadImg = (event: React.SyntheticEvent<HTMLImageElement>) => {
    const { width, height } = event.currentTarget;
    setCrop(centerAspectCrop(width, height, imgFormat === 'noDefine' ? width/height : 1));
  };

  useImperativeHandle(ref, () => ({
    async onSave () {
      if (imgRef.current && completedCrop) {
        const imageCrop =  await imagePreview(imgRef.current, completedCrop);
        if (imageCrop) return {blob: imageCrop, ratio: completedCrop.width/completedCrop.height}
      }

    }
  }))
  
  console.log(crop)
  console.log(completedCrop)

  return (

    <div className={['w-full flex justify-center', className].join(' ')}>

      <ReactCrop
        crop={crop}
        circularCrop={imgFormat === 'circular'}
        onChange={(_, percentCrop) => setCrop(percentCrop)}
        onComplete={(img) => setCompletedCrop(img)}
        aspect={imgFormat === 'noDefine' ? undefined : 1}
      >
        <img
          ref={imgRef}
          alt=""
          src={image}
          className='max-h-80'
          onLoad={loadImg}
        />
      </ReactCrop>
    </div>
  );
});

export { Image, Avatar, LoadImage, LoadImageCrop, EditImage, ImageCrop };