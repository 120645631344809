import { DATA_APP_ID, DATA_API_URL } from '../config';
//TODO process
import { toast } from 'react-toastify';
import axios, { AxiosError, AxiosInstance, AxiosResponse } from "axios";
import { ParseBaseAttributes, ParseObject } from '../../../shared/schema/index';


let instanceDataPAPI : AxiosInstance | undefined = undefined;
//let instanceFilePAPI : AxiosInstance | undefined = undefined;
let fileType: string | undefined = undefined;
let errorUserId = false;

type ConfigType = {type?: ContentTypeType, forceInstance?: boolean, response?: ResponseType};
type ContentTypeType = 'application/json' | 'multipart/form-data' | 'image/png';
type ResponseType = 'json' | 'stream' | 'arraybuffer' | 'document' | 'text' | 'blob'

function getInstance(config?: ConfigType): AxiosInstance {
  if (config?.response) return createInstance(config?.type ? config?.type : 'image/png', config.response);
  if (config?.type) return createInstance(config.type) //return instanceFilePAPI && (config.type === fileType) ? instanceFilePAPI : createInstance(config.type);
  if (config?.forceInstance) return createInstance('application/json');
  
  
  return (instanceDataPAPI && !config?.forceInstance) ? instanceDataPAPI : createInstance('application/json');
};

function createInstance(contentType: string, responseType?: ResponseType): AxiosInstance {
  if (contentType !== 'application/json') fileType = contentType;
  axios.defaults.baseURL = DATA_API_URL;
  const sessionToken = window.localStorage.getItem('t');
  const headers: any = {
      'X-Parse-Application-Id': DATA_APP_ID,
      'Content-Type': contentType,
  };
  if (sessionToken) headers['X-Parse-Session-Token'] = JSON.parse(sessionToken);
  
  const instance = axios.create({
    baseURL: DATA_API_URL,
    headers: headers,
    responseType: responseType ? responseType : 'json'
  });

  if (contentType === 'application/json') instanceDataPAPI = instance //: instanceFilePAPI = instance;
  //fileType = contentType;
  
  intanceInterceptor(instance)
  
  return instance;
};

function intanceInterceptor(instance: AxiosInstance) {
  instance.interceptors.response.use(res => {
    return res.data;
  }, (error: AxiosError) => {
    return 'error'//Promise.reject(error);
  });
};

async function errorApi(error: AxiosError): Promise<string>  {
  if (error.code ===  "ERR_BAD_REQUEST") {
    const errorAxios = error.response as AxiosResponse;
    if (!errorUserId && (errorAxios.data.code === 101 || errorAxios.data.code === 209)) {
      errorUserId = true;
      if (error.config && error.config.url === '/login') {
        toast.error('Le couple identifiant/mot de passe est incorrect', {theme: 'colored'})
      } else {
        localStorage.removeItem('t');
        localStorage.removeItem('m');
        window.location.replace('/loginTimeout');
      };
      return 'errorUserId'
    }

  } else if (error.code === "ERR_NETWORK") {
    toast.error("Problème de connexion. Vérifier votre connexion.", {theme: 'colored'});
    return 'error500'
  }

  return error.code as string
};

interface GetOptions {
  where?: {}, 
  count?: number,
  order?: string,
  limit?: number,
  skip?: number,
  keys?: string[],
  excludeKeys?: string[],
  include?: string[] | string, 
};

async function getApi<T extends ParseBaseAttributes>(methodName : string, option?: GetOptions, config?: ConfigType): Promise<T | string> {
  const params = {
    where: option?.where,
    keys: option?.keys,
    excludeKeys: option?.excludeKeys,
    include: option?.include,
  }
  return await getInstance(config).get(methodName, { params: params}
  ).then((response: any) => { return response as T}
  ).catch((error: AxiosError) => { return errorApi(error)});
}

type GetResponse<T> = {results: T[], count: number }
async function getClassApi<T extends ParseBaseAttributes>(className: string, option?: GetOptions, config?: ConfigType): Promise<GetResponse<T> | string>  {

  return await getInstance(config).get('/classes/' + className, {params: {
    "where": option?.where,
    "order": option?.order,
    "count": option?.count ? 1 : undefined, 
    "limit": option?.limit,
    "skip": option?.skip,
    "keys": option?.keys,
    "excludeKeys": option?.excludeKeys,
    "include": option?.include,
  }}).then((response: any) => { return response as GetResponse<T>
  }).catch((error: AxiosError) => { return errorApi(error)});
}

async function getByIdApi<T extends ParseBaseAttributes>(className : string, id: string, option?: GetOptions): Promise<T | string> {
  const params = {
    where: option?.where,
    keys: option?.keys,
    excludeKeys: option?.excludeKeys,
    include: option?.include,
  }
  return await getInstance().get('/classes/' + className + '/' + id, {
    params: params
  }).then((response: any) => { return response as T
  }).catch((error: AxiosError) => { return errorApi(error)});
}


async function postApi<T>(methodName : string, object?: ParseObject, config?: ConfigType): Promise<T | string> {
  return await getInstance(config).post(methodName, object
  ).then((response: any) => {return response}
  ).catch((error: AxiosError) => { return errorApi(error)});
}

async function postFunctionsApi<T>(methodName : string, object?: ParseObject, config?: ConfigType): Promise<T | string> {
  return await getInstance(config).post('/functions/' + methodName, object
  ).then((response: any) => {return response.result}
  ).catch((error: AxiosError) => { return errorApi(error)});
}

type PostClassResponse = {createdAt: string, objectId: string};

async function postClassApi<T>(className : string, object: Partial<T>): Promise<PostClassResponse | string> {
  return await getInstance().post('/classes/' + className, object
  ).then((response: any) => { return response as PostClassResponse}
  ).catch((error: AxiosError) => { return errorApi(error)});
};

type PutResponse = {updatedAt: string}

async function putClassApi<T>(className : string, id: string, object: Partial<T>): Promise<PutResponse | string> {  
  return await getInstance().put('/classes/' + className + '/' + id, object
  ).then((response: any) => { return response as PutResponse }
  ).catch((error: AxiosError) => { return errorApi(error)});
};

type SaveResponse = {createdAt?: string, objectId?: string, updatedAt?: string}

async function saveClassApi<T extends ParseBaseAttributes>(className : string, object: Partial<T>, id?: string): Promise<SaveResponse | string> {
  if (id) {
    return await putClassApi<T>(className, id, object)
  } else {
    return await postClassApi<T>(className, object)
  }
};


type BatchQuery = {method: 'POST' | 'PUT' | 'DELETE', path: string, body: ParseObject }
type BatchResponse = []

async function postBatchApi(batch : BatchQuery[]): Promise<BatchResponse | string> {
  return await getInstance().post('/batch/', {requests: batch} 
  ).then((response: any) => { return response as BatchResponse}
  ).catch((error: AxiosError) => { return errorApi(error)});
}

async function getFileApi<T>(methodName : string, object?: ParseObject, config?: ConfigType) {
  return await getInstance(config).post('/functions/' + methodName, object
  ).then((response: any) => {return response}
  ).catch((error: AxiosError) => { return errorApi(error)});
}

async function postFileApi(name : string, file: File, type: ContentTypeType): Promise<any> {
  return await getInstance({type: type}).post('/files/' + name, file
  ).then((response: any) => { console.log(response); return response}
  ).catch((error: AxiosError) => { return errorApi(error)});
}

async function addRelationApi<T extends ParseBaseAttributes>(className: string, object: T & string, relation: Parse.Pointer): Promise<PutResponse | string> {
  return await getInstance().put('/classes/' + className + '/' + typeof object === 'string' ? object : object.objectId as string,
  {opponents: {__op: "AddRelation", objects: { relation }}} 
  ).then((response: any) => { return response as PutResponse}
  ).catch((error: AxiosError) => { return errorApi(error)});
}

async function removeRelationApi<T extends ParseBaseAttributes>(className: string, object : T & string, relation: Parse.Pointer): Promise<PutResponse | string> {
  return await getInstance().put('/classes/'+ className + '/' + typeof object === 'string' ? object : object.objectId as string,
  {opponents: {__op: "RemoveRelation", objects: { relation }}}  
  ).then((response: any) => { return response as PutResponse}
  ).catch((error: AxiosError) => { return errorApi(error)});
}

async function incrementFieldApi<T extends ParseBaseAttributes>(className: string, object : T & string, field: string, value: number): Promise<PutResponse | string> {
  return await getInstance().put('/classes/'+ className + '/' + typeof object === 'string' ? object : object.objectId as string,
  {[field]: {__op: "Increment","amount": value}}
  ).then((response: any) => { return response as PutResponse}
  ).catch((error: AxiosError) => { return errorApi(error)});
}

async function deleteFieldApi<T extends ParseBaseAttributes>(className: string, object : T & string, field: string): Promise<PutResponse | string> {
  return await getInstance().put('/classes/' + className + '/' + typeof object === 'string' ? object : object.objectId as string,
  {[field]: {__op: "Delete"}}
  ).then((response: any) => { return response as PutResponse }
  ).catch((error: AxiosError) => { return errorApi(error)});
}

async function deleteClassApi(className : string, objectId: string, config?: ConfigType): Promise<any> {
  return await getInstance(config).delete('/classes/' + className + '/' + objectId
  ).then((response: any) => { return response}
  ).catch((error: AxiosError) => { return errorApi(error)});
}


export {
  getApi,
  getClassApi,
  getByIdApi,
  postApi,
  postFunctionsApi,
  postClassApi,
  postBatchApi,
  postFileApi,

  getFileApi,

  putClassApi,
  saveClassApi,
  addRelationApi,
  removeRelationApi,
  incrementFieldApi,
  deleteFieldApi,
  deleteClassApi
};