import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { View, SubView, ButtonText, ViewFooter } from '../../components';
import { DataList } from './data/DataList';

const DataSiteView = () => {
  const { t } = useTranslation(['datasite', 'nav']);
  const [navView, setNavView] = useState<'data' | 'model'>('data');  

  return (
      <>
          <View>

              <SubView showIf={navView === 'data'}>
                  <DataList />
              </SubView>

              <SubView showIf={navView === 'model'}>
                  
              </SubView>
 

          </View>     
          
          <ViewFooter>  
              
              <ButtonText showIf={navView !== 'data'}
                  variant="filled-primary"
                  text={t('data.nav')}
                  onClick={() => setNavView('data')}
              />
              
              <ButtonText showIf={navView !== 'model'}
                  variant='filled-primary'
                  text={t('model.nav')}
                  onClick={() => setNavView('model')}
              />
              
          </ViewFooter> 

      </>
  )
}

export { DataSiteView };