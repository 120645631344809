import { createContext } from 'react';
import { Auth, Help, Nav } from '../../../../shared/schema/index'
import { authObject, helpObject, navObject } from '../_object';

type AuthCtxType = [
    auth: Auth,
    setAuth: (a: Auth | undefined) => void
]
const AuthCtx = createContext<AuthCtxType>([authObject(), () => {}]);


type NavCtxType = [
    nav: Nav,
    setNav: (n: Nav) => void
]
const NavCtx = createContext<NavCtxType>([navObject({}), () => {}]);

type HelpCtxType = {
    help: Help | undefined,
    setHelp: (h: Help | undefined) => void
}
const HelpCtx = createContext<HelpCtxType>({help: helpObject() , setHelp: () => {}});



export { AuthCtx, NavCtx, HelpCtx };