import { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { AuthCtx } from '../_context/Context';
import { UseSiteSetting } from '../../../../shared/schema/index';
import { ButtonFooter, Card, CardTitle, Description, ContentCol, Checkbox, ButtonAction } from '../../components';

type UseSettingProps = {
  use: UseSiteSetting;
  setUse: (u: UseSiteSetting) => void;
  saveSetting: () => void;
  open?: boolean;
}

const UseSetting = ({use, setUse, saveSetting, open}:UseSettingProps) => {
  const [auth] = useContext(AuthCtx);
  const { t } = useTranslation(['siteSetting', 'error']);
  const [openUse, setOpenUse] = useState<boolean>(open ? open : false);

  return (

    <Card>

      <CardTitle title={t('useSetting.title')}>
        <ButtonAction action='modify' showIf={!openUse} className='ml-2' onClick={() => setOpenUse(true)} />
      </CardTitle>

      <ContentCol showIf={openUse} >

        <ContentCol>

          <Checkbox id='base'
            readOnly
            checked={true}
            color='success'
            label={t('useSetting.baseTitle')}
          />
          <Description containerClass=' italic ml-4' text={t('useSetting.baseDescription')}/>

        </ContentCol>

        <ContentCol>

          <Checkbox id='event'
            checked={use.eventView ? use.eventView : false}
            color='info'
            label={t('useSetting.eventTitle')}
            onChange={() => setUse({...use, eventView: !use.eventView })}
          />
          <Description containerClass='italic ml-4' text={t('useSetting.eventDescription')}/>

        </ContentCol>
      
        <ContentCol className={use.eventView ? '' : 'opacity-40'}>

          <Checkbox id='presence'
            checked={use.presenceView && use.eventView ? use.presenceView  : false}
            color='info'
            label={t('useSetting.presenceTitle')}
            onChange={() => setUse({...use, presenceView: !use.presenceView })}
          />
          <Description containerClass='italic ml-4' text={t('useSetting.presenceDescription')}/>

        </ContentCol>
        
        <ButtonFooter showIf ={!open}
          action='apply'
          onValid={() => {
            saveSetting();
            setOpenUse(false)
          }}
          onCancel={() => {
            setUse(auth.siteSetting.use)
            setOpenUse(false)
          }}
        />
        
      </ContentCol>
      
    </Card>
  );
};

export { UseSetting };