import { LocaleSys, Member } from '../../../../shared/schema/index';
import { parsePointer } from ".";

const newMember = (language: LocaleSys): Member => {
    const siteId = JSON.parse(localStorage.getItem('s') as string);
    const object: Member = {
        site: parsePointer('Site', siteId),
        gender: null,
        name: '',
        firstName: '',
        lastName: '',
        email: '',
        relations: [],
        isMember: true,
        emailing: false,
        noEmailing: false,
        groups: [],
        memberSetting: {language: language}
    };

    return object;
}

export { newMember }
