import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import * as XLSX from "xlsx";
import { getClassApi } from '../../../utils';
import { AuthCtx } from '../../_context/Context';
import { Suspense, SubCard, Text, getMemberName, Checkbox, ContentCol, ContentCenter, ButtonAction } from '../../../components';

//TODO filter isMember and isEmailing
//TODO permission to filesMember

const ExportMember = () => {
    const [auth] = useContext(AuthCtx);
    const { t } = useTranslation(['member']);
    
    const [data, setData] = useState<{[key: string]: boolean}>({
        gender: true,
        firstName: true,
        lastName: true,
        isMember: true,
        email: true,
        emailing: true,
        phone: true,
        comment: true,
        relations: true
    })

    const [loading, setLoading] = useState<boolean>(false);    

    const generateFileName = () => {
        const now: string = new Date().toLocaleDateString(auth.me.userSetting.language, {year: '2-digit', month: '2-digit', day: '2-digit', hour: '2-digit', minute:'2-digit'}).replace(/\//g, '_')
        return 'InvitCom_' + now + '_' + auth.siteName + '_' + getMemberName({t:t, name: auth.siteSetting.memberName, plural: true})
    }

    const keysData = () => {
        const keys: string[] = [];
        for (const d in data) {
            if (data[d]) keys.push(`${d}`);
        }
        return keys
    }

    const numberToLetters = (num: number) => {
        let letters = ''
        while (num >= 0) {
            letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'[num % 26] + letters
            num = Math.floor(num / 26) - 1
        }
        return letters
    }

    const exportMember = async() => {
        setLoading(true)
        const fileName = generateFileName()
        const keys = keysData()
        let nbColumnRelation = 0
        
        const getMembers = await getClassApi<any>('Member', {
            keys: keys,
            include: 'relations.member',
            count: 1,
            limit: 10000
        });

        if (typeof getMembers !== 'string') {
            const nbMember = getMembers.count
            const members = getMembers.results.map(m => {
                delete m.ACL
                delete m.createdAt
                delete m.updatedAt
                if (m.gender === 'm') m.gender = t('gender_m')
                if (m.gender === 'f') m.gender = t('gender_f')
                
                if (m.relations && m.relations.length > 0) {
                    m.relations =  m.relations.forEach((r: any, k: number) => {
                        if (k + 1 > nbColumnRelation) nbColumnRelation = k + 1
                        return m[t('relation') + '_' + (k+1)] = r.member.objectId + '; ' +
                        t('relationType', {context: r.relationType}) + '; ' +
                        r.member.firstName + '; ' +
                        r.member.lastName
                        + (r.member.email ? '; ' + r.member.email : '')     
                    });
                    
                }
                delete m.relations

                return m
            })

            if (members) {
                const ws = XLSX.utils.json_to_sheet(members);
                for (let i = 0; i < 12; i++) {
                    const cell: string = numberToLetters(i) + '1'
                    ws[cell].v = t(ws[cell].v, {member: getMemberName({t:t, name: auth.siteSetting.memberName})})
                }
                const wb = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(wb, ws, getMemberName({t:t, name: auth.siteSetting.memberName, plural: true}));
                XLSX.writeFile(wb, fileName + '.xlsx');
                setLoading(false)
                toast.success(t('fileExport.success', {nbMember : nbMember}) as string, {theme: 'colored'});
                return
            }
        }

        toast.error(t('errorRetry') as string, {theme: 'colored'});
        setLoading(false)
    }
    
    return (

        <SubCard className='mt-4'>

            <ContentCenter>
                <Text text={t('fileExport.title')} textClass='text-lg font-semiBold' />
            </ContentCenter>

            <ContentCol className='my-2'>

                <Text text={t('fileExport.dataToExport')} textClass='font-semiBold' />

                <Checkbox id='gender'
                    checked={data.gender}
                    variant='success'
                    label={t('gender')}
                    onChange={() => setData({...data, gender: !data.gender})}
                />

                <Checkbox id='firstName'
                    checked={data.firstName}
                    variant='success'
                    label={t('firstName')}
                    onChange={() => setData({...data, firstName: !data.firstName})}
                />

                <Checkbox id='lastName'
                    checked={data.lastName}
                    variant='success'
                    label={t('lastName')}
                    onChange={() => setData({...data, lastName: !data.lastName})}
                />

                <Checkbox id='isMember'
                    checked={data.isMember}
                    variant='success'
                    label={t('isMember', {member: getMemberName({t:t, name: auth.siteSetting.memberName})})}
                    onChange={() => setData({...data, isMember: !data.isMember})}
                />

                <Checkbox id='email'
                    checked={data.email}
                    variant='success'
                    label={t('email')}
                    onChange={() => setData({...data, email: !data.email})}
                />

                <Checkbox id='emailing'
                    checked={data.emailing}
                    variant='success'
                    label={t('emailing')}
                    onChange={() => setData({...data, emailing: !data.emailing})}
                />
                
                <Checkbox id='phone'
                    checked={data.phone}
                    variant='success'
                    label={t('phone')}
                    onChange={() => setData({...data, phone: !data.phone})}
                />
                
                <Checkbox id='comment'
                    checked={data.comment}
                    variant='success'
                    label={t('comment')}
                    onChange={() => setData({...data, comment: !data.comment})}
                />
                
                <Checkbox id='relations'
                    checked={data.relations}
                    variant='success'
                    label={t('relations')}
                    onChange={() => setData({...data, relations: !data.relations})}
                />


            </ContentCol>

            <ContentCenter className='mt-4'>
                <ButtonAction action='download' showIf={!loading}
                  onClick={() => exportMember()}
                />
            </ContentCenter>

            <Suspense showIf={loading} />
                
        </SubCard>

 

    );
};

export { ExportMember };