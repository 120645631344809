import { } from '.';
import { ParseBaseAttributes, ParseDate, ParseGeoPoint } from '../../../../shared/schema';

type CheckFields = boolean | string[];

interface ParseObject {
    [key: string]: any;
};

interface ParseError extends ParseObject {
    error: string;
};


interface ParseClass extends Partial<Parse.Pointer> {
    ACL?: any;
    //site?: Site;
}


function objectWithoutParse(o: any) {
    o.ACL = undefined
    o.className = undefined
    o.__type = undefined
    o.createdAt = undefined
    o.updatedAt = undefined

    return o
}

const parseClass = (data?: any): ParseClass => {
    return {
        objectId: data?.objectId,
        ACL: data?.ACL,
        __type: 'Object'
    }
};

const parsePointer = (className: string, objectId: string ): Parse.Pointer => {
    return {
        objectId: objectId,
        className: className,
        __type: 'Pointer'
    }
}

const parsePointerArray = (className: string, array: ParseClass[]): ParseClass[] => {
    return array.map(item => parsePointer(className, item.objectId as string));       
};

interface ParseFile extends ParseObject {
    name: string;
    url: string;
    __type: string;
};

const parseFile = (name: string, url: string): ParseFile => {
    return {
        name: name,
        url: url,
        __type: 'File'
    }
};

const parseDate = (date?: string | Date) => {
    let newdate = new Date().toISOString()
    if (date) {
        newdate = typeof date === 'string' ? new Date(date).toISOString() : date.toISOString()
    } 
    return {
        iso: newdate,
        __type: 'Date'
    }
};

const parseGeoPoint = (lat: number, lng: number): ParseGeoPoint => {
    return {
        latitude: lat,
        longitude: lng,
        __type: 'GeoPoint'
    }
};

export type { ParseObject, ParseError, ParseClass, ParseDate, ParseFile, CheckFields}
export { parseClass, parsePointer, parsePointerArray, parseFile, parseDate, parseGeoPoint, objectWithoutParse };