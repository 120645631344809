import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Member, MemberRelation, SaveMember } from '../../../../../shared/schema/index';
import { ModalConfirm } from '../../../components';
import { MemberPosterCard } from './MemberPosterCard';
import { MemberEditCard } from './MemberEditCard';



type MemberCardProps = {
    key: React.Key;
    member: Member;
    relationTypes: {name: string, label:String}[];
    onSaveMember: (s: SaveMember) => Promise<Member | undefined>
    onDelete: (memberId: string) => void;
    onDeleteRelation: (member: Member, childId: string) => Promise<MemberRelation[] | false>;
}

const MemberCard = ({member, relationTypes, onSaveMember, onDelete, onDeleteRelation}: MemberCardProps) => { 
    const { t } = useTranslation(['member']);
    const [showEdit, setShowEdit] = useState<boolean>(false);
    const [showModalDelete, setShowModalDelete] = useState<boolean>(false);

    const saveMember = async(s: SaveMember) => {
        const save = await onSaveMember(s)
        if (save) {
            setShowEdit(false)
        }
        return save
    }

    return (
        <>
            {!showEdit &&
            <MemberPosterCard
                member={member}
                onAction={a => a === 'edit' ? setShowEdit(true) : setShowModalDelete(true)}
            />
            }

            {showEdit &&
            <MemberEditCard
                member={member}
                relationTypes={relationTypes}
                onSaveMember={saveMember}
                onDeleteRelation={onDeleteRelation}
                onCancel={() => setShowEdit(false)}
            />
            }

            {member.objectId &&
            <ModalConfirm
                title={t('modal.deleteMember', {firstName: member.firstName, lastName: member.lastName })}
                open={showModalDelete}
                onValid={() => onDelete(member.objectId as string)}
                onClose={() => setShowModalDelete(false)}
            />
            }
            
        </>
    );
};


export { MemberCard };