import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { toFirstUpperCase } from '../../utils'
import { ButtonText, getMemberName } from '../../components';
import { AuthCtx, NavCtx } from '../_context/Context';


const DashboardView = () => {
    const [auth] = useContext(AuthCtx);
    const { t } = useTranslation('nav');
    const [nav, setNav] = useContext(NavCtx);

    return (

        <div className='h-full flex justify-center overflow-y-auto p-4'>

            <div className="h-full w-full sm:w-2/3 lg:w-1/2 flex flex-col items-center justify-between">

                {auth.permission.presenceView &&
                <div className="w-64 sm:h-20 rounded-xl bg-green text-white hover:bg-slate-100 hover:text-green sm:w-96 my-2">
                    
                    <ButtonText text={t('label_w1')}
                        className='w-full h-16 flex flex-wrap justify-center items-center font-bold text-lg p-0 m-0'
                        onClick={() => setNav({...nav, k: "w1"}) }
                    />
                
                </div>
                }

                {auth.permission.eventView &&
                <div className="h-16 w-64 min-h-fit rounded-xl bg-blue text-white sm:h-20 sm:w-96 my-2">
                
                    <ButtonText text={t('label_w2')}
                        className='w-full h-16 flex flex-wrap justify-center items-center font-bold text-lg p-0 m-0 hover:opacity-70'
                        onClick={() => setNav({...nav, k: "w2"})}
                    />
                
                </div>
                }

                {auth.permission.emailingView &&
                <div className="h-16 w-64 min-h-fit rounded-xl bg-red text-white sm:h-20 sm:w-96 my-2">
                
                    <ButtonText text={t('label_w3')}
                        className='w-full h-16 flex flex-wrap justify-center items-center font-bold text-lg p-0 m-0 hover:opacity-70'
                        onClick={() => setNav({...nav, k: "w3"})}
                    />
                
                </div>
                }

                {auth.permission.memberView &&
                <div className="h-16 w-64 min-h-fit rounded-xl bg-orange text-white sm:h-20 sm:w-96 my-2">
                    <ButtonText text={toFirstUpperCase(getMemberName({t: t, name: auth.siteSetting.memberName, plural: true}))}
                        className='w-full h-16 flex flex-wrap justify-center items-center font-bold text-lg p-0 m-0 hover:opacity-70'
                        onClick={() => setNav({...nav, k: "w4"})}
                    />        
                </div>
                }

                {auth.permission.dataSiteView &&
                <div className="h-16 w-64 min-h-fit rounded-xl bg-purple text-white sm:h-20 sm:w-96 my-2">
                    <ButtonText text={t('label_w5')}
                        className='w-full h-16 flex flex-wrap justify-center items-center font-bold text-lg p-0 m-0 hover:opacity-70'
                        onClick={() => setNav({...nav, k: "w5"})}
                    />
                </div>
                }

        
            </div>
    
        </div>
    );
};

export default DashboardView;