import { useContext, useState } from "react";
import { createRoot } from 'react-dom/client';
import { useTranslation } from "react-i18next";
import { AuthCtx } from "../../_context/Context";
import { Member, ResponseCheckMember, TypeCheckMember } from "../../../../../shared/schema";
import { ContentCol, getMemberName, Modal, RadioBase } from "../../../components";


type RequestCheckMemberProps = {
    type: TypeCheckMember,
    request: 'name' | 'email'
    member: Member,
    members?: Member[],
    email?: Member,
};

const requestCheckMember = ({type, request, member, members, email}: RequestCheckMemberProps) => {    
    return new Promise<ResponseCheckMember | 'cancel'>(resolve => {
        const container = document.getElementById('modal-check-member');
        const root = createRoot(container!);
        const unmout = () => root.unmount();

        const valid = (value: ResponseCheckMember | 'cancel') => {
            resolve(value)
            unmout()
        }
      
        const close = () => { 
            resolve('cancel')
            unmout()
        }

        root.render(
          <RequestCheckMemberModal type={type} request={request} member={member} members={members} email={email} onValid={valid} onClose={close} />
        );
    }) 
}

interface RequestCheckMemberModalProps extends RequestCheckMemberProps {
    onValid: (v :ResponseCheckMember | 'cancel') => void
    onClose: () => void
};

const RequestCheckMemberModal = ({type, request, member, members, email, onValid, onClose}: RequestCheckMemberModalProps) => {
    const { t } = useTranslation(['member', 'nav']);
    const [auth] = useContext(AuthCtx);

    const title = request === 'name'
    ? t('modalRequest.nameTitle', {firstName: member.firstName, lastName: member.lastName})
    : t('modalRequest.emailTitle', {email: member.email})
    
    const [choice, setChoice] = useState<'create' | 'changeEmailing' | 'keepEmailing' | number>()

    //const [open, setOpen] = useState<boolean>(true)
    
    const memberName = getMemberName({t: t, name: auth.siteSetting.memberName})

    const valid = () => {
        if (choice !== undefined) {
            //name
            if (choice === 'create') return onValid({response: 'create', member: member})
            if (typeof choice === 'number' && members) return onValid({response: 'exist', member: members[choice]})
            //Email
            if (choice === 'changeEmailing' && email) return onValid({response: 'changeEmailing', member: email})
            if (choice === 'keepEmailing') return onValid({response: 'keepEmailing', member: member})
        }
        return onValid('cancel')
    }

    const label = (member: Member) => {
        let label = member.firstName + ' ' + member.lastName
        if (member.email !== '') label += ', ' + t('email') + ': ' + member.email
        if (member.phone) label += ', ' + t('phone') + ': ' + member.phone
        if (member.comment) label += ', ' + t('commentaire') + ': ' + member.comment

        return label
    }

    return (

        <Modal
            open={true}
            title={title}
            onClose={onClose}
            onValid={valid}
        >

            <div>
                {request === 'name' &&
                <>
                    {type === 'create' &&
                    <div>{t('modalRequest.nameCreate', {member: getMemberName({t: t, name: auth.siteSetting.memberName})})}</div>
                    }

                    {type === 'update' &&
                    <div>{t('modalRequest.nameUpdate')}</div>
                    }

                    {(type === 'createRelation' || type === 'updateRelation') && members &&
                    <ContentCol>
                        <div>{t('modalRequest.want')}</div>

                        <RadioBase name='name'
                            value='create'
                            label={type === 'createRelation'
                                ? t('modalRequest.nameRadioCreate', {member: memberName})
                                : t('modalRequest.nameRadioUpdate', {member: memberName})
                            }
                            valueSelected={choice}
                            setValue={() => setChoice('create')}
                        />

                        <div>{t('modalRequest.orChoiceRelation', {member: memberName})}</div>

                        {members.map((member: Member, k: number) => 
                            <RadioBase name='name' key={k}
                                value={k}
                                label={label(member)}
                                valueSelected={choice}
                                setValue={() => setChoice(k)}
                            />
                        )}

                    </ContentCol>
                    }
                </>
                }

                {request === 'email' && email &&
                <ContentCol>

                    <div>{t('modalRequest.want')}</div>

                    <RadioBase name='email'
                        value='changeEmailing'
                        label={t('modalRequest.emailChoice', {firstName: member.firstName, lastName: member.lastName})}
                        valueSelected={choice}
                        setValue={() => setChoice('changeEmailing')}
                    />

                    <RadioBase name='email'
                        value='keepEmailing'
                        label={t('modalRequest.emailChoice', {firstName: email.firstName, lastName: email.lastName})}
                        valueSelected={choice}
                        setValue={() => setChoice('keepEmailing')}
                    />

                </ContentCol>
                }
            </div>

        </Modal>
    )
    
}

export { requestCheckMember }