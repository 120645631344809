import { Place } from '../../../../../shared/schema/';
import { SubCard, Text, TextEditorSimply, ButtonIcon, Content } from '../../../components';


type PlacePostCardProps = {
    place: Place;
    onEdit: () => void;
    onDelete: () => void;
    onCancel: () => void
}

const PlacePostCard = ({place, onEdit, onDelete, onCancel}: PlacePostCardProps) => { 

    return (

        <SubCard className='flex justify-between mt-2'>

            <Content className='w-full flex flex-col mr-2'>

                <Text containerClass='text-lg font-semibold' text={place.name} />

                <SubCard>

                    <Text text={place.address?.street} />

                    <Text text={place.address?.addStreet} />

                    <Content className='flex items-center'>
                        <Text containerClass='mr-2' text={place.address?.city} />
                        {place.address?.zipCode ? <div className='mr-2'>-</div> : null}
                        <Text text={place.address?.zipCode} />
                    </Content>

                    <Content className='flex items-center'>
                        <Text containerClass='mr-2' text={place.address?.country} />
                        {place.address?.state ? <div className='mr-2'>-</div> : null}
                        <Text text={place.address?.state} />
                    </Content>

                    <Content className='flex items-center'>
                        <ButtonIcon icon='globe' iconClass='h-6 w-6 mr-1' iconLeft={true}>
                            {place.address?.geoPoint?.latitude + ', ' + place.address?.geoPoint?.longitude}
                        </ButtonIcon>
                    </Content>

                </SubCard>

                {place.text !== '' &&
                <SubCard>
                    <div>{place.text}</div>
                </SubCard>
                }
            
            </Content>

            <Content className='w-8'>
        

                    <ButtonIcon icon='edit' className='h-8 w-8 flex justify-center items-center rounded-full bg-primary text-secondary mb-2' iconClass='w-5 h-5'
                            onClick={onEdit}
                    />

                    <ButtonIcon icon='delete' className='h-8 w-8 flex justify-center items-center rounded-full bg-danger text-white mb-2' iconClass='w-5 h-5'
                            onClick={onDelete}
                    />

                    <ButtonIcon icon='close' className='h-8 w-8 flex justify-center items-center rounded-full bg-warning text-secondary' iconClass='w-8 h-8'
                            onClick={onCancel}
                    />


                </Content>

        </SubCard>
  
    );
};


export { PlacePostCard };