import { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { postApi, useLocalStorage } from '../../utils';
import { User } from '../../../../shared/schema/index';
import { ButtonAction, ContentCenter, InputMail, InputPassword, InputText } from '../../components';

const LoginView = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation(['auth', 'nav']);
  const [token, setSessionToken] = useLocalStorage('t');
  const [, setMe] = useLocalStorage('me');
  const [username, setUsername] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [attribute, setAttribute] = useState<string>('');
  const [timeout, setTimeout] = useState<boolean>(false);

  useEffect(() => {
    
    if (location?.state?.username) setUsername(location.state.username);
    
    if (location?.search?.split('username=')[1]) setUsername(location.search.split('username=')[1].replace('%40', '@'));
    
    if (location.pathname === '/verifyEmailSuccess') toast.success(t('login.success.verifyEmailSuccess') as string, {theme: 'colored'});
    
    if (location.pathname === '/passwordResetSuccess') toast.success(t('login.success.passwordResetSuccess') as string, {theme: 'colored'});
    
    if (location.pathname === '/loginTimeout' && !timeout) {
      toast.error(t('login.error.reconnect') as string, {theme: 'colored'});
      setTimeout(true);
      navigate('/login');
    };
  },[t, location, navigate, timeout]);

  console.log('login');

  const onLogin = async() => {
    if (username === '') return toast.error(t('login.error.username') as string, {theme: 'colored'});
    if (password === '') return toast.error(t('login.error.password') as string, {theme: 'colored'});

    const user = await postApi<User>('/login', {
      username: username?.toLowerCase().trim(),
      password: password,
    }, {forceInstance: true});
    console.log(user)
    
    if (typeof user !== 'string') {
      if (user.banished) {

        toast.error(t('login.error.banished') as string, {theme: 'colored'});
        //TODO go to contact home page
      
      } else if (user.emailVerified) {
        if (token && token !== user.sessionToken) {
          localStorage.removeItem('n');
        }

        setSessionToken(user.sessionToken);
        setMe(user);
        navigate('/');
        return setTimeout(false);
      }
      return toast.error(t('login.error.noRegister') as string, {theme: 'colored'});
    }
    toast.error(t('login.error.retry') as string, {theme: 'colored'});
  };

  return (
    <div className="w-full h-full bg-piPrimary">
      <div className="w-full h-full flex flex-col items-center bg-login-pattern bg-cover bg-no-repeat overflow-auto">
        <div className="w-80 sm:w-96 flex flex-col justify-center bg-primary/80 text-white px-1 my-12">
          <header className="w-80 sm:w-96 mb-10 pr-4 pt-4">
            <h1 className='text-center text-2xl mt-2'>
              {t('siteName')}
            </h1>
            <h2 className='text-center text-xl italic mt-4'>
              {t('login.title')}
            </h2>
          </header>

          <form onSubmit={(event) => event.preventDefault()}>

            <InputMail id='username' label={t('login.id')} value={username} onChange={(event) => setUsername(event.target.value)} placeholder={t('login.idPlaceholder')}                 className="w-full rounded-full py-4 pl-8 pr-4 outline-none text-secondary"
              //autoComplete="on"
            />
           
            <InputPassword id='password' type='pass'
              value={password}
              onChange={(event) => setPassword(event.target.value)}
              onKeyDown={(event) => {if(event.key==="Enter"){
                onLogin();
              }}}
              //autoComplete="on"
            />

            <InputText id="attribute" containerClass='hidden'
              label={t('login.attribute')}
              value={attribute}
              onChange={(event) => setAttribute(event.target.value)}
            />

          </form>

          <ContentCenter className='mb-6'>
            <Link to={t('forgotPassword.path', {ns: 'nav'})} className="text-white font-semibold transition duration-300 hover:opacity-80">
              {t('login.forgotPassword')}
            </Link>
          </ContentCenter>

          <ButtonAction action='valid'
            onClick={() => onLogin()}
          />

          <ContentCenter showIf={false} className='mb-6'>
            <Link to='/register' className="text-white font-bold text-lg transition duration-300 hover:opacity-80">
              {t('register.title')}
            </Link>
          </ContentCenter>

          <ContentCenter className='mb-6'>
            <Link to={t('home.path', {ns: 'nav'})} className="text-white font-bold text-lg transition duration-300 hover:opacity-80">
              {t('backHome')}
            </Link>
          </ContentCenter>
        
        </div>
      </div>
    </div>
  );
};

export default LoginView;
