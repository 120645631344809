import { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { AuthCtx } from '../_context/Context';
import { ButtonAction, ButtonFooter, Card, CardTitle, ColorPicker, ContentCol } from '../../components';


type FontSettingProps = {
  color: string;
  setColor: (u: string) => void;
  saveSetting: () => void;
  open?: boolean;
}

const ColorSetting = ({color, setColor, saveSetting, open}:FontSettingProps) => {
    const [auth] = useContext(AuthCtx);
    const { t } = useTranslation(['siteSetting', 'error']);
    const [openColor, setOpenColor] = useState<boolean>(open ? open : false);

    return (

        <Card className="mb-2 p-2 sm:p-4">
            <CardTitle title={t('color')}>

                <div className='flex items-center'>
                    <div className="swatch" style={{backgroundColor: auth.siteSetting.color}}/>
                    {openColor && color !== auth.siteSetting.color &&
                    <div className="swatch mx-2" style={{ backgroundColor: color }}/>
                    }
                </div>
                
                <ButtonAction action='modify'
                    showIf={!openColor}
                    className='ml-2'
                    onClick={() => setOpenColor(true)}
                />


            </CardTitle>
 
            <ContentCol showIf={openColor} >
              
                <ColorPicker
                    color={auth.siteSetting.color}
                    onChange={setColor}
                />
              
                <ButtonFooter
                    action='apply'
                    ifAction={color !== auth.siteSetting.color}
                    onValid={() => {
                        saveSetting();
                        setOpenColor(false)
                    }}
                    onCancel={() => {
                        setColor(auth.siteSetting.color)
                        setOpenColor(false)
                    }}

                />

            </ContentCol>
            
        </Card>
    );
};

export { ColorSetting };