import { useTranslation } from "react-i18next";
import { PiProduct } from "../../../shared/schema";

const PiProductTranslate = (p: PiProduct) => {
    const { t } = useTranslation('admin');

    p.label = t('piPay.label', {context: p.code});
    p.description = t('piPay.description', {context: p.code, email: p.option.email, email2Months: p.option.email * 2, emailMonth: p.option.email / 12});
    p.description2 = t('piPay.priceLabel', {price: p.price.amount, duration: p.option.nbMonth, formatParams: {price: { currency: p.price?.currency }} })
    return p;
}

export { PiProductTranslate }