import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { HelpCtx } from '../../_context/Context';
import { Article } from '../../../../../shared/schema/';
import { ButtonFooter, ContentCol, InputText, SubCard, ContentRow, Text, TextEditor, ButtonIcon } from '../../../components';
import { saveClassApi, toFirstUpperCase } from '../../../utils';
import { newArticle, objectWithoutParse } from '../../_object';

type ArticleEditCardProps = {
    article?: Article;
    onSave: (a: Article) => void
    onCancel: () => void
}

const ArticleEditCard = ({article, onSave, onCancel}: ArticleEditCardProps) => { 
    const { t } = useTranslation(['datasite']);
    const {help, setHelp} = useContext(HelpCtx);
    const [title, setTitle] = useState<string>(article?.title ? article.title : '');
    const [text, setText] = useState<string>(article?.text ? article.text : '');

    const checkValidArticle = () => {
        if (title !== '') return true

        return false
    }

    const handleChange = (content: any, delta: any, source: any, editor: any) => {
        //TODO hors line save article in memoire
        setText(editor.getContents());
    }

    const onSaveArticle = async() => {
        const saveArticle = article ? article : newArticle() 

        if (title !== article?.title) saveArticle.title = title
        saveArticle.text = text

        const savedArticle = await saveClassApi('Article', objectWithoutParse(saveArticle), article?.objectId)
        if (typeof savedArticle !== 'string') {
            return onSave(saveArticle)
        }

        
        toast.error(t('errorRetry') as string, {theme: 'colored'})
    }

    return (
  

        <SubCard>

            <ContentCol>

                <InputText placeholder={t('article.title')}
                    label={t('article.title')}
                    labelPosition='left'
                    value={title}
                    onChange={(e) => setTitle(toFirstUpperCase(e.target.value))}
                    autoFocus containerClass='font-semibold bg-white'
                    isRequired={true}
                />

                
                <ContentRow>
                    <Text textClass='text-base font-medium pl-2 mr-3' text={t('article.textTitle')} />
                    <ButtonIcon icon='help' iconClass={'text-info h-full w-full m-auto'}
                        onClick={() => setHelp({page: 'member', section: 'edit', article: 'emailing'})}
                        className={'h-6 w-6'}
                    />
                </ContentRow>
    

                    <TextEditor
                        text={text}
                        onChange={handleChange}
                        className='h-[400px]'
                        placeholder={t('article.textEditorPlaceholder')}
                    />

            </ContentCol>

            <ButtonFooter action='save'
                ifAction={checkValidArticle()}
                onValid={() => onSaveArticle()}
                onCancel={() => onCancel()}
            />

        </SubCard>

    );
};


export { ArticleEditCard };