import { useEffect, useState, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import AddName from './AddName';
import { postFunctionsApi } from '../../../utils';
import { AuthCtx } from '../../_context/Context';
import { Site } from '../../../../../shared/schema/index';
import AddSubscription from './AddSubscription';
import AddSiteSetting from './AddSiteSetting';
import { ContentCenter, Icon, Suspense } from '../../../components';


const CreateSite = () => {
  const [auth] = useContext(AuthCtx);
  const { t } = useTranslation(['admin', 'auth']);
  const navigate = useNavigate()

  const [siteId, setSiteId] = useState<string>();
  const [step, setStep] = useState<number>(1);

  const [saving, setSaving] = useState<boolean>(true);

  const stripeSUBid = new URLSearchParams(window.location.search).get('stripeSUBid');

  useEffect(() => {
    async function fetchData() {

      //TMP wait WebWook - Stripe  
      if (stripeSUBid) {     
        const site = await postFunctionsApi<Site>('createPiCustomerSite', {stripeSUBid: stripeSUBid});
        if (typeof site !== 'string') {
          return navigate('/');
        }
      }
 
      //Get Customer
      const getCustomer = await postFunctionsApi<{siteId?: string}>('getCustomerAdminTmp');
      if (typeof getCustomer !== 'string') {
        //Go to AddSiteSetting - Step 3 
        if (getCustomer.siteId) {
          console.log(getCustomer)
          console.log(getCustomer.siteId)
          setSiteId(getCustomer.siteId)
          setSaving(false);
          return setStep(3);
        //Go to AddSubscription - Step 2 
        } else {
          setSaving(false);
          return setStep(2);
        }
      }
      //Error get Customer
      toast.error(t('stripe.error.retry') as string, {theme: 'colored'});
    }
    //Check User name - Step 1
    (!auth.me.firstName || !auth.me.lastName) ? setSaving(false) : fetchData();

  }, [t, auth.me, stripeSUBid, navigate]);

  return (
  <>
  
    <Suspense showIf={saving} />
    
    <div className="w-full h-full bg-piPrimary text-white border-white">
      <div className="w-full h-full flex flex-col items-center bg-login-pattern bg-cover bg-no-repeat overflow-auto">
        <div className="w-80 sm:w-96 flex justify-center bg-piPrimary/80 flex-col px-1 my-12">
          <header className="w-80 sm:w-96 mb-10 pr-4 pt-4">
            <h1 className='text-center text-2xl text-white mt-2'>
              {t('siteName', {ns: 'auth'})}
            </h1>
            <h2 className='text-center text-xl italic text-white mt-4'>
              {t('createSite.title')}
            </h2>
          </header>

          <ContentCenter>

            <div className="flex items-center mb-4 justify-center">
              
              <div className='w-10 h-10 rounded-full flex items-center justify-center bg-white text-neutre'>
                <Icon name='user' className='w-6 h-6' />
              </div>
              <div className={['h-2 w-12', step >= 2 ? 'bg-white' : 'bg-neutre opacity-80'].join(' ')}></div>
              <div
                className={['w-10 h-10 rounded-full flex items-center justify-center',
                  step >= 3 ? 'bg-white text-neutre' : 'bg-neutre text-white opacity-80',
                ].join(' ')}
              >
                <Icon name='creditCard' className='w-6 h-6' />
              </div>
                  
              <div className={['h-2 w-12', step >= 3 ? 'bg-white' : 'bg-neutre opacity-80'].join(' ')}></div>
              <div
                className={['w-10 h-10 rounded-full flex items-center justify-center',
                  step >= 4 ? 'bg-white text-neutre' : 'bg-neutre text-white opacity-80',
                ].join(' ')}
              >
                <Icon name='setting' className='w-6 h-6' />
              </div>         
            </div>
              
            {step === 1 && 
            <AddName setStep={setStep}/>
            }

            {step === 2 && 
            <AddSubscription setSiteId={setSiteId} setStep={setStep} />
            }

            {step === 3 && siteId &&
            <AddSiteSetting siteId={siteId} />
            }
          
          </ContentCenter>

          <ContentCenter showIf={step !== 4}>
            <Link to={t('home.path', {ns: ['nav']})} className="text-white font-bold text-lg transition duration-300 hover:opacity-80">
              {t('backHome', {ns: ['auth']})}
            </Link>
          </ContentCenter>

        </div>
      </div>
    </div>
  </>
  );
};

export { CreateSite }
