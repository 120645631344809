import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { postFunctionsApi } from '../../utils';
import { ParseDate, SiteSub } from '../../../../shared/schema/index';
import { Modal } from '../../components';

type StopPlanModalProps = {
    siteSub: SiteSub;
    state: 'stop' | 'cancel';
    setState: (s: 'stop' | 'cancel') => void;
    open: boolean;
    onClose: () => void;
};

const StopPlanModal = ({siteSub, state, setState, open, onClose}: StopPlanModalProps) => {
    const { t } = useTranslation('admin');

    const onStopPlan = async() => {
        const stopSub = await postFunctionsApi<{planEndAt?: ParseDate, canceled?: boolean}>('stopPlan', {siteId: siteSub.site?.objectId, isCancel: state === 'cancel' ? true : false });
        if (typeof stopSub !== 'string') {
            siteSub.planEndAt = stopSub.planEndAt ? stopSub.planEndAt : undefined;
            setState(stopSub.canceled ? 'stop' : 'cancel');
            stopSub.canceled
            ? toast.success(t('siteAdmin.stopPlanCancelSuccess') as string, {theme: 'colored'})
            : toast.success(t('siteAdmin.stopPlanSuccess') as string, {theme: 'colored'});

            return onClose();
        }
        return toast.error(t('siteAdmin.stopPlanError') as string, {theme: 'colored'});
    };

    return (
        
        <Modal variant='warning'
            title={t('siteAdmin.stopPlanModalTitle')}
            onValid={() => onStopPlan()}
            open={open}
            onClose={onClose}
        >
            {state === 'stop' ? t('siteAdmin.stopPlanModalDescription') : t('siteAdmin.stopPlanCancelModalDescription')}
        </Modal>
    );
};

export {StopPlanModal};