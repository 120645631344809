import { useContext } from "react";
import { HelpCtx } from "../views/_context/Context";
import { Help } from "../../../shared/schema"
import { ButtonIcon, Icon, ColorEnum, IconEnum, onTextColor} from ".";

interface TextProps {
  title?: React.ReactNode | string;
  levelTitle?: number;
  text?: React.ReactNode | string;
  color?: ColorEnum;
  icon?: IconEnum;
  showIf?: boolean;
  containerClass?: string;
  titleClass?: string;
  textClass?: string;
  iconClass?: string;
};

const titleLevel1Style ='font-medium text-lg mr-4';
const textLevel1Style ='font-semibold text-lg mr-4';
const titleLevel2Style ='font-medium text-base mr-2';
const textLevel2Style ='font-semibold text-base mr-2';

const TextBase = ({ title, text, icon, containerClass, titleClass, textClass, iconClass, color, showIf= true}: TextProps) => {
  return showIf ? (
    <div className={[color ? onTextColor(color) : null, 'flex flex-wrap items-center', containerClass].join(' ')}>
      {icon ? <Icon name={icon} className={['h-6 w-6', iconClass].join(' ')} /> : null}
      <div className="flex flex-wrap">
        {title && <div className={titleClass}>{title}</div>}
        {text && <div className={textClass}>{text}</div>}
      </div>
    </div>
    ) : null;
};

const Title = ({ title, levelTitle=1, text, icon, containerClass, titleClass, textClass, color, showIf= true }: TextProps) => {
  return <TextBase title={title} text={text} icon={icon} color={color} showIf={showIf}
    containerClass={['flex items-center', containerClass].join(' ')}
    titleClass={[levelTitle === 1 ? titleLevel1Style : titleLevel2Style ,titleClass].join(' ')}
    textClass={[ levelTitle === 1 ? textLevel1Style : textLevel2Style ,textClass].join(' ')}
  />;
};

const Text = ({ text, containerClass, textClass, color, showIf= true }: TextProps) => {
  return <TextBase text={text} color={color} showIf={showIf}
    containerClass={['text-base', containerClass].join(' ')}
    textClass={textClass}
  />;
};

const TextCenter = ({ text, containerClass, textClass, color, showIf= true }: TextProps) => {
  return <TextBase text={text} color={color} showIf={showIf}
    containerClass={['text-center', containerClass].join(' ')}
    textClass={textClass}
  />;
};

const Description = ({ text, containerClass, color, showIf= true }: TextProps) => {
  return <TextBase text={text} color={color} showIf={showIf} containerClass={['text-sm', containerClass].join(' ')} />;
};

const TextImportant = ({ text, containerClass, textClass, color, showIf= true }: TextProps) => {
  return <TextBase text={text} color={color} showIf={showIf}
      containerClass={['font-semibold text-base', containerClass].join(' ')}
      textClass={textClass}  
  />;
};

interface TextHelpProps extends TextProps { help: Help };

const TextHelp = ({ text, help, containerClass, textClass, iconClass, color, showIf= true }: TextHelpProps) => {
  const {setHelp} = useContext(HelpCtx);
  return showIf ? <div className="flex items-center">
    <Text text={text}  color={color}
      containerClass={['text-neutre text-base mr-1', containerClass].join(' ')}
      textClass={textClass}
    />
    <ButtonIcon icon='help' iconClass={'text-info h-full w-full m-auto'}
      onClick={() => setHelp(help)}
      className={'h-6 w-6'}
    />
   
            
    </div> : null;
};

//Textarea
type TextareaProps = {
  value: string;
  onChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  placeholder?: string;
  className?: string;
};

const Textarea = ({ value, placeholder, onChange, className }: TextareaProps) => {
  
  return (
    <textarea
      className={[
        'w-full border border-neutreLight focus:border-neutre rounded-md outline-none resize-none py-1 px-2 ',
        className,
      ].join(' ')}
      placeholder={placeholder}
      value={value}
      onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => onChange(event)}
    />
  );
};


export {
  TextBase,
  Text,
  TextCenter,
  Title,
  Description,
  TextImportant,
  TextHelp,
  Textarea
};
