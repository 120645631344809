import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { AuthCtx } from '../_context/Context';
import { RolesResponse } from '.';
import { ButtonText, ContentCenter } from '../../components';
import { authObject } from '../_object';
import { AuthNavView } from './ProtectedView';


type SelectRoleProps = {
    roles: RolesResponse[];
    selectRole: (n: AuthNavView) => void;
};

const SelectRole = ({roles, selectRole}: SelectRoleProps) => {
  const { t } = useTranslation('auth');
  const [auth, setAuth] = useContext(AuthCtx);

  const onSelect = (role: RolesResponse) => {
    const newAuth = authObject(role)
    newAuth.me = auth.me;
    setAuth(newAuth);
    selectRole('default');
  }

    return (
      <div className="w-full h-full bg-primary">
        <div className="w-full h-full flex flex-col items-center bg-login-pattern bg-cover bg-no-repeat overflow-auto">
          <div className="w-80 sm:w-96 flex justify-center bg-primary/80 flex-col px-1 my-12">
            <header className="w-80 sm:w-96 mb-10 pr-4 pt-4">
              <h1 className='text-center text-2xl text-white mt-2'>
                {t('siteName')}
              </h1>
              <h2 className='text-center text-xl italic text-white mt-4'>
                {t('selectRole.title')}
              </h2>
            </header>
  
            <ContentCenter className='mb-6'>
              {roles.map((role, key) =>
                <ButtonText text={role.label === 'adminTmp' ?  t('selectSite.finishCreateSite') : role.siteName}
                  key={key}
                  variant='filled-pi'
                  className='text-white text-lg font-bold border-2 border-white w-full py-2 rounded-full transition duration-300 hover:bg-white hover:text-primary my-2'
                  onClick={() => onSelect(role)}
                />
              )}
            </ContentCenter>
          
          </div>
        </div>
      </div>
    );
  };

export { SelectRole };