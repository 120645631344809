//Color
type ColorEnum = 'piPrimary' | 'piSecondary' | 'primary' | 'secondary' | 'neutre' | 'neutreLight' | 'success' | 'warning' | 'info' | 'danger' | 'white';

const onTextColor = (color: ColorEnum) => {
    switch (color) { 
        case 'piPrimary' : return 'text-piPrimary';
        case 'piSecondary' : return 'text-piSecondary';
        case 'primary' : return 'text-primary';
        case 'secondary' : return 'text-secondary';
        case 'neutre' : return 'text-neutre';
        case 'neutreLight': return 'text-neutreLight'
        case 'success' : return 'text-success';
        case 'warning' : return 'text-warning';
        case 'info' : return 'text-info';
        case 'danger' : return 'text-danger';
        case 'white' : return 'text-white';
        default: return 'text-neutre';
    }
}

const onColorToHex = (color: string) => {
    switch (color) { 
        case 'piPrimary' : return "#90D3ED";
        case 'piSecondary' : return "#6231af";
        case 'primary' : return 'text-primary';
        case 'secondary' : return 'text-secondary';
        case 'neutre' : return "#27272a";
        case 'success' : return "#5e7127";
        case 'warning' : return "#ff9900";
        case 'info' : return "#2094f3";
        case 'danger' : return "#ff5724";
        default: return color;
    }
}

//Text Size
type TextSizeEnum = 'xs' | 'sm' | 'base' | 'lg' | 'xl' | '2xl';

const onTextSize = (size: TextSizeEnum) => {
    switch (size) { 
        case 'xs' : return 'text-xs';
        case 'sm' : return 'text-sm';
        case 'base' : return 'text-base';
        case 'lg' : return 'text-lg';
        case 'xl' : return 'text-xl';
        case '2xl' : return 'text-2xl';
        default: return 'text-base';
    }
}

//Button
type ActionButtonEnum =
    "cancel" | "cancelChange" | "close" |
    "save" | "copy" | "link" |
    "download" | "upload" |
    "valid" | "confirm" |
    "remove" | "clear" |
    "apply" | "change" | "modify" |
    "send" |
    "next" | "previous" |
    "payment" |
    "loadFile" | "loadImage" |  
    "comeback";

type VariantButtonEnum = 'filled-pi' | 'filled-primary' | 'filled-primary-lg' | 'filled-neutre' | 'filled-neutre-lg' | 'filled-success-lg' | 'link-neutre';
type VariantButton = { variant?: VariantButtonEnum, action?: ActionButtonEnum }

const onButtonStyle = (variant?: VariantButtonEnum , action?: ActionButtonEnum ) => {
    const base = 'transition duration-300 text-sm font-medium md:text-base py-2 px-4 '
    const filled = base + 'rounded-full border ';

    switch (action) { 
        case "modify":
            return filled + 'bg-primary text-secondary border-primary hover:bg-opacity-70 hover:border-opacity-70';  
        
        case "valid":
        case "apply":
        case "save":
        case "copy":
        case "link":
        case "payment":
        case "download":
        case "upload":
            return filled + 'bg-success text-white border-success hover:bg-opacity-70';

        case "next":
        case "previous":
        case "close":
        case "remove":
        case "clear":
            return filled + 'bg-neutreLight text-white border-neutreLight hover:opacity-70';

        case "confirm":
        case "change":
        case "cancelChange":
        case "send":
            return filled + 'bg-info text-white border-info hover:bg-opacity-70';
        case "loadImage":
        case "loadFile":
            return 'text-sm font-medium md:text-base rounded-lg bg-primary text-secondary border-primary hover:bg-opacity-70 py-1 px-2';
        case "comeback":
        case "cancel":
            return filled + 'text-neutre border-neutre hover:bg-warning hover:text-white hover:border-warning';
        default: break;
    }

    switch (variant) {
        case 'filled-primary':
            return filled + 'bg-primary text-secondary border-primary hover:bg-opacity-70 '
        case 'filled-primary-lg':
            return 'text-sm font-medium md:text-base rounded-lg bg-primary text-secondary border-primary hover:bg-opacity-70 py-1 px-2 '
        case 'filled-neutre':
            return filled + 'bg-neutreLight text-white hover:bg-opacity-70 '
        case 'filled-neutre-lg':
            return 'text-sm font-medium md:text-base rounded-lg bg-neutreLight text-white hover:bg-opacity-70 py-1 px-2 '
        case 'link-neutre':
            return 'font-medium text-neutre hover:opacity-70 mx-2'
        case 'filled-success-lg':
                return filled + 'text-sm font-medium md:text-base rounded-lg bg-success text-white border-success hover:bg-opacity-70 py-1 px-2 '
        default: break;
    }
    
    return '';
}

//Input
type VariantCheckboxEnum = 'success' | 'warning' | 'danger' | 'info';
const checkboxStyle = (variant: VariantCheckboxEnum) => {
    switch(variant) {
      case 'success': return 'accent-success'
      case 'warning': return 'accent-warning'
      case 'danger': return 'accent-danger'
      case 'info': return 'accent-info'
    }
  }


export { onButtonStyle, onTextColor, onTextSize, checkboxStyle }
export type { ColorEnum, TextSizeEnum, ActionButtonEnum, VariantButtonEnum, VariantButton, VariantCheckboxEnum }