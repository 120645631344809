import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { ContactPi } from '../../../../shared/schema/index';
import { getClassApi, postClassApi } from '../../utils';
import { parseDate } from '../_object';
import { View } from '../../components';
import { ContactPiList } from './ContactPiList';
import { ContactPiEdit } from './ContactPiEdit';

const ContactPiView = () => {
    const { t } = useTranslation(['contactpi']);
    const [requestList, setRequestList] = useState<ContactPi[]>([]);

    useEffect(() => {
        async function fetchdata() {
            const getContactPi = await getClassApi<ContactPi>('ContactPi', {
                where : JSON.stringify({
                    show: true
                }),
                order: '-createdAt'
            })
            if (typeof getContactPi !== 'string') {
                setRequestList(getContactPi.results);
            } else toast.error(t('errorConnection') as string, {theme: 'colored'});
        }
        fetchdata()
    }, []);

    const saveRequest = async(request: ContactPi) => {
        const newMessage = await postClassApi<ContactPi>('ContactPi', request);
        if (typeof newMessage !== 'string') {
            request.createdAt = newMessage.createdAt as string;
            request.objectId = newMessage.objectId;
            const newList = requestList;
            newList.unshift(request);
            setRequestList(newList);
            return true
        }
        toast.error(t('errorRetry') as string, {theme: 'colored'});
        return false
    };

    return (
        <View withFooter={false}>

            <ContactPiEdit saveRequest={saveRequest} />
        
            {requestList.length > 0 &&
            <ContactPiList requests={requestList} setRequests={setRequestList} />
            }
 
        </View>
    );
};

export { ContactPiView };
