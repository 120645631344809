import { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { AuthCtx } from '../_context/Context';
import { blobToBase64, postFunctionsApi } from '../../utils';
import { ImageCrop, Modal } from '../../components';


type EditAvatarProps = {
    openAvatarModal: boolean;
    setOpenAvatarModal: (o: boolean) => void;
};

const EditAvatar = ({openAvatarModal, setOpenAvatarModal}:EditAvatarProps) => {
    const [auth, setAuth] = useContext(AuthCtx)
    const { t } = useTranslation(['account', 'error']);
    const [img, setImg] = useState<string>('');
    const [saving, setSaving] = useState<boolean>(false);

    useEffect(() => {
        if (auth.me.avatar) {
            setImg(auth.me.avatar);
        }
    },[auth.me.avatar]);

    const save = async(file: File | Blob) => {
        setSaving(true)
        if (file) {
            let data = await blobToBase64(file)
            data = data.replace('data:image/png;base64,', '')
            const fileSaved = await postFunctionsApi('saveAvatar', {data: data});
            if (typeof fileSaved !== 'string') {
                if (auth.me.avatar) URL.revokeObjectURL(auth.me.avatar);
                const blobUrl = URL.createObjectURL(file);
    
                const me = auth.me
                me.avatar = blobUrl
                setAuth({...auth, me: me});
                setImg(blobUrl)
                setOpenAvatarModal(false);
            } else {
                toast.error(t('common', {ns: 'error'}) as string, {theme: 'colored'});
            }
        }
        setSaving(false);
    };

    const removeAvatar = async () => {
        if (img !== '') {
            const avatarRemoved = await postFunctionsApi<any>('deleteAvatar');
            if (avatarRemoved) {
                if (auth.me.avatar) URL.revokeObjectURL(auth.me.avatar);
                const me = auth.me
                me.avatar = undefined
                
                setAuth({...auth, me: me});
                setImg('');
            };
        };
    }

    const onCancel = () => {
        setOpenAvatarModal(false)
    }

    return (
    
        <Modal
            variant="primary"
            open={openAvatarModal}
            title={t('editAvatar.title')}
            footer={false}
            onClose={onCancel}
        >
            
            <ImageCrop   
                removeIf={img !== ''}
                saving={saving}
                imgFormat='circular'
                height={200}
                imgSrc={img}
                setImgSrc={setImg}
                onSaveImage={save}
                onRemoveImage={removeAvatar}
                onCancel={onCancel}
            />
             
        </Modal>
    );
};

export { EditAvatar };
