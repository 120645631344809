import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { postFunctionsApi } from '../../utils';
import { ButtonAction, ButtonFooter, ContentCol, Radio, InputText } from '../../components';
import { ModalBase } from '../../components/Modal';
import { toast } from 'react-toastify';
import { PiCustomer, Society, TypePiCustomer } from '../../../../shared/schema/index';

type ChangeTypeModalProps = {
    piCustomer: PiCustomer;
    open: boolean;
    onClose: () => void;
};

const ChangeTypeModal = ({piCustomer, open, onClose}: ChangeTypeModalProps) => {
    const { t } = useTranslation('admin');

    const [type, setType] = useState<{type?: TypePiCustomer, society?: Society}>({type : piCustomer.typeCustomer, society: piCustomer.society});

    const onChangeType = async() => {
        if (type.type === piCustomer.typeCustomer && JSON.stringify(type.society) === JSON.stringify(piCustomer.society)) return onClose();

        const changeType = await postFunctionsApi<boolean>('changeCustomerType', {
            piCustomerId: piCustomer.objectId,
            typeCustomer: type.type,
            society: type.society
        })
        if (typeof changeType !== 'string') {
            piCustomer.typeCustomer = type.type as TypePiCustomer;
            type.type === 'individual' ? piCustomer.society = undefined : piCustomer.society = type.society;
            
            toast.success(t('customerAdmin.successChange') as string, {theme: 'colored'});
            return onClose();
        }

        return toast.error(t('customerAdmin.errorChange') as string, {theme: 'colored'});
    }

    return (
        
        <ModalBase open={open} onClose={onClose} containerClass='p-2 sm:p-4' >
      
            <ContentCol >

                <ContentCol>

                    <Radio name='type' showIf={piCustomer.typeCustomer === 'society'}
                        value={{name:'individual', label: t('addCustomer.individual')}}
                        valueSelected={{name: type?.type, label: t('addCustomer.individual') }}
                        setValue={() => setType({...type, type: 'individual'})}
                    />

                    <Radio name='type'
                        value={{name:'society', label: t('addCustomer.society')}}
                        valueSelected={{name: type?.type, label: t('addCustomer.society') }}
                        setValue={() => setType({...type, type: 'society'})}
                    />

                </ContentCol>

                <ContentCol showIf={type?.type === 'society'} >

                    <InputText id='societyName'
                        label={t('customerAdmin.societyName')}
                        value={type?.society?.name ? type.society.name  : ''}
                        onChange={(event: any) => {
                            const society = type?.society ? type.society : {name: ''};
                            society.name = event.target.value;
                            setType({...type, society: society})
                        }}
                        placeholder={t('customerAdmin.societyName')}
                        isRequired={true}
                    />

                    <InputText id='societyVatNumber'
                        label={t('customerAdmin.societyVatNumber')}
                        value={type?.society?.vatNumber ? type.society.vatNumber  : ''}
                        onChange={(event: any) => {
                            const society = type?.society ? type.society : {name: ''};
                            society.vatNumber = event.target.value;
                            setType({...type, society: society})
                        }}
                        placeholder={t('customerAdmin.societyVatNumber')}
                    />

                    <InputText id='societyCompanyIdNumber'
                        label={t('customerAdmin.piCustomer.societyCompanyIdNumber')}
                        value={type?.society?.companyIdNumber ? type.society.companyIdNumber  : ''}
                        onChange={(event: any) => {
                            const society = type?.society ? type.society : {name: ''};
                            society.companyIdNumber = event.target.value;
                            setType({...type, society: society})
                        }}
                        placeholder={t('customerAdmin.societyCompanyIdNumber')}
                    />

                </ContentCol>

                <ButtonFooter
                    action='confirm'
                    onValid={() => onChangeType()}
                    onCancel={onClose}
                />

            </ContentCol>            

        </ModalBase>
    );
};

export {ChangeTypeModal};