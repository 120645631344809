import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { postFunctionsApi } from '../../utils';
import { RolesResponse } from '../../../../shared/schema/index';
import { ButtonFooter, Card, CardTitle, ContentCol, Checkbox, MapItems, ModalConfirm, Text } from '../../components';


export type CloseAccountProps = {
  onClose: () => void;
};

type RemoveRole = {id: string, close: boolean, siteName: string, roleName: string}

const CloseAccount = ({onClose}: CloseAccountProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation(['account', 'nav']);
  const [removeAccount, setRemoveAccount] = useState<boolean>(false);
  const [removeRoles, setRemoveRoles] = useState<RemoveRole[]>([]);
  const [modalData, setModalData] = useState<{account: boolean, roles?: RemoveRole[]}>();
  //TODO Questionnaire en cas de suppression de compte

  useEffect(() => {
    async function fetchdata() {
      const getRoles = await postFunctionsApi<RolesResponse[]>('getRoles');
      if (typeof getRoles !== 'string') {
        const roles = getRoles.map(r => {return {id: r.roleId, close: false, siteName: r.siteName, roleName: r.label}})
        setRemoveRoles(roles);
      }
    }
    if (removeRoles.length === 0) fetchdata()
  },[removeRoles])

  const modifyRole = (id: string) => {
    const rmvRoles = removeRoles?.map(rr => {
      if(rr.id === id) rr.close = !rr.close;
      return rr;
    });
    setRemoveRoles(rmvRoles);
  }

  const allRolesChecked = () => {
    return removeRoles?.every(r => r.close)
  }

  const onCloseAccount = async() => {
    const closeAccount = await postFunctionsApi('closeAccount', modalData);
    if (typeof closeAccount !== 'string') {
      localStorage.clear();
      navigate(t('home.path', {ns: 'nav'}));
      return
    }
    toast.error(t('editClose.retry') as string, {theme: 'colored'});
  };

  return (

    <Card>

      <CardTitle text={t('editClose.title')} />

      <ContentCol>     

        <Text text={t('editClose.closeSite')}/>

        <MapItems<RemoveRole> items={removeRoles} component={(r: RemoveRole, k:number) => (  
          <Checkbox
            key={k}
            id={'r'+ k}
            label={r.siteName + ' (' + r.roleName + ')'}
            value={r.id}
            checked={r.close}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => modifyRole(event.target.value)}
          />
        )}/>

        <Text showIf={allRolesChecked()} text={t('editClose.closeAccount')}/>  

        <Checkbox
          showIf={allRolesChecked()}
          id='account'
          color='warning'
          label={t('editClose.accountCheckbox')}
          checked={removeAccount}
          onChange={() => setRemoveAccount(!removeAccount)}
        />

        <ButtonFooter
          action='valid'
          onValid={() => setModalData({account: removeAccount, roles: removeRoles})}
          onCancel={() => {
            setRemoveRoles([])
            onClose()
          }}
        />

      </ContentCol>

      <ModalConfirm
        data={modalData}
        onValid={(data) => onCloseAccount()}
        onClose={() => {
          setModalData(undefined)
          setRemoveRoles([])
        }}
      />

    </Card>
      
  );
};

export default CloseAccount;