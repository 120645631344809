import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PDFViewer } from '@react-pdf/renderer';
import { PiCustomer, PiInvoice, User } from '../../../../shared/schema/index';
import { ButtonIcon, Card, CardTitle, ContentCol, Li, LiActions, LiHeader, ListMap, ModalBase, Pagination, Text } from '../../components';
import { CustomerList } from './AdminSubView';
import { postFunctionsApi } from '../../utils';
import { InvoicePDF } from './InvoicePDF';



type InvoiceAdminProps = {
    customerList: CustomerList[];
};

const InvoiceAdmin = ({customerList}: InvoiceAdminProps) => {
    const {t} = useTranslation('admin');

    const [piCustomerSeleted, setPiCustomerSeleted] = useState<PiCustomer>(customerList[0].customer);
    const [invoices, setInvoices] = useState<PiInvoice[]>();
    const [invoiceFilter, setInvoiceFilter] = useState<PiInvoice[]>([]);
    const [invoiceSelected, setInvoiceSelected] = useState<PiInvoice>();

    const [showInvoice, setShowInvoice] = useState<boolean>(false);
    const nbItemsPerPage = 12;

    //Load invoice
    useEffect(() => {
        async function fetchData(){
            const getInvoices = await postFunctionsApi<PiInvoice[]>('getPiInvoice', {
                piCustomerId: piCustomerSeleted.objectId,
            })
            if (typeof getInvoices !== 'string') {
                setInvoices(getInvoices)
                setInvoiceFilter(getInvoices.slice(0, nbItemsPerPage))
            }
        }
        fetchData();
    },[piCustomerSeleted])

    return (

        <ContentCol>

            {invoices && customerList.map((c: CustomerList) => (

            <Card key={c.customer.objectId}>
                    
                <CardTitle
                    title={t('customerAdmin.title')}
                    text={c.customer.typeCustomer === 'society' ? c.customer.society?.name : (c.customer.user as User)?.firstName + ' ' + (c.customer.user as User)?.lastName} 
                >
                    <ButtonIcon icon='visibility' showIf={c.customer.objectId !== piCustomerSeleted.objectId}
                        onClick={() => setPiCustomerSeleted(c.customer)}
                        iconClass="text-info ml-4"
                    />
                </CardTitle>

                <ListMap<PiInvoice> items={invoiceFilter} component={(invoice) => (
                    <Li key={invoice.objectId}>

                        <LiHeader className='grid-cols-[6rem_auto_4rem_2rem]'>

                            <Text containerClass='text-left' text={new Date(invoice.createdAt as string).toLocaleDateString()}/>

                            <Text text={t('invoiceAdmin.productLabel', {context: invoice.product?.name})}/>

                            <Text text={t('amountVal', { val: (invoice.amount as number) /100, formatParams: { val :{ currency: invoice.currency}}})}/>
                                                                    
                            <LiActions>

                                <ButtonIcon icon='visibility'
                                    iconClass={'h-6 w-6 text-info'}
                                    onClick={() => {
                                        setInvoiceSelected(invoice)
                                        setShowInvoice(true)
                                    }}
                                    className="mr-2"
                                />

                            </LiActions>

                        </LiHeader>



                    </Li>
                )} />


                <Pagination
                    nbItems={invoices.length}
                    nbItemsPerPage={nbItemsPerPage}
                    onChangePage={n => setInvoiceFilter(invoices.slice(n*nbItemsPerPage, (nbItemsPerPage*12 + 12)))}
                />

                <ModalBase
                    open={showInvoice}
                    onClose={() =>setShowInvoice(false)}
                >
                    {invoiceSelected &&
                    <PDFViewer height={600} width={380} >
                        <InvoicePDF invoice={invoiceSelected}/>
                    </PDFViewer>
                    }
                
                </ModalBase>



                
            </Card>

            ))}





               
                
        </ContentCol>


     
   
    );
};

export { InvoiceAdmin };