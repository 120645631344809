import * as React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import { useTranslation } from 'react-i18next';

import { DashboardView, LoginView, RegisterView, ForgotPasswordView, NoMatchView, ProtectedView, ChoosePasswordView, ChangeEmail } from './views/auth';
import { EmailingView } from './views/emailing/EmailingView';
import { AccountView } from './views/account';
import { Suspense } from './components';
import RgpdView from './views/rgpd/RgpdView'
import { HomeView } from './views/home/HomeView';
import { PresenceView } from './views/presence/PresenceView';
import { EventView } from './views/event/EventView';
import { MemberView } from './views/member/MemberView';
import { DataSiteView } from './views/dataSite/DataSiteView';
import { SiteSettingView } from './views/adminConfig/SiteSettingView';
import { ContactPiView } from './views/contactPi/ContactPiView';
import { GalleryView } from './views/gallery/GalleryView';

import { AdminUserView } from './views/adminUsers/AdminUserView';
import { AdminSubView } from './views/adminSubscription/AdminSubView';

//const UsersView = React.lazy(() =>  import("./views/usersAdmin/UsersView"));
//const ConfigurationView = React.lazy(() =>  import("./views/configuration/ConfigurationView"));
//const CommunicationNavView = React.lazy(() =>  import("./views/communication/CommunicationView"));
//const OpinionsView = React.lazy(() =>  import("./views/survey/OpinionsView"));
//const RgpdView = React.lazy(() =>  import("./views/rgpd/RgpdView"));

const App = () => {
  const { t } = useTranslation('nav');


  return (
    <BrowserRouter>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <React.Suspense fallback={<Suspense />}>
        <Routes>
          <Route path="/" element={<ProtectedView />} >
            <Route index element={<DashboardView />} />
            
            <Route path={t('path', {context: "w1"})} element={<PresenceView />} />
            <Route path={t('path', {context: "w2"})} element={<EventView />} />
            <Route path={t('path', {context: "w3"})} element={<EmailingView />} />
            <Route path={t('path', {context: "w4"})} element={<MemberView />} />
            <Route path={t('path', {context: "w5"})} element={<DataSiteView />} />

            <Route path={t('path', {context: "u1"})} element={<AccountView />} />
            <Route path={t('path', {context: "u3"})} element={<AdminSubView />} />
            <Route path={t('path', {context: "u4"})}  element={<AdminUserView/>} />
            <Route path={t('path', {context: "u5"})} element={<SiteSettingView />} />
            <Route path={t('path', {context: "u6"})}  element={<ContactPiView />} />
            <Route path={t('path', {context: "u7"})}  element={<GalleryView />} />
            
            <Route path="/rgpd" element={<RgpdView />} />
          </Route>

          <Route path={t("home.path")} element={<HomeView />} />
          <Route path="/login" element={<LoginView />} />
          <Route path="/loginTimeout" element={<LoginView />} />         
          <Route path={t("forgotPassword.path")} element={<ForgotPasswordView />} />
          <Route path="/passwordResetSuccess" element={<LoginView />} />
          <Route path="/register" element={<RegisterView />} />
          <Route path="/choosePassword" element={<ChoosePasswordView />} />
          <Route path="/verifyEmailSuccess" element={<LoginView />} />
          <Route path="/changeEmail" element={<ChangeEmail />} />

          <Route path="*" element={<NoMatchView />} />
          
        </Routes>
      </React.Suspense>
    </BrowserRouter>
  );
};

export default App;
