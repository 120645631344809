import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { postFunctionsApi } from '../../utils';
import { Address, PiCustomer } from '../../../../shared/schema/index'
import { addressObject } from '../_object';
import { AddressInput } from '../_shared';
import { ButtonFooter, ModalBase } from '../../components';


type ChangeAddressModalProps = {
    piCustomer: PiCustomer;
    open: boolean;
    onClose: () => void;
};

const ChangeAddressModal = ({piCustomer, open, onClose}: ChangeAddressModalProps) => {
    const { t } = useTranslation('admin');
    const [address, setAddress] = useState<Address>(piCustomer.address ? piCustomer.address as Address : addressObject({}) as Address);

    const onSaveAddress = async() => {
        if (address.country === '' ||
            address.street === '' ||
            address?.city === '' ||
            address?.zipCode === ''
        ) return toast.error(t('address.errorAddress') as string, {theme: 'colored'})

        const changeAddress = await postFunctionsApi<string>('changeCustomerAddress', {
            piCustomerId: piCustomer.objectId,
            address: address
        })
        if (typeof changeAddress !== 'string') {
            piCustomer.address = address
            toast.success(t('customerAdmin.successChange') as string, {theme: 'colored'});
            return onClose();
        }

        return toast.error(t('customerAdmin.errorChange') as string, {theme: 'colored'});  
    }

    return (
        
        <ModalBase open={open} onClose={onClose} containerClass='w-80 sm:w-3/4 p-2 sm:p-4' >
      
            <AddressInput 
                address={address}
                setAddress={setAddress}
            /> 

            <ButtonFooter
                action='confirm'
                onValid={onSaveAddress}
                onCancel={onClose}
            />

        </ModalBase>
    );
};

export {ChangeAddressModal};