import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { saveClassApi, toFirstUpperCase } from '../../../utils';
import { User } from '../../../../../shared/schema/index';
import { AuthCtx } from '../../_context/Context';
import { ButtonAction, ButtonFooterBase, ContentCenter, InputText } from '../../../components';

interface AddNameProps {
    setStep: (s: number) => void;
};

const AddName = ({setStep}: AddNameProps) => {
    const [auth, setAuth] = useContext(AuthCtx);
    const { t } = useTranslation('admin');
    const [userName, setUserName] = useState<Partial<User>>({
        firstName : auth.me.firstName ? auth.me.firstName : '',
        lastName: auth.me.lastName ? auth.me.lastName : ''
    })
    const [saving, setSaving] = useState<boolean>(false);

    const onSave = async() => {
        setSaving(true);
        if (userName.firstName === '' || userName.lastName === '') {
            toast.error(t('error.accountName') as string, {theme: 'colored'});
            return
        };
        if (userName.firstName === auth.me.firstName && userName.lastName === auth.me.lastName) {
            return setStep(2)
        }

        if (userName.firstName && userName.lastName) {
            const update = await saveClassApi('_User', userName, auth.me.objectId);
            if (typeof update !== 'string') {
                const newMe = auth.me;
                newMe.firstName = userName.firstName;
                newMe.lastName = userName.lastName;
                const newAuth = auth
                newAuth.me = newMe;
                setAuth(newAuth);
                setStep(2)   
                return
            };
        }
        toast.error(t('error.retry') as string, {theme: 'colored'});
        setSaving(false);   
    }

    return (
        <>
            <div className="text-center text-lg mb-8">{t('addName.title')}</div>

            <ContentCenter>

                <InputText id='firstName' label={t('addName.firstName')}
                value={userName.firstName}
                onChange={(event) => setUserName({...userName, firstName: toFirstUpperCase(event.target.value)})}
                placeholder={t('addName.firstName')}
                containerClass='w-80'
                autoComplete="on"
                autoFocus
                />

                <InputText id='firstName'
                label={t('addName.lastName')}
                value={userName.lastName}
                onChange={(event) => setUserName({...userName, lastName: toFirstUpperCase(event.target.value)})}
                placeholder={t('addName.lastName')}
                containerClass='w-80'
                autoComplete="on"
                />

            </ContentCenter>

            <ButtonFooterBase>

                <ButtonAction action='next'
                    className="w-full"
                    onClick={() => saving ? null : onSave()}
                />
                
            </ButtonFooterBase>

        </>

    );
};

export default AddName;