import { useState, useContext } from 'react';
import { MemberLabel } from '../../params';
import { MemberSetting } from './MemberSetting';
import { UseSetting } from './UseSetting';
import { FontSetting } from './FontSetting';
import { ColorSetting } from './ColorSetting';
import { BackgroundSetting } from './BackgroundSetting';
import { saveClassApi } from '../../utils';
import { AuthCtx } from '../_context/Context';
import { SiteSetting, ParseFile, UseSiteSetting } from '../../../../shared/schema/index';
import { siteSettingObject } from '../_object';
import { Theme, View } from '../../components';

const SiteSettingView = () => {
  const [auth, setAuth] = useContext(AuthCtx);
  const [use, setUse] = useState<UseSiteSetting>(auth.siteSetting.use);
  const [memberName, setMemberName] = useState<MemberLabel>(auth.siteSetting.memberName);
  const [otherMemberName, setOtherMemberName] = useState<MemberLabel | undefined>(auth.siteSetting.otherMemberName);
  const [font, setFont] = useState<string>(auth.siteSetting.font);
  const [color, setColor] = useState<string>(auth.siteSetting.color); 

  const saveSetting = async(removeBackground?: boolean, backgroundParse?: ParseFile) => {
    //TODO check Member and Other non vide
    const newSetting: SiteSetting = siteSettingObject();
    newSetting.use = use;
    newSetting.memberName = memberName;
    newSetting.otherMemberName = otherMemberName;
    newSetting.color = color;
    newSetting.font = font;

    const setting = await saveClassApi('Site', {siteSetting: newSetting}, auth.siteId);
    if (typeof setting !== 'string') {
      setAuth({...auth, siteSetting: newSetting});
    } else {
      //TODO
    }
  };

  return (

    <View withFooter={false}>

      <Theme color={color} font={font}/>

      <UseSetting use={use} setUse={setUse} saveSetting={saveSetting} />

      <MemberSetting memberName={memberName} setMemberName={setMemberName} otherMemberName={otherMemberName} setOtherMemberName={setOtherMemberName} saveSetting={saveSetting} />

      <FontSetting font={font} setFont={setFont} saveSetting={saveSetting} />

      <ColorSetting color={color} setColor={setColor} saveSetting={saveSetting} />

      <BackgroundSetting />

    </View>
  );
};

export { SiteSettingView };