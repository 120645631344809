import { useState, useContext, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { ChangePassword, EditAvatar, EditName, EditEmail, EditUserSetting} from '.';
import { getApi, getClassApi, saveClassApi, toFirstUpperCase } from '../../utils';
import { AuthCtx } from '../_context/Context';
import { PiCustomer, NavViewType, User } from '../../../../shared/schema/index';
import { authMeObject, parsePointer } from '../_object';

import { ButtonIcon, Card, ContentRow, CardFooter, View, Title, ContentCol, SubView, Content, ContentWrap, ButtonText, Avatar } from '../../components';
import CloseAccount from './CloseAccount';


export type AccountNav  = 'changePassword' | 'editAvatar' | 'editUserSetting' | 'editName' | 'editMail' | 'closeAccount' | 'showRgpd' | 'default';

const AccountView = () => {
  const [auth, setAuth] = useContext(AuthCtx);
  const { t } = useTranslation(['account', 'nav']);

  const [user, setUser] = useState<Partial<User>>(auth.me);
  const [isUserCustomer, setIsUserCustomer] = useState<boolean>(false);
  const [openAvatarModal, setOpenAvatarModal] = useState(false);

  const [navView, setNavView] = useState<NavViewType>({action: 'default'});

  useEffect(() => {
    async function fetchData() {
      const userCustomer = await getClassApi<PiCustomer>('PiCustomer', {
        where: {user: parsePointer('_User', auth.me.objectId)}
      })
      if (typeof userCustomer !== 'string' && userCustomer.results.length > 0) setIsUserCustomer(true)
    }
    
    if (auth.roleLabel === 'admin') fetchData()
  },[auth])

  const updateUser = async (userUpdate: Partial<User>) => {
    const update = await saveClassApi('_User', userUpdate, auth.me.objectId);
    if (typeof update !== 'string') {
      const getUser = await getApi<User>('/users/me');
      if (typeof getUser !== 'string') {
        setAuth({...auth, me: authMeObject(getUser)})
        setUser(getUser);
        setNavView({action: 'default'});
        return
      }
    }
    toast.error(t('nav.retry') as string, {theme: 'colored'});
  }

  return (
  
    <View withFooter={false}>
   
        <Card>
          
          <ContentRow>
           
              <Content className="relative flex mr-10">

                <ButtonIcon icon='edit' iconClass='w-6 h-6'
                  className="h-9 w-9 absolute top-0 right-0 flex items-center justify-center bg-white text-neutre text-xs border border-neutre rounded-full cursor-pointer hover:bg-success hover:text-white hover:border-success p-1 -mr-4"
                  onClick={() => setOpenAvatarModal(true)}
                />
                <div className='w-20 h-20 bg-primary rounded-full flex justify-center items-center text-secondary text-2xl'>
                  {user.objectId && user.firstName && user.lastName &&
                  <Avatar isUserAvatar={true} userId={user.objectId} firstName={user.firstName} lastName={user.lastName} className="rounded-full w-full h-full"/>
                  }
                </div>

              </Content>
            

            <ContentCol>
              
              <Title text={toFirstUpperCase(user.firstName) + ' ' + toFirstUpperCase(user.lastName)} />
              
              <Title text={user.email} />
              
              <div className="italic">{t(`roleLabel.${auth.roleLabel}`, {ns: 'nav'})}</div>

            </ContentCol>
          </ContentRow>

        </Card>
              
        <EditUserSetting />  

        <SubView showIf={navView.action === 'editName'}>
          <EditName
            user={user}
            updateUser={updateUser}
            onClose={() => setNavView({action: 'default'})}
          />
        </SubView> 

        <SubView showIf={navView.action === 'editMail'}>
          <EditEmail
            user={user}
            setUser={setUser}
            onClose={() => setNavView({action: 'default'})}
          />
        </SubView>

        <SubView showIf={navView.action === 'changePassword'}>
          <ChangePassword onClose={() => setNavView({action: 'default'})}/>
        </SubView>

        <SubView showIf={navView.action === 'closeAccount'}>
          <CloseAccount onClose={() => setNavView({action: 'default'})}/>
        </SubView>

        <EditAvatar
          openAvatarModal={openAvatarModal}
          setOpenAvatarModal={setOpenAvatarModal}
        />  
 
        <Card>
          <ContentWrap>
            <ButtonText text={t('nav.editName')}
              showIf={navView.action !== 'editName'}
              variant='filled-primary'
              onClick={() => setNavView({action: 'editName'})}
            />

            <ButtonText text={t('nav.editEmail')}
              showIf={navView.action !== 'editMail'}
              variant='filled-primary'
              onClick={() => setNavView({action: 'editMail'})}
            />

            <ButtonText text={t('nav.editPassword')}
              showIf={navView.action !== 'changePassword'}
              variant='filled-primary'
              onClick={() => setNavView({action: 'changePassword'})}
            />
            
            <ButtonText text={t('nav.editClose')}
              showIf={navView.action !== 'closeAccount' && !isUserCustomer}
              variant='filled-neutre'
              onClick={() => setNavView({action: 'closeAccount'})}
            />

          </ContentWrap>

        <CardFooter>
          <ButtonText text={t('nav.editRgpd')}
            variant='link-neutre'
            onClick={() => {}}
          />
        </CardFooter>

      </Card>
    </View>
  );
};

export default AccountView;
