import { useState, useRef, useContext, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AuthCtx, HelpCtx, NavCtx } from '../_context/Context';
import { postApi, useClickOutside } from '../../utils'
import { Avatar, ButtonBase, ButtonIcon, ButtonText, getMemberName, Icon, Image } from '../../components';
import { AuthNavView } from './ProtectedView';

type HeaderProps = {
    menu: string[];
    selectRole: (n: AuthNavView) => void;
};

const Header = ({menu, selectRole}: HeaderProps) => {
    const [auth] = useContext(AuthCtx);
    const [nav, setNav] = useContext(NavCtx);
    const {help, setHelp} = useContext(HelpCtx);
    const navigate = useNavigate();
    const { t } = useTranslation('nav');
    const refMenu = useRef(null);
    
    const [showUserMenu, setShowUserMenu] = useState<boolean>(false);
    //const [alertMessage, setAlertMessage] = useState<number>(0); TODO
    
    const closeUserMenu = useCallback(() => setShowUserMenu(false), []);
    useClickOutside(refMenu, closeUserMenu);

    const memberName = getMemberName({t: t, name: auth.siteSetting.memberName, plural: true})

    const onBack = () => {
        if (nav.k[0] === 'u' && nav.b) {
            setNav({k: nav.b?.k ? nav.b?.k : "d0", a: nav.b?.a , s: nav.b?.s, b: nav, e: nav.b?.e});
            navigate(t('path', {context: nav.b?.k})); 
        } else {
            setNav({...nav, k: "d0", a: undefined, s: undefined, b: undefined, e: undefined});
            navigate(t('path', {context: "d0"}));
        }
    };

    const onLogout = async () => {
        await postApi('/logout');
        localStorage.removeItem('t');
        localStorage.removeItem('m'); 
        navigate(t('home.path'));
    };

    return (
        
        <header className="h-16 w-full fixed flex justify-end items-center bg-primary px-4 z-40">

            <div className='w-full flex items-center'>

                <ButtonIcon icon='arrowRight' iconClass={'text-primary h-5 w-5 m-auto'}
                    showIf={nav.k !== "d0" }
                    className='bg-secondary rounded-full w-8 h-8 mr-4'
                    onClick={() => onBack()}
                />

                <div className="text-secondary text-lg font-semibold">{nav.k === "d0" ? auth.siteName : t('title', {context: nav.k, member: memberName})}</div>
          
            </div>

            <div className='flex items-center relative'>

                <ButtonIcon icon='help' iconClass={'text-secondary h-full w-full m-auto'}
                    className='h-9 w-9 mr-2 sm:mr-4'
                    onClick={() => help ? setHelp(undefined) : setHelp({page: 'dashboard'})}     
                />
        
                <ButtonIcon icon='notifications' iconClass={'text-secondary text-xl'}
                    showIf={false}
                    className='mr-2 sm:mr-4'
                    onClick={() => {}}   
                />
                
                <ButtonBase
                    className='relative hover:opacity-70'
                    onClick={() => setShowUserMenu(!showUserMenu)}
                >              
                    <div ref={refMenu} className='bg-secondary rounded-full flex justify-center items-center text-primary w-12 h-12'>
                        <Avatar isUserAvatar={true} firstName={auth.me.firstName} lastName={auth.me.lastName} className="rounded-full w-full h-full" />
                    </div>
                
                    {showUserMenu
                    ? <Icon name='arrowLeft' className='absolute -bottom-[3px] right-0 h-3 w-3 text-right text-secondary '/>
                    : <Icon name='arrowRight' className='absolute -bottom-[3px] right-[0.1rem] h-3 w-3 text-right text-secondary'/>
                    }
                </ButtonBase>

                {showUserMenu &&
                <div ref={refMenu} className={['h-screen w-56 fixed top-16 flex flex-col bg-white bg-opacity-30 backdrop-blur-sm py-2',
                    !showUserMenu ? 'animate-closemenu -right-56' : 'animate-openmenu right-0',
                ].join(' ')}>
                    {menu.filter(m => m[0] === 'u').map(item => 
                    <ButtonBase
                        key={item}
                        onClick={() => {
                            item === "u2" ? selectRole('select') : setNav({...nav, k: item})
                            setShowUserMenu(false)
                        }}
                        buttonClass={[
                            'block text-opacity-60 transition duration-300 cursor-pointer hover:text-opacity-100 pr-2 sm:pr-4',
                            nav.k === item ? 'text-secondary bg-primary text-opacity-70' : 'text-primary',
                        ].join(' ')}
                    >
                        <div className="w-full flex flex-col text-right py-2">
                            <span className="font-semibold hover:text-opacity-70">{t('label', {context: item})}</span>
                        </div>
                    </ButtonBase>
                    )}

                    <ButtonText
                        text={<div className="font-bold text-primary text-opacity-70 hover:text-opacity-100 text-right cursor-pointer pr-2 sm:pr-4">{t('logout')}</div>}
                        onClick={onLogout}
                        className="my-2"
                    />
                        
                </div>
                }
            
            </div>

        </header>
    );
};

export default Header;
