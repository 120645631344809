import * as ReactDOM from 'react-dom';

type ThemeProps = {
    color?: string;
    colorA11y?: string;
    font?: string;
    fontSize?: string;
};
  
const Theme = ({color, colorA11y, font, fontSize = '4rem'}: ThemeProps) => {
    const primaryColor = getRGBColor(color ? color : '', "primary");
    const a11yColor = getRGBColor(colorA11y ? colorA11y : getAccessibleColor(color ? color : ''), "secondary");
    
    return (
        ReactDOM.createPortal(<style>:root
            {`{${primaryColor} ${a11yColor} ${`font-family: ${font}`}`}
        </style>, document.head)
    );
  };
  
export default Theme;

  // Determine the accessible color of text
const getAccessibleColor = (hex: string) => {
    let color = hex.replace(/#/g, "")
    // if shorthand notation is passed in
    if (color.length !== 6) {
      color = `${color}${color}`
    }
    // rgb values
    var r = parseInt(color.substr(0, 2), 16)
    var g = parseInt(color.substr(2, 2), 16)
    var b = parseInt(color.substr(4, 2), 16)
    var yiq = (r * 299 + g * 587 + b * 114) / 1000
    return yiq >= 128 ? "#000000" : "#FFFFFF"
};
  
// Change hex color into RGB
const getRGBColor = (hex: string, type: string) => {
    let color = hex.replace(/#/g, "")
    // if shorthand notation is passed in
    if (color.length !== 6) {
        color = `${color}${color}`
    }
    // rgb values
    var r = parseInt(color.substr(0, 2), 16)
    var g = parseInt(color.substr(2, 2), 16)
    var b = parseInt(color.substr(4, 2), 16)

    return `--color-${type}: ${r}, ${g}, ${b};`
};