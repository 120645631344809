import { useTranslation } from 'react-i18next';
import { Content, ContentCol, InputText, Radio, Text, TextCenter } from '../../../components';
import { Address, PiCustomer} from '../../../../../shared/schema/index';
import { addressObject } from '../../_object';
import { AddressInput } from '../../_shared';


interface AddCustomerProps {
    customer: Partial<PiCustomer>;
    setCustomer: (c: Partial<PiCustomer>) => void;
};

const AddCustomer = ({customer, setCustomer}: AddCustomerProps) => {
    const { t } = useTranslation('admin');
    

    return (
        <Content className='w-full mt-4'>

            <TextCenter text={t('addCustomer.title')} containerClass="text-lg mb-2" />

            <ContentCol>

                <Radio name='type'
                    value={{name:'individual', label: t('addCustomer.individual')}}
                    valueSelected={{name: customer.typeCustomer, label: t('addCustomer.individual') }}
                    setValue={() => setCustomer({...customer, typeCustomer: 'individual'})}
                />

                <Radio name='type'
                    value={{name:'society', label: t('addCustomer.society')}}
                    valueSelected={{name: customer.typeCustomer, label: t('addCustomer.society') }}
                    setValue={() => setCustomer({...customer, typeCustomer: 'society'})}
                />

            </ContentCol>

            <ContentCol showIf={customer.typeCustomer === 'society' }>

                <InputText id='societyName'
                    label={t('customerAdmin.societyName')}
                    value={customer.society?.name}
                    onChange={(e) => {
                        const newSociety = customer.society ? customer.society : {name: ''};
                        newSociety.name = e.target.value
                        setCustomer({...customer, society: newSociety })
                    }}
                    placeholder={t('customerAdmin.societyName')}
                    isRequired={true}
                />

                <InputText id='societyVatNumber'
                    label={t('customerAdmin.societyVatNumber')}
                    value={customer.society?.vatNumber}
                    onChange={(e) => {
                        const newSociety = customer.society ? customer.society : {name: ''};
                        newSociety.name = e.target.value
                        setCustomer({...customer, society: newSociety })
                    }}
                    placeholder={t('customerAdmin.societyVatNumber')}
                />

                <InputText id='societyCompanyIdNumber'
                    label={t('customerAdmin.societyCompanyIdNumber')}
                    value={customer.society?.companyIdNumber}
                    onChange={(e) => {
                        const newSociety = customer.society ? customer.society : {name: ''};
                        newSociety.name = e.target.value
                        setCustomer({...customer, society: newSociety })
                    }}
                    placeholder={t('customerAdmin.societyCompanyIdNumber')}
                />

            </ContentCol>

            <Text text={t('addCustomer.adresseTitle')} />

            <AddressInput
                address={customer.address ? customer.address as Address : addressObject({}) as Address}
                setAddress={(address) => setCustomer({...customer, address: address})}
            />

        </Content>
    );
};

export default AddCustomer;