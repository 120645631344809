import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toFirstUpperCase } from '../../../utils'
import { AuthCtx, HelpCtx } from '../../_context/Context';
import { Gender, Member, MemberRelation, RelationType } from '../../../../../shared/schema/index';
import { ButtonFooter, ButtonIcon, Checkbox, ContentCol, ContentRow, ContentBetween, InputText, SubCard, Text, Toggle, Select, getMemberName, TextImportant, TextBase } from '../../../components';

type MemberRelationEditCardProps = {
    key?: React.Key;
    relation: MemberRelation;
    lastNameParent: string;
    relationTypes: {name: string, label:String}[];
    onSaveRelation: (child: Member, relationTypeParent: RelationType, isUpdateNameEmail: boolean, isUpdateRelation: boolean) => void;
    onCancel: () => void;
}

const MemberRelationEditCard = ({relation, lastNameParent, relationTypes, onSaveRelation, onCancel}: MemberRelationEditCardProps) => { 
    const [auth] = useContext(AuthCtx);
    const {help, setHelp} = useContext(HelpCtx);
    const { t } = useTranslation(['member']);
    const [child, setChild] = useState<Member>(Object.assign(relation.member)) //{...relation.member as Member});
    const [relationType, setRelationType] = useState<RelationType | undefined>(relation.relationType);

    //lastName Parent
    useEffect(() => {
        if (!relationType && lastNameParent && relationType !== 'other' && !child.lastName)
        setChild({...child, lastName: lastNameParent})
    },[relationType, lastNameParent, child]);

    //TODO change name function checkModified -> contrôle button save ()
    const checkValidRelation = (): boolean => {
        if (JSON.stringify(relation.member) === JSON.stringify(child) && relation.relationType === relationType) return false;
        //TODO Error 
        if (child.firstName?.length !== 0 && child.lastName?.length !== 0 && relationType) return true
        
        return false
    };

    const addGender = (gender: Gender) => {
        setChild({...child, gender: child.gender !== gender ? gender : null})
    }

    const detectChangeAttributes = () => {
        //let name = false
        let email = false
        
        //if (relation.member.firstName !== child.firstName || relation.member.lastName !== child.lastName) name = true;
        if (relation.member.email !== child.email || (relation.member.emailing ! == child.emailing && child.emailing)) email = true

        return (email) ? true : false // || name
    }

    const saveRelation = () => {
        if (relationType) {
            const updateNameEmail = detectChangeAttributes()
            const updateRelation = relation.relationType !== relationType ? true : false; 

            onSaveRelation(child, relationType, updateNameEmail, updateRelation);
        }
    }

    return (
        
        <SubCard className='bg-primary bg-opacity-10'>

            <ContentBetween>

                <ContentRow>

                    <Text text={t('relationTitle')}/>

                    <Select
                        options={relationTypes}
                        defaultValue={relationTypes.find(r => r.name === relationType)}
                        onChange={(t) => setRelationType(t.name)}
                        className='font-semibold bg-white ml-1'
                    />

                </ContentRow>

                <ButtonIcon icon='edit'
                    className='h-8 w-8 flex justify-center items-center rounded-full bg-warning text-white mb-2'
                    iconClass='w-5 h-5'
                    onClick={onCancel}
                />

            </ContentBetween>

            <ContentCol showIf={relationType !== undefined} className='mt-4'>

                <ContentRow>
                    <Checkbox label={t('gender_m')} checked={child.gender === 'm'} onChange={e => addGender('m')} />
                    <Checkbox label={t('gender_f')} checked={child.gender === 'f'} onChange={e => addGender('f')} />
                </ContentRow>

                {!child.objectId &&
                <>
                <InputText placeholder={t('firstName')}
                    value={child?.firstName}
                    onChange={(e) => setChild({...child, firstName: toFirstUpperCase(e.target.value)})}
                    autoFocus containerClass='font-semibold bg-white'
                />

                <InputText placeholder={t('lastName')}
                    value={child.lastName}
                    onChange={(e) => setChild({...child, lastName: toFirstUpperCase(e.target.value)})}
                    containerClass='font-semibold bg-white'
                />
                </>
                }
                
                {child.objectId &&
                <TextBase text={child.firstName + ' ' + child.lastName} textClass='font-semibold text-lg ml-4 mb-2' />
                }

                <ContentRow>
                    <Toggle label={t('isMember', {member: getMemberName({t: t, name: auth.siteSetting.memberName})})}
                        checked={child?.isMember ? child.isMember : false}
                        onChange={() => setChild({...child, isMember: !child?.isMember})}
                    />
                    <ButtonIcon icon='help' iconClass={'text-info h-full w-full m-auto'}
                        showIf={child.email !== ''}
                        onClick={() => setHelp({page: 'member', section: 'edit', article: 'isMember'})}
                        className={'h-6 w-6 mb-2 ml-3'}
                    />
                </ContentRow>

                <InputText type='email' placeholder={t('email')}
                    value={child.email}
                    onChange={(e) => setChild({...child, email: e.target.value})}
                />

                <ContentRow>
                <Toggle label={t('emailing')}
                    showIf={child.email !== ''}
                    checked={child.emailing}
                    onChange={() => setChild({...child, emailing: !child.emailing})}
                />
                <ButtonIcon icon='help' iconClass={'text-info h-full w-full m-auto'}
                    showIf={child.email !== ''}
                    onClick={() => setHelp({page: 'member', section: 'edit', article: 'emailing'})}
                    className={'h-6 w-6 mb-2 ml-3'}
                />
                </ContentRow>

                <InputText type='tel' placeholder={t('phone')}
                    value={child.phone ? child.phone : ''}
                    onChange={(e) => setChild({...child, phone: e.target.value})}
                />
         

            </ContentCol>

            <ButtonFooter action='save'
                ifAction={checkValidRelation()}
                onValid={() => saveRelation()}
                onCancel={onCancel}
            />

        </SubCard>

    );
};

export { MemberRelationEditCard };