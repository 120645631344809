type IconProps = {stroke: string}


const UndoIcon = () => {
  return (
    <svg viewBox="0 0 18 18">
      <polygon className="ql-fill ql-stroke" points="6 10 4 12 2 10 6 10"></polygon>
      <path className="ql-stroke" d="M8.09,13.91A4.6,4.6,0,0,0,9,14,5,5,0,1,0,4,9"></path>
    </svg>
  )
}

const RedoIcon = () => {
  return (
    <svg viewBox="0 0 18 18">
      <polygon className="ql-fill ql-stroke" points="12 10 14 12 16 10 12 10"></polygon>
      <path className="ql-stroke" d="M9.91,13.91A4.6,4.6,0,0,1,9,14a5,5,0,1,1,5-5"></path>
    </svg>
  )
}

const SizeIcon = () => {
  return (
    <svg viewBox="0 0 18 18">
      <polyline className="ql-stroke" points="3.5 14 7 4 10.5 14"></polyline>
      <line className="ql-stroke" x1="9.45" x2="4.55" y1="11" y2="11"></line>
      <path className="ql-fill" d="M12.09,7.55l1.7-1.473a0.337,0.337,0,0,1,.429,0l1.7,1.473A0.261,0.261,0,0,1,15.7,8H12.3A0.261,0.261,0,0,1,12.09,7.55Z"></path>
      <path className="ql-fill" d="M12.09,10.45l1.7,1.473a0.337,0.337,0,0,0,.429,0l1.7-1.473A0.261,0.261,0,0,0,15.7,10H12.3A0.261,0.261,0,0,0,12.09,10.45Z"></path>
    </svg>
  )
}

const FontIcon = () => {
  return (
    <svg viewBox="0 0 18 18">
      <polyline className="ql-stroke" points="3.5 14 7 4 10.5 14"></polyline>
      <line className="ql-stroke" x1="9.45" x2="4.55" y1="11" y2="11"></line>
      <path className="ql-fill" d="M13.636,5.013a4.016,4.016,0,0,0-1.863.472,0.42,0.42,0,0,0-.179.629l0.112,0.214a0.418,0.418,0,0,0,.625.191,2.557,2.557,0,0,1,1.183-.326A0.933,0.933,0,0,1,14.573,7.2V7.338H14.339c-1.272,0-3.325.281-3.325,1.954A1.75,1.75,0,0,0,12.9,11.011a2.072,2.072,0,0,0,1.785-1.078h0.022a1.132,1.132,0,0,0-.022.247V10.4a0.412,0.412,0,0,0,.457.472h0.379A0.416,0.416,0,0,0,15.99,10.4V7.293A2.121,2.121,0,0,0,13.636,5.013Zm0.948,3.4a1.452,1.452,0,0,1-1.305,1.505,0.775,0.775,0,0,1-.859-0.753c0-.854,1.216-0.966,1.93-0.966h0.234V8.416Z"></path>
    </svg>
  )
}




export {
  //Inline


  //List Inline
  SizeIcon,
  FontIcon,

  //Block



  //History
  UndoIcon,
  RedoIcon,
}