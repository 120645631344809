import { ParseObject, Nav } from '../../../../shared/schema/index';

const navObject = (data: ParseObject): Nav => {
    const object: Nav = {k: data.k ? data.k : "d0"};
    for (const property in data) {
        switch (property) {
            case 'r':
            case 's':
            case 'a':
            case 'b':
            case 'e':
                object[property] = data[property];
                break;
            default: break;          
        }
    }
    return object;
}

export { navObject }
