import { ParseObject, Auth, AuthMe, Permission,  } from "../../../../shared/schema/index";
import { siteSettingObject } from ".";

const authObject = (data?: ParseObject): Auth => {
    const permission: Permission = data?.permission
    if (permission && data?.siteSetting) {
        permission.eventView = permission.eventView && data.siteSetting.use.eventView;
        permission.presenceView = permission.eventView && data.siteSetting.use.presenceView ;
    }

    return {
        me: data?.me,
        permission: permission,
        roleId: data?.roleId,
        roleLabel : data?.label,
        siteId: data?.siteId,
        siteName: data?.siteName, 
        siteSetting: data?.siteSetting ? data.siteSetting : siteSettingObject()
    };
}

const authMeObject = (data?: ParseObject): AuthMe => {
    return {
        objectId: data?.objectId,
        email: data?.email,
        firstName: data?.firstName,
        lastName: data?.lastName,
        avatar: data?.avatar,
        emailTmp: data?.emailTmp,
        rgpdValided: data?.rgpdValided,
        userSetting: data?.userSetting
    };
}

export { authObject, authMeObject }
