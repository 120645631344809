import { useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import FormPassword from './FormPassword';
import { postFunctionsApi } from '../../utils';
import { UserPassword } from '../../../../shared/schema/index';
import { userPasswordObject } from '../_object';

const ChoosePasswordView = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation(['auth', 'nav']);
  const [user, setUser] = useState<UserPassword>(userPasswordObject());
  const params = new URLSearchParams(location.search);

  const onValid = async () => {
    if (!params.get('username') && params.get('username') !== user.username) return toast.error(t('choosePassword.error.retry') as string, {theme: 'colored'});
    const changePassword: any = await postFunctionsApi('changePassword', {
      username: user.username, 
      token: params.get('token'),
      password: user.password,
    });
    if (typeof changePassword !== 'string' && changePassword.username) {
      toast.success(t('choosePassword.success') as string, {theme: 'colored'});
      navigate('/login', {state: {username: changePassword.username}});
      return
    }
    if (changePassword === 'error password') {
      return toast.error(t('choosePassword.error.password') as string, {theme: 'colored'});
    } 
    toast.error(t('choosePassword.error.retry') as string, {theme: 'colored'});
  }

  return (
    <div className="w-full h-full bg-primary">
      <div className="w-full h-full flex flex-col items-center bg-login-pattern bg-cover bg-no-repeat overflow-auto text-white">
        <div className="w-80 sm:w-96 flex justify-center bg-primary/80 flex-col px-1 my-12">
          <header className="w-80 sm:w-96 mb-10 pr-4 pt-4">
            <h1 className='text-center text-2xl mt-2'>
              {t('siteName')}
            </h1>
            <h2 className='text-center text-xl italic mt-4'>
                {t('choosePassword.title')}
              </h2>
          </header>

          <div className="flex flex-col items-center justify-center mb-4">
            <span className='font-bold text-xl text-center'>{t('choosePassword.description')}</span>
          </div>

          <FormPassword user={user} setUser={setUser} showRgpd={false} onValid={onValid} />

          <div className="mt-6 flex justify-center">
            <Link to={t('home.path', {ns: 'nav'})} className="font-bold text-lg transition duration-300 hover:opacity-80">
              {t('backHome')}
            </Link>
          </div>

        </div>
      </div>
    </div>
  );
};

export default ChoosePasswordView;