import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { postFunctionsApi, toFirstUpperCase } from '../../utils';
import { AuthCtx } from '../_context/Context';
import { User, UserRole } from '../../../../shared/schema/index';
import { ButtonIcon, ButtonFooter, Card, RadioList, ModalConfirm, SubCard, ListSearch, ListMap, Title, Li, ContentCol, LiHeader, LiActions, Text, ContentRow, ButtonAction } from '../../components';

type ListUserProps = {
    showRoles: () => {name: string, label:string}[];
    users: UserRole[];
    setUsers: (u:UserRole[]) => void;
    userCustomerId?: string;
};

const ListUser = ({showRoles, users, setUsers, userCustomerId}: ListUserProps) => {
    const [auth] = useContext(AuthCtx);
    const { t } = useTranslation(['admin', 'nav']);

    const filterTags = [
        { label: t('listUser.all'), value: 'all' },
        { label: t('listUser.firstName'), value: 'firstName' },
        { label: t('listUser.lastName'), value: 'lastName' },
        { label: t('listUser.roleName'), value: 'roleName' },
    ];

    const [userSearch, setUserSearch] = useState<string>('');
    const [filterTagSelected, setFilterTagSelected] = useState(filterTags[0]);
    const [userFilter, setUserFilter] = useState<UserRole[]>([]);

    const [roleSelected, setRoleSelected] = useState<{name: string, label:string} | undefined>();

    const [showUser, setShowUser] = useState<string | undefined>();
    const [showRole, setShowRole] = useState<string | undefined>();
    const [modalUserDelete, setModalUserDelete] = useState<User>();

    useEffect(() => {
        let newList = users;
        if (userSearch !== '') {
            switch (filterTagSelected.value) {
                case 'firstName':
                    newList = users.filter(u => u.firstName?.toLowerCase().includes(userSearch));
                    break;
                case 'lastName':
                    newList = users.filter(u => u.lastName?.toLowerCase().includes(userSearch));
                    break;
                case 'roleName':
                    newList = users.filter(u => u.roleName?.toLowerCase().includes(userSearch));
                    break;
                default:
                    newList = users.filter(u => 
                        u.firstName?.toLowerCase().includes(userSearch) ||
                        u.lastName?.toLowerCase().includes(userSearch) ||
                        u.roleName?.toLowerCase().includes(userSearch))
            }  
        }
        setUserFilter(newList)
    },[users, filterTagSelected, userSearch])

    const onChangeRoleUser = async(user: UserRole) => {
        if (roleSelected && auth.siteId && user.objectId) {
            const getRoles: any = await postFunctionsApi('changeRoleUser', {
                userId: user.objectId,
                siteId: auth.siteId,
                newRoleName: roleSelected.name
            });
            if (typeof getRoles !== 'string') {
                const index = users.findIndex(u => u.objectId === user.objectId);
                const newUsers = users;
                newUsers[index].roleName = roleSelected.name;
                setUsers(newUsers);
                setShowRole(undefined);
                setRoleSelected(undefined);
                return toast.success(t('listUser.success.changeRole') as string, {theme: 'colored'});
            }
        }
        toast.error(t('listUser.error.changeRole') as string, {theme: 'colored'})
    }

    const onResendVerificationEmail = async(user: UserRole) => {
        const resend = await postFunctionsApi<User>('resendInvit', {username: user.username, roleName: user.roleName, siteId: auth.siteId})
        if (typeof resend !== 'string') {
            //TODO send 1 time/Day -> updateAt User
            return toast.success(t('listUser.error.sendVerifEmail') as string, {theme: 'colored'});
        }
        toast.error(t('listUser.error.sendVerifEmail') as string, {theme: 'colored'})
    }

    const onCheckRole = (user: UserRole) => {
        return ((user.objectId === userCustomerId) || (auth.roleLabel !== 'admin' && user.roleName === 'admin')) ? false : true;
    }

    const onRemoveUser = async(userId: string) => {
        const removeUser = await postFunctionsApi('removeRoleUserSite', {userId: userId, siteId: auth.siteId});
        if (typeof removeUser !== 'string' && removeUser === true) {
            const newUsers = users.filter(u => u.objectId !== userId)
            setUsers(newUsers);
            setModalUserDelete(undefined);
            return
        }
        return toast.error(t('listUser.error.removeUser') as string, {theme: 'colored'});
    }

    return (
        <Card>

            <ListSearch
                selected={ filterTags[0]}
                items={filterTags}
                onSelect={(value) => setFilterTagSelected(value)}
                searchValue={userSearch}
                onSearch={(text) => setUserSearch(text)}
            />

            <ListMap<UserRole> items={userFilter} component={(user: UserRole) => (
              
                <Li key={user.objectId}>

                    <LiHeader className='grid-cols-[auto_8rem_5rem]'>

                        <Title text={<span>{user.firstName} {user.lastName}</span>} containerClass={user.emailVerified ? '' : 'text-warning'} />
                            
                        <Text containerClass='truncate' text={t(`roleLabel.${user.roleName}`, {ns: 'nav'})}/>
                                           
                        <LiActions>
            
                            <ButtonIcon icon={showUser === user.objectId ? 'visibilityOff' : 'visibility'}
                                iconClass={'h-6 w-6 text-info'}
                                onClick={() => {
                                    showUser === user.objectId ? setShowUser(undefined) : setShowUser(user.objectId)
                                    setShowRole(undefined)
                                }}
                                className="mr-2"
                            />

                            <ButtonIcon icon='delete' iconClass={'h-6 w-6 text-danger'}
                                showIf={onCheckRole(user)}
                                onClick={() => setModalUserDelete(user)}
                                className="mr-2"
                            />

                        </LiActions>
              
                    </LiHeader>
                     
                    <SubCard showIf={showUser === user.objectId}>

                        <ContentRow>
                            <div className="flex mr-4">
                                <div className="relative">

                                    <div className='w-16 h-16 bg-primary rounded-full flex justify-center items-center text-white'>
                                        {user.avatar &&
                                        <img src={user.avatar.url} id={user.avatar.name} className="rounded-full w-full h-full" alt={user.firstName + " " + user.lastName} />
                                        }
                                        {!user.avatar &&
                                        <span>{(user.firstName ? user.firstName[0].toUpperCase() : '') + (user.lastName ? user.lastName[0].toUpperCase() : '')}</span>
                                        }
                                    </div>

                                </div>
                            </div>

                            <ContentCol>

                                <Text containerClass='font-medium py-0' text={toFirstUpperCase(user.firstName) + ' ' + toFirstUpperCase(user.lastName)}/>
                                
                                <Text containerClass='font-medium py-0' text={user.username}/>
                                
                                <Text containerClass='italic py-0'
                                    text={user.emailVerified ? <span className='text-success'>{t('listUser.register')}</span> : <span className='text-warning'>{t('listUser.noRegister')}</span>}
                                />

                            </ContentCol>

                        </ContentRow>
                     
                        <ButtonIcon icon='send' iconClass={'h-6 w-6 text-info'}
                            showIf={!user.emailVerified}
                            iconLeft={true}
                            onClick={() => onResendVerificationEmail(user)} //1 per day with updateAt User    
                            className="mr-2"
                        >
                            <Text color='info' text={t('listUser.sendEmail')}/>
                        </ButtonIcon>
                    
                        <ContentRow>

                            <Text text={<span>{t(`roleLabel.${user.roleName}`, {ns: 'nav'})} {user.objectId === userCustomerId && <span>({t('listUser.customerRegister')})</span>}</span> }/>
                            
                            <ButtonAction action='modify'
                                showIf={!showRole && user.objectId !== userCustomerId && onCheckRole(user)}
                                className='ml-2'
                                onClick={() => {
                                    setShowRole(user.objectId)
                                    setRoleSelected(showRoles().find(r => r.name === user.roleName))
                                }}
                            /> 

                        </ContentRow>
                              
                        <ContentCol showIf={showRole === user.objectId} >

                            <RadioList name='role'
                                items={showRoles()}
                                valueSelected={roleSelected}
                                setValue={setRoleSelected} 
                            />

                            <ButtonFooter
                                action='save'
                                onValid={() => onChangeRoleUser(user)}
                                onCancel={() => setShowRole(undefined)}
                            />

                        </ContentCol>

                    </SubCard>

                </Li>
                
            )}/>
            
            <ModalConfirm
                data={modalUserDelete}
                onValid={u => onRemoveUser(u.objectId)}
                onClose={() => setModalUserDelete(undefined)}  
            />
                    
        </Card>

    );
};

export { ListUser };