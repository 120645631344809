import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ContactPi, ContactPiReason, Reason } from '../../../../shared/schema/index';
import { ButtonFooter, Card, Content, ContentCenter, ContentCol, TextEditorSimply, RadioList, InputText, Rating, Text, TextImportant, Title, Description } from '../../components';
import { parsePointer } from '../_object';

const reasonList: Reason[] = [{name: "survey"}, {name: "bug"}, {name: "suggestion"}, {name: "other"}]
type ContactPiEditProps = {
    saveRequest: (r: ContactPi) => Promise<boolean>
}

const ContactPiEdit = ({saveRequest}: ContactPiEditProps) => {
    const { t } = useTranslation(['contactpi', 'button']);
    const [reason, setReason] = useState<{name: ContactPiReason, label: string} | undefined>();
    const [subject, setSubject] = useState<string>('');
    const [note, setNote] = useState<number>(0);
    const [message, setMessage] = useState<string>('');

    const save = async() => {
        if (reason) {
            const object: ContactPi = {
                reason: reason.name,
                subject: subject,
                note: note === 0 ? undefined : note,
                message: message !== '' ? message : undefined,
                site: parsePointer('Site', JSON.parse(localStorage.getItem('s') as string)),
                show: true,
                responded: false
            }

            const save = await saveRequest(object)
            if (save) {
                setSubject('');
                setMessage('');
                setNote(0);
                setReason(undefined);
            }

        }
    }

    const onChangeMessage = (content: any, delta: any, source: any, editor: any) => {
        setMessage(editor.getContents());
    }

    return (

        <Card>

            <Title text={t('titlereason')}/>
        
            <RadioList items={reasonList.map(r => {return {name: r.name, label: t(r.name)}})}
                name='reason'
                valueSelected={reason}
                setValue={setReason}
                col={true}
            />
            
            <ContentCol showIf={reason !== undefined}>

                <ContentCenter showIf={reason?.name === 'survey'}>
                    <TextImportant text={t('yourevaluation')}/>
                    <Rating rating={note} setRating={setNote} />
                </ContentCenter>

                <InputText value={subject}
                    showIf={(reason?.name === 'bug' || reason?.name === 'other')}
                    label={reason?.name === 'bug' ? t('subjectbug') : t('subjectother')}
                    onChange={(event: any) => setSubject(event.target.value)}
                    id='subject'
                    maxLength={50}
                    autoFocus
                    labelPosition='top'
                />
                    
                <Text text= {(t('message'))}/>

                <Content className='w-full h-48 min-h-max border border-neutre'>
                    <TextEditorSimply className='h-48' value={message} onChange={onChangeMessage} placeholder={(t('messageplaceholder'))} /> 
                </Content>
            

                <Description containerClass='italic' text={(t('required', {ns: 'button'}))}/>

            </ContentCol>
                
            <ButtonFooter showIf={reason !== undefined}
                action='send'
                ifAction={(reason?.name === 'survey' && note === 0 ? false : true) && ((reason?.name === 'bug' || reason?.name === 'other') && subject === '' ? false : true)}
                onValid={() => save()}
                onCancel={() => {
                    setReason(undefined)
                    setNote(0)
                    setMessage('')
                }}
            />

        </Card>
    );
};

export { ContactPiEdit };
