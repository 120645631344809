import { ParseBaseAttributes, PiPaymentMethod } from '../../../shared/schema/index';

type ParseAttribute = 'createdAt' | 'updatedAt' | 'className' | 'ACL'
const delParseAttribute = <T extends Partial<ParseBaseAttributes>>(object: T, keep?: ParseAttribute[] | ParseAttribute) => {
    
    if (!keep || typeof keep === 'string') {
        if (keep !== 'createdAt') delete object.createdAt
        if (keep !== 'updatedAt') delete object.updatedAt
        if (keep !== 'className') delete object.className
        if (keep !== 'ACL') delete object.ACL
    } else if (keep) {
        if (keep.find(k => k !== 'createdAt')) delete object.createdAt
        if (keep.find(k => k !== 'updatedAt')) delete object.updatedAt
        if (keep.find(k => k !== 'className')) delete object.className
        if (keep.find(k => k !== 'ACL')) delete object.ACL
    }

    return object
}


const toFirstUpperCase = (string: string | undefined) => {
    return string ? string.charAt(0).toUpperCase() + string.slice(1) : '';
};

const toFirstLowerCase = (string: string) =>  {
    return string ? string.charAt(0).toLowerCase() + string.slice(1) : '';
};

const checkPhone = (phone: string) => {
    const p = phone.replace('.', ' ');
    return p
}

const sortNameArray = (x: any, y: any) => {
    if (x.name < y.name) {return -1;}
    if (x.name > y.name) {return 1;}
    return 0;
}

const cardExp = (pm?: PiPaymentMethod): 'ok' | 'expiring' | 'expired' | 'noCard' => {
    const today = new Date();
    const year = today.getFullYear();
    const month = today.getMonth() + 1
    console.log(year)
    console.log(month)
    if (pm && pm.exp_year && pm.exp_month) {
        console.log(pm.exp_year)
        console.log(pm.exp_month)
        if (pm.exp_year === year && pm.exp_month === month
        ) return 'expiring'

        if ((pm.exp_year < year ||
            (pm.exp_year === year && pm.exp_month < month))
        ) return 'expired'

        return 'ok'
    }

    return 'noCard'
}

const b64toBlob = (b64Data: string, sliceSize=512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];
  
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);
  
        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }
  
        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
    }
  
    const blob = new Blob(byteArrays, {type: 'image/png'});

    return blob;
}

const blobToBase64 = (blob: File | Blob): Promise<string> => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    return new Promise(resolve => {
        reader.onloadend = () => {
            resolve(reader.result as string);
        };
    });
};


export {delParseAttribute, toFirstUpperCase, toFirstLowerCase, cardExp, checkPhone, sortNameArray, b64toBlob, blobToBase64}
