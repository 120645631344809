import { SetupIntent } from '@stripe/stripe-js';
import { useTranslation } from 'react-i18next';
import { postFunctionsApi } from '../../utils';
import { PiCustomer, PiPaymentMethod } from '../../../../shared/schema/index';
import { StripeModal } from '../../components';
import { toast } from 'react-toastify';


type ChangePaymentMethodProps = {
    piCustomer: PiCustomer;
    open: boolean;
    onClose: () => void;
};

const ChangePaymentMethod = ({piCustomer, open, onClose}: ChangePaymentMethodProps) => {
    const { t } = useTranslation('admin');

    const changePaymentMethod = async(si: SetupIntent) => {
        const stripePaymentMethod = await postFunctionsApi<PiPaymentMethod>('changePaymentMethod', {
            piCustomerId: piCustomer.objectId,
            stripeSI: si
        })
        if (typeof stripePaymentMethod !== 'string') {
            piCustomer.paymentMethod = stripePaymentMethod;
            toast.success(t('customerAdmin.successChange') as string, {theme: 'colored'});
            return onClose();
        }
    };

    return (
        
        <StripeModal
            open={open}
            onConfirm={(setup) => changePaymentMethod(setup)}
            onClose={onClose}
        />
    );
};

export {ChangePaymentMethod};