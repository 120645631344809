import { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { MemberLabel } from '../../params';
import { AuthCtx } from '../_context/Context';
import { ButtonFooter, Card, CardTitle, ContentCol, getMemberName, ChooseMemberName, ButtonAction } from '../../components';

type MemberSettingProps = {
  memberName: MemberLabel;
  setMemberName: (m: MemberLabel) => void;
  otherMemberName: MemberLabel | undefined;
  setOtherMemberName: (m: MemberLabel | undefined) => void;
  saveSetting: () => void;
  open?: boolean;
}

const MemberSetting = ({memberName, setMemberName, otherMemberName, setOtherMemberName, saveSetting, open}:MemberSettingProps) => {
  const [auth] = useContext(AuthCtx);
  const { t } = useTranslation(['siteSetting', 'error', 'nav']);
  const [openMember, setOpenMember] = useState<boolean>(open ? open : false);
  const [showOther, setShowOther] = useState<boolean>(false);

  const onChangeName = () => {
    saveSetting();
    setOpenMember(false)
  }

  return (

      <Card>

        <CardTitle
          title={t('member')}
          text={getMemberName({t: t, name: auth.siteSetting.memberName ,plural: true})}
        >
          <ButtonAction action='modify' showIf={!openMember} className='ml-2' onClick={() => setOpenMember(true)} /> 
        </CardTitle>

        <ContentCol showIf={openMember}>

          <ChooseMemberName
            memberName={memberName}
            setMemberName={setMemberName}
            otherMemberName={otherMemberName}
            setOtherMemberName={setOtherMemberName}
            showOther={showOther}
            setShowOther={setShowOther}
            saveSetting={saveSetting}
          />
    
          <ButtonFooter showIf={!showOther}
            action='apply'
            ifAction={(memberName.plural !== auth.siteSetting.memberName.plural)}
            onValid={() => onChangeName()}
            onCancel={() => {
              setMemberName(auth.siteSetting.memberName)
              if (JSON.stringify(auth.siteSetting.otherMemberName) !== JSON.stringify(otherMemberName)) saveSetting();
              setOpenMember(false)
            }}
          
          />
          
        </ContentCol>
    
      </Card>

    
  );
};

export { MemberSetting };