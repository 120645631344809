import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { deleteClassApi, getClassApi } from '../../../utils';
import { Place } from '../../../../../shared/schema';
import { ButtonIcon, Card, CardTitle, ContentRow, InputSearch, LeafletMap, Suspense, TextBase } from '../../../components';
import { PlaceEditCard } from './PlaceEditCard';
import { PlacePostCard } from './PlacePostCard';

const PlaceList = () => {
    const { t } = useTranslation(['datasite']);

    const [placeSearch, setPlaceSearch] = useState<string>('');
    const [placeFilter, setPlaceFilter] = useState<Place[]>([]);
    const [refreshList, setRefreshList] = useState<boolean>(false);
    const [nbPlace, setNbPlace] = useState<number>(0)
    const [editPlace, setEditPlace] = useState<Place>();
    
    const [showAddPlace, setShowAddPlace] = useState<boolean>(false);
    const [showPlace, setShowPlace] = useState<boolean>(false);
    const [showEditPlace, setShowEditPlace] = useState<boolean>(false)

    const [saving, setSaving] = useState<boolean>(true);

    useEffect(() => {
        async function fetchData() {
            let queryWhere: any = {};
            if (placeSearch.length > 0) queryWhere.name = {"$regex": placeSearch, $options: "i"}

            const getPlaces = await getClassApi<Place>('Place', {
                "where" : JSON.stringify(queryWhere),
                "count": 1,
                "limit": 1000,
                "include" : ['address']
            });
            
            if (typeof getPlaces !== 'string') {
                setPlaceFilter(getPlaces.results);
                setNbPlace(getPlaces.count)
                setRefreshList(false); 
            }

            setSaving(false);        
        }
        fetchData();
    },[placeSearch, refreshList, setSaving]);

    const onShowPlace = (p: Place) => {
        setEditPlace(p)
        setShowPlace(true)
    }

    const onSavePlace = (place: Place)  => {
        setRefreshList(true); 
        setShowAddPlace(false)
        setShowEditPlace(false)
        if (showPlace) setEditPlace(place)
    }

    const onDelete = async() => {
        if (editPlace && editPlace.objectId) {
            await deleteClassApi('Place', editPlace.objectId)
            setRefreshList(true); 
        }
        
        setShowPlace(false)
        setShowEditPlace(false)
    }


    return (

        <Card>

            <CardTitle title={showAddPlace ? t('place.addTitle') : t('place.listTitle')} />

            {!showAddPlace && !showEditPlace &&
            <>
            <InputSearch
                searchValue={placeSearch}
                onSearch={(text) => setPlaceSearch(text)}
            />
            <TextBase text={t('place.nbPlace') + nbPlace} />
            </>
            }

            {!showEditPlace &&
            <ContentRow className='justify-end -mt-4'>

                <ButtonIcon icon={showAddPlace ? 'close' : 'more'} iconClass='w-7 h-7' className='mb-2'
                    onClick={() => setShowAddPlace(!showAddPlace)}
                >
                    <TextBase textClass='ml-2' text={t('place.add')}/>
                </ButtonIcon>

            </ContentRow>
            }

            {showAddPlace &&
            <PlaceEditCard
                onSave={onSavePlace}
                onCancel={() => setShowAddPlace(false)}
            />
            }

            {showPlace && editPlace &&
            <PlacePostCard
                place={editPlace}
                onEdit={() => setShowEditPlace(!showEditPlace)}
                onDelete={onDelete}
                onCancel={() => {
                    setShowPlace(false)
                    setShowEditPlace(false)
                    setEditPlace(undefined)
                }}
            />
            }

            {showEditPlace &&
            <PlaceEditCard
                place={editPlace}
                onSave={onSavePlace}
                onCancel={() => {
                    setShowEditPlace(false)
                }}
            />
            }

            {placeFilter.length > 0 && !showPlace && !showAddPlace && !showEditPlace &&
            <TextBase text={t('place.clickShowPlace')} textClass='italic' />
            }

            {placeFilter.length > 0 && !showAddPlace && !showEditPlace &&
            <LeafletMap
                places={placeFilter}
                zoom={1}
                className='h-[410px] w-full relative rounded-xl'
                showPlace={onShowPlace}
            />
            }

            {placeFilter.length === 0 && !showAddPlace && !showEditPlace &&
            <TextBase containerClass='flex justify-center items-center text-neutre font-semibold text-lg my-6' text={t('place.noResult')} />
            }

            <Suspense showIf={saving} />
                
        </Card>
    );
};

export { PlaceList };