import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Elements, PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { loadStripe, SetupIntent } from '@stripe/stripe-js';
import { toast } from 'react-toastify';
import { STRIPE_KEY } from '../config';
import { PiProduct } from '../../../shared/schema/index';
import { ButtonIcon, ButtonFooter, Title, ButtonText, ButtonAction, ContentBetween, Suspense, ButtonFooterBase} from '.';
import { postFunctionsApi } from '../utils';
import { createRoot } from 'react-dom/client';

type ModalBaseProps = {
  children?: React.ReactNode | string;
  open: boolean;
  onClose: () => void;
  containerClass?: string;
};

const ModalBase = ({children, open, onClose, containerClass}: ModalBaseProps) => {
  if (!open) return null;

  const handleOnBackDropClick = (e: any) => {
    if (e.target.id === "backdrop") onClose && onClose();
  };
  
  return (
    <div
      id="backdrop"
      onClick={handleOnBackDropClick}
      className='fixed inset-0 flex items-center justify-center bg-black bg-opacity-30 backdrop-blur-sm overflow-auto z-40'
    >
      <div className={["bg-white rounded-xl text-neutre", containerClass].join(' ')}>
      
        {children}
      
      </div>
      
    </div>
  );
};

type TypeEnum = 'primary' | 'error' | 'warning' | 'info';
type ModalHeaderProps = {
  title: React.ReactNode | string;
  variant?: TypeEnum;
  onClose: () => void;
  headerClass?: string;
};

const ModalHeader = ({variant='primary', title, onClose, headerClass}: ModalHeaderProps) => {

  const modalHeaderStyle = () => {
    switch (variant) {
      case 'error':
        return 'bg-error text-white';
      case 'warning':
        return 'bg-warning text-white';
      case 'info':
        return 'bg-info text-white';
      default:
        return 'bg-primary text-secondary';
    }
  };
  
  return (
    <div
      className={[
        'flex items-center justify-between text-white rounded-t-xl p-2 sm:p-4', headerClass, modalHeaderStyle()
      ].join(' ')}
    >
      <Title title={title} />

      <ButtonIcon icon='close' iconClass='h-8 w-8 text-white text-2xl' onClick={() => onClose()} />
    
    </div>
  );
};


interface ModalProps extends ModalBaseProps {
  variant?: TypeEnum;
  title: string;
  titleButtonValid?: string | undefined;
  titleButtonValid2?: string | undefined;
  titleButtonCancel?: string | undefined;
  footer?: boolean;
  onValid?: (v?: any) => void;
  onValid2?: (v?: any) => void;
};

const Modal = ({ variant = 'primary', title, titleButtonValid, titleButtonValid2, children, open, footer=true, onValid, onValid2, onClose }: ModalProps) => {
  const { t } = useTranslation(['account', 'button']);

  return (
    
    <ModalBase open={open} onClose={onClose} containerClass='w-80 sm:w-3/4' >

      <ModalHeader title={title} onClose={onClose} variant={variant}  />

        <div className="px-4 py-4">
            
          {children}

          <ButtonFooterBase showIf={footer}>

            <div className='flex items-center'>

              <ButtonText text={titleButtonValid ? titleButtonValid : t('confirm', {ns :'button'})}
                showIf={titleButtonValid !== ''}
                variant="filled-neutre"
                onClick={onValid}
              />

              <ButtonText text={titleButtonValid2 ? titleButtonValid2 : t('confirm', {ns :'button'})}
                showIf={titleButtonValid2 !== '' && onValid2 !== undefined}
                variant='filled-neutre'
                onClick={onValid2}
                className='ml-4'
              />
                
            </div>
            
            <ButtonAction action='cancel' onClick={() => onClose()} className='mb-4' />
            
          </ButtonFooterBase>

        </div>

    </ModalBase>
  );
};



interface ModalDeleteProps {
  title?: string;
  data?: any;
  onValid: (d: any) => void;
  open?: boolean;
  onClose: () => void;
};

const ModalConfirm = ({ title, data, open, onValid, onClose }: ModalDeleteProps) => {
  const { t } = useTranslation('button');
  const [openModal, setOpenModal] = useState<boolean>(false)
  
  useEffect(() => {
    setOpenModal(data ? true : false) 
  }, [data])

  return (

    <ModalBase open={openModal || open === true} onClose={onClose} containerClass='w-80 sm:w-96' >
      
      <ModalHeader title={title ? title :t('modaldeletetitle')} onClose={onClose} variant='warning' />

      <ButtonFooter
        action='confirm'
        onValid={() => onValid(data)}
        onCancel={onClose} 
        className='px-2 sm:px-4 pb-4'
      />
       
    </ModalBase>
  );
};



interface StripeModalProps extends ModalBaseProps {
  product?: PiProduct;
  quantity?: number;
  onConfirm: (c: SetupIntent) => void,
}

const stripePromise = loadStripe(STRIPE_KEY);

const StripeModal = ({product, quantity=1, onConfirm, open, onClose}: StripeModalProps) => {
  const { t } = useTranslation(['admin', 'nav']);
  const [options, setOptions] = useState<any>(); //StripeElementsOptions

  useEffect(() => {
    async function fetchData() {
      const siStripe = await postFunctionsApi<{siSecret: string}>('createStripeSI');
      if (typeof siStripe !== 'string') return setOptions({ clientSecret: siStripe.siSecret, appearance: {}, loader: "never"})
      //TODO error
    }
    if (open) fetchData();
  }, [open]);

  const onCancel = async() => {
    setOptions(undefined);
    await postFunctionsApi<boolean>('cancelStripeSI', {stripeSIid: options?.clientSecret});
    onClose();
  };

  const setSI = (si: SetupIntent) => {
    setOptions(undefined);
    onConfirm(si)
  }

  return (
    <>
      {open && !options && <Suspense />}

      {open && options &&
      <ModalBase open={open} onClose={onCancel} containerClass=' w-80 sm:w-3/4 p-2 sm:p-4' >

        <ContentBetween className='border-b border-neutre pb-2 sm:pb-4'>

          <div className='font-bold text-success'>
            {product &&  <span>{product.label} {product.price.amount * quantity}</span>}
            {!product &&  <span>{t('adminCutomer.modalChangePaymentMetodTilte')}</span>}
          </div>
          
          <ButtonIcon icon='close' onClick={() => onClose()} iconClass='h-8 w-8 text-neutre' />

        </ContentBetween>

        <Elements stripe={stripePromise} options={options}>

          <CheckoutForm setSI={setSI} />
        
        </Elements>
            
      </ModalBase>
      }
    </>
  );
};

type CheckoutFormProps = {setSI: (si: SetupIntent) => void }

const CheckoutForm = ({setSI}: CheckoutFormProps) => {
  const { t } = useTranslation(['admin', 'nav']);
  const stripe = useStripe();
  const elements = useElements();
  const [isLoading, setIsLoading] = useState(false);

  const confirmSetup = async(event: any) => { event.preventDefault();

    if (!stripe || !elements) return toast.error(t('stripe.error.connexion') as string, {theme: 'colored'});
    setIsLoading(true);

    const confirm = await stripe.confirmSetup({
      elements: elements,
      redirect: 'if_required',
    });
    
    if (confirm.error?.type === "card_error" || confirm.error?.type === "validation_error") {
      toast.error(confirm.error.message as string, {theme: 'colored'});
    } else if (confirm.error) {
      toast.error(t('stripe.error.retry') as string, {theme: 'colored'});
    } else if (confirm.setupIntent) {
      setSI(confirm.setupIntent);
    } else {
      //TODO error
    }
  
    setIsLoading(false);
  }

  return (
    
    <form id='stripe' onSubmit={confirmSetup} className='flex flex-col justify-center mt-2 sm:mt-4' >

      <PaymentElement id="payment-element" />

      <ButtonAction action='valid'
        showIf={isLoading === false ||  stripe !== null || !elements !== null}
        form='stripe'
        type='submit'
        className='w-11/12 mt-4 mb-2 mx-auto'
      />

    </form>
  );
};

type ChildProps<T> = {
  onValid: (v: T | 'cancel') => void
  onClose: () => void,
}

interface AsyncModalProps<T> {
  domId: string;
  title: string
  child: ({onValid, onClose, ...props}: ChildProps<T>) => JSX.Element
};

const asyncModal = <T,>(domId: string, child: (p: ChildProps<T>) => JSX.Element) => {    
  return new Promise<T | 'cancel'>(resolve => {
    const container = document.getElementById(domId);
    const root = createRoot(container!);
    const unmout = () => root.unmount()

    const valid = (value: T | 'cancel') => {
      resolve(value)
      unmout()
    }

    const close = () => { 
      resolve('cancel')
      unmout()
    }

    return root.render(
      child({onValid: valid, onClose: close})
    );
  }) 
}

interface AModalProps<T> {
  child: JSX.Element
  title: string;
  onValid: (v: T | 'cancel') => void
  onClose: () => void;
};

const AModal = <T,>({child, title, onValid, onClose}: AModalProps<T>) => {    
  
  const [value, setValue] = useState<T |'cancel'>('cancel')

  const valid = () => {
    onValid(value)
  }

    return (
      <Modal
        open={true}
        title={title}
        onValid={valid}
        onClose={onClose}
      >    
        {child}
      </Modal>
    ); 
}

export { ModalBase, ModalHeader, Modal, ModalConfirm, StripeModal, asyncModal, AModal};
