import { useTranslation } from "react-i18next";
import { FontIcon, RedoIcon, SizeIcon, UndoIcon } from "./Icons";
import { textColors, bgColors, fonts } from "./Register";
import { linkHandler } from "./QLink";
import { imageHandler } from "./QImage";

const modules = {
  toolbar: {
    container: "#toolbar",
    handlers: {
      image() { imageHandler((this as any).quill)},
      link() { linkHandler((this as any).quill) },
      redo() { (this as any).quill.history.redo()},
      undo() { (this as any).quill.history.undo()}
    }
  },
  history: {
    delay: 1000,
    maxStack: 200,
    userOnly: true
  }
};

// Formats objects for setting up the Quill editor
const formats = [
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "align",
  "strike",
  "script",
  "blockquote",
  "background",
  "color",
  "link",
  "image"
];

const QuillToolbar = () => {
  const { t } = useTranslation(['textEditor']);
  const blockClass = 'h-8 w-fit flex flex-nowrap items-center mr-2'

  return(
    <div id="toolbar" className="h-fit w-full flex flex-wrap items-center border-t border-x rounded-t-lg px-2">

      <div className={blockClass}>
        <div className="h-5 w-5 ml-1"><FontIcon /></div>
        <select className="ql-font" defaultValue="default">
          <option value="default">{t('font.default')}</option>
          {fonts.map((f, k) =>
            <option key={k} value={f.value}>{f.label}</option> 
          )}
        </select>
        <div className="h-5 w-5 ml-1"><SizeIcon /></div>
        <select className="ql-size" defaultValue="medium">
          <option value="extrasmall">{t('size.extraSmall')}</option>
          <option value="small">{t('size.small')}</option>
          <option value="medium">{t('size.medium')}</option>
          <option value="large">{t('size.large')}</option>
          <option value="extralarge">{t('size.extraLarge')}</option>
        </select>

      </div>

      <div className={blockClass}>
        <button className="ql-bold" />
        <button className="ql-italic" />
        <button className="ql-underline" />
        <button className="ql-strike" />
      </div>

      <div className={blockClass}>
        <select className="ql-align" />
      </div>

      <div className={blockClass}>
        <button className="ql-blockquote" />
        <button className="ql-list" value="ordered" />
        <button className="ql-list" value="bullet" />
      </div>

      <div className={blockClass}>
        <button className="ql-script" value="super" />
        <button className="ql-script" value="sub" />
      </div>

      <div className={blockClass}>
        
        <select className="ql-color" >
        {textColors.map((f, k) =>
          <option key={k} value={f.color as string} /> 
        )}
        </select>

        <select className="ql-background" >
        {bgColors.map((f, k) =>
          <option key={k} value={f.backgroundColor as string} /> 
        )}
        </select>
      </div>

      <div className={blockClass}>
        <button className="ql-link" />
      </div>

      <div className={blockClass}>
        <button className="ql-image" />
      </div>

      <div className={blockClass}>
        <button className="ql-undo"><UndoIcon/></button>
        <button className="ql-redo"><RedoIcon/></button>
      </div>

      <div className={blockClass}>
        <button onClick={() => {}}>Insert</button>
      </div>

      <div className='ql-tooltip' style={{ display: 'none', position: 'absolute', zIndex: 10 }}>
        coucou
      </div>

    </div>
  )
};

export { QuillToolbar, modules, formats }