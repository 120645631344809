import Quill from "quill";
import { Suspense, useRef, useState } from "react";
import { createRoot } from "react-dom/client";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import Compressor from 'compressorjs';
import { b64toBlob, blobToBase64, postFunctionsApi } from "../utils";
import { ButtonIcon, InputText, Modal } from "../components";
import { EditImage } from "../components/Image";


let BlockEmbed = Quill.import('blots/block/embed');

class ImageBlot extends BlockEmbed {
  static create(value: any) {
    console.log(value)
    const img: HTMLElement = super.create();
    img.setAttribute('src', value.src);
    img.setAttribute('alt', value.alt);
    img.setAttribute('filename', value.filename);
    img.classList.add('img') 
    img.addEventListener('error', async() => {
        const data  = await postFunctionsApi<string>('getImage', {
            siteId: JSON.parse(localStorage.getItem('s') as string),
            filename: img.getAttribute('filename')
        })
        if (data !== 'error') {
            const blob = b64toBlob(data);
            const blobUrl = URL.createObjectURL(blob);
            img.setAttribute('src', blobUrl)
        }
    });
    img.addEventListener('click', () => {
        img.setAttribute('start', Quill.find(node).offset())
    })
    const text = document.createElement('div');
    text.classList.add('text-img')
    console.log(text)

    const node = document.createElement('div')
    node.classList.add('block-img')
    node.appendChild(img)
    node.appendChild(text)

    return node;
  }

  static value(node: HTMLElement) {
    console.log('Qimage value node')
    const img: HTMLImageElement = node.getElementsByTagName("img")[0];
    return {
      alt: img.getAttribute('alt'),
      src: img.getAttribute('src'),
      filename: img.getAttribute('filename'),
      start: img.getAttribute('start')
    };
  }
}
ImageBlot.blotName = 'image';
ImageBlot.tagName = 'img';

Quill.register(ImageBlot);


const imageHandler = async(quill: Quill) => {
    const range = quill.getSelection();
    const input = document.createElement('input');

    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.click();

    input.onchange = async() => {
        console.log('img')
        
        if (input.files) {
            const file = input.files[0];
            console.log(file)
            let image: {name: string, file: string | undefined} = await new Promise(resolve => {
                new Compressor(file, {
                    quality: 0.8,
                    height: 500,
                    success: (compressedResult) => {
                        let reader = new FileReader()
                        reader.onload = () => resolve({name: file.name.replace(/\..+$/, '') ,file: reader.result?.toString()});
                        reader.readAsDataURL(compressedResult);
                    },
                }
            )});

            if (image && image.file) {
                const res = await imageEdit(image.file, image.name)
                console.log(res)
                
                if (res !== 'cancel' && range) {
                    quill.insertEmbed(range.index, 'image', {src: res.src, alt: res.name, filename: res.filename}, 'user');
                    quill.setSelection(range.index + 1, 0);
                }
                
            }
        }
    }
}


const imageEdit = (image: string, fileName: string) => {
    return new Promise<{name: string, src: string, filename: string}  | 'cancel'>(resolve => {
      const container = document.getElementById('modal-editor');
      const root = createRoot(container!);
  
      const valid = (value: {name: string, src: string, filename: string} | 'cancel') => {
          resolve(value)
          root.unmount();
      }
    
      const close = () => { 
          resolve('cancel')
          root.unmount();
      }
  
      root.render(
        <Suspense>
          <ImageModal image={image} fileName={fileName} onValid={valid} onClose={close} />
        </Suspense>
      );
    })
}

type ImageModalProps = {
    image: string,
    fileName: string
    onValid: ({name, src, filename}: {name: string, src: string, filename: string}) => void,
    onClose: () => void
};

type RefImageCrop = {
    onSave: () => Promise<{blob: Blob, ratio: number} | undefined>
}

const ImageModal = ({image, fileName, onValid, onClose }: ImageModalProps) => {
    const { t } = useTranslation(['textEditor']);
    const [name, setName] = useState<string>(fileName)
    const imageCrop = useRef<RefImageCrop>(null);

    const [saving, setSaving] = useState<boolean>(false);

    const onImageCrop = async() => {
        setSaving(true)
        const img = await imageCrop.current?.onSave()
        if (img) {
            const height = 250;
            const width = Math.round(height*img.ratio)
            new Compressor(img.blob, {
                quality: 0.8,
                height: height,
                width: width,
                success: async (compressedResult) => {
                    return save(compressedResult, height, width)
                }
            });
        }
    }

    const save = async(file: File | Blob, h: number, w: number) => {
        if (file) {
            console.log(file)
            let data = await blobToBase64(file)
            data = data.replace('data:image/png;base64,', '')
            const siteId = JSON.parse(localStorage.getItem('s') as string)
            const metaData = {
                alt: name,
                h: h,
                w: w,
                tags: ['article']
            }
            const fileSaved = await postFunctionsApi<{filename: string}>('saveImage', {siteId: siteId, data: data, meta: metaData});
            console.log(fileSaved)
            if (typeof fileSaved !== 'string') {
                const src = URL.createObjectURL(file);
                console.log(src)
                setSaving(false);
                return onValid({name: name, filename: fileSaved.filename, src: src});    
            } else {
                toast.error(t('common', {ns: 'error'}) as string, {theme: 'colored'});
            }
        }
        setSaving(false);
    };

    return (
      
        <Modal
            variant="primary"
            open={true}
            title={t('image.modal.title')}
            onValid={onImageCrop}
            onClose={onClose}
        >

            <InputText
                label={t('image.modal.name')}
                autoFocus={true}
                value={name}
                onChange={(e) => setName(e.target.value)}
            />

            <EditImage
                ref={imageCrop}
                image={image}
            
            />

        </Modal>
      
    )
}

type ImageTooltipProps = {
    node?: HTMLElement;
    onCancel: () => void;
    onEdit: () => void;
    onDelete: () => void;
}

const ImageTooltip = ({node,  onCancel, onEdit, onDelete}: ImageTooltipProps) => {
    
    return(
        <div className="h-[76px] w-[140px] absolute flex flex-col border rounded-lg shadow-sm backdrop-blur-sm z-10 p-1">
  
            <div className='w-full flex flex-row' >

            </div>
    
            <div className='w-full flex flex-row justify-between'>
                <ButtonIcon icon='close' iconClass="h-8 w-8" className="text-info m-1" onClick={onCancel} />
                <ButtonIcon icon='edit' iconClass="h-8 w-8"  className="text-success m-1" onClick={onEdit} />
                <ButtonIcon icon='delete' iconClass="h-8 w-8" className="text-danger m-1" onClick={onDelete} />
            </div>
    
        </div>
    )
};

export { imageHandler, imageEdit, ImageTooltip }
