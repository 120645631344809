import { useState  } from 'react';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import { LANG_LIST } from '../../params';
import { ButtonText, Select } from '../../components';

export interface HomeViewNav {
  action: 'loading' | 'default',
  section: 'home' | 'price' | 'newSite',
};

const HomeView = () => {

  const navigate = useNavigate();
  const { t, i18n } = useTranslation(['home', 'nav']);
  const [langSelected, setLangSelected] = useState<string>()

  const onChangeLang = (lang: string) => {
    setLangSelected(lang)
    i18n.changeLanguage(lang)
  }

  return (
    <div className="h-full w-full bg-piPrimary">

      <div className='w-full flex justify-end pt-4 pr-4'>
        <ButtonText text={t('login')}
          variant='filled-primary'
          onClick={() => navigate('/login')}
        />
      </div>

      <div className='text-center text-4xl mt-20 text-piPrimaryContent'>
        {t('siteName', {ns: 'nav'})}   
      </div>

      <div className='flex justify-center my-10'>

        <ButtonText text={t('register')}
            variant='filled-primary'
            onClick={() => navigate('/register')}  
        />

      </div>
      
      <footer className="w-full border-zinc-900 bg-zinc-800 rounded-b-box rounded-tr-box flex min-h-[6rem] min-w-[18rem] flex-wrap items-center justify-center gap-2 text-white overflow-x-hidden border bg-cover bg-top p-4">
        <div className='grid'>
          <div className=''>Services</div>
        </div>
        <div className='grid'>
          <div className=''>Réseaux social</div>
        </div>
        <div className='grid'>
          <div className='flex'>
            <div>Langue</div>
            <Select
              defaultValue={langSelected}
              options={LANG_LIST}
              onChange={onChangeLang}
            />
          </div>
          <div className=''>Données personelles</div>
          <div className=''>Cookies / stockage</div>
        </div>
      </footer>
    </div>
  );
};

export { HomeView };