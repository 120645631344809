import { HelpPage } from '../../../../shared/schema/index';

const dashboradPage: HelpPage = {
    name: 'dashboard',
    label: "Tableau de bord",
    intro: "Le tableau de bord permet.",
    sections: [
        {
            name: "members",
            label: "Gestion des Membres",
            intro: "hhghj gjhg jhg",
            articles: [
                {
                    name: "list",
                    label: "Liste des membres",
                    content: "jhjhjkhk jhkjhjkhjkh jkhjkhkj hkjhjh jkhjhkj hkjhj bhbjhbhb jhbjhbj hbjhbbb"
                },
                {
                    name: "files",
                    label: "Fichiers",
                    content: "jhjhj khkjhk jhjkhjkh jkhjk hk jhk hjhj khjhkjh kjhjbh bjhbhbjh bjhbjhbjhb bb"
                },
                {
                    name: "new",
                    label: "Nouveau membre",
                    content: "jhjhjkh kjhkjhjkhjk hj khjkjkg jhghk  jhkjhjhjkhjhk jhkjhjbhbj hbh bjhb hbjhb jhbbb"
                },
            ]
        },{
            name: "publication",
            label: "Emailling & publications",
            intro: "hhghjgjhgjhg",
            articles: [
                {
                    name: "emailing",
                    label: "Emailing",
                    content: "jhjhjkhk jhkjhjkhj khjkhjkhkj hkjhjhjkh jhkjhkjh jbhb jhbhbjhbj hbjhbjhbbb"
                },
                {
                    name: "publication",
                    label: "Publication",
                    content: "jhjh jkhkj hkjhjk hjkhjkh jkjkgjhghkjh kjhjhjkhjhkjh kjh jbhbj hbhb jhbjhbjh bjhbbb"
                },
            ]
        }
    ]
}


const helpPages: HelpPage[] = [
    dashboradPage,
]

export default helpPages;