import { IconEnum, Title } from ".";


//Card
interface CardContainerProps {
  children?: React.ReactNode | string;
  title?: React.ReactNode | string;
  levelTitle?: number;
  text?: React.ReactNode | string;
  icon?: IconEnum;
  iconClass?: string; 
  className?: string;
  showIf?: boolean;
};
const Card = ({ children, className, showIf= true}: CardContainerProps) => {
  return showIf ? <div className={['w-full h-fit bg-white rounded-xl text-neutre mb-2 p-2 sm:p-4', className].join(' ')}>{children}</div> : null;
};

const SubCard = ({ children, className, showIf= true}: CardContainerProps) => {
  return showIf ? <div className={['w-full h-fit border rounded-lg boder-neutreLight focus-within:border-neutre p-2 mb-2', className].join(' ')}>{children}</div> : null;
};

const CardTitle = ({ children, className, title, levelTitle=1, text, icon, showIf= true }: CardContainerProps) => {
  return showIf ? 
  <div className={['flex flex-wrap justify-between items-center', levelTitle === 1 ? 'mb-2' : '', className].join(' ')}>
    <Title title={title} text={text} levelTitle={levelTitle} icon={icon}/>
    {children}
  </div> : null;
};

const CardHeader = ({ children, className }: CardContainerProps) => {
  return <div className={['w-full mb-2 p-2 sm:p-4', className].join(' ')}>{children}</div>;
};

const CardContent = ({ children, className }: CardContainerProps) => {
  return <div className={['p-2 sm:p-4', className].join(' ')} >{children}</div>;
};

const CardFooter = ({ children, className }: CardContainerProps) => {
  return <div className={['flex flex-wrap items-center justify-center border-t border-primary mt-4 p-2 sm:p-4', className].join(' ')}>{children}</div>;
};


export {
  Card,
  SubCard,
  CardTitle,
  CardHeader,
  CardContent,
  CardFooter,
};
