import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { LANG_LIST } from '../../params';
import { emailCheckFormat, postFunctionsApi, toFirstUpperCase } from '../../utils';
import { AuthCtx } from '../_context/Context';
import { UserInvit, UserRole } from '../../../../shared/schema/index';
import { Card, CardTitle, ContentCol, RadioList, InputText, Select, TextHelp, ContentRow, ButtonAction, InputAlignLeft, ButtonFooterBase } from '../../components';

type InvitUserProps = {
    showRoles: () => {name: string, label:string}[];
    addUser: (u: UserRole) => void;
};

const InvitUser = ({showRoles, addUser}: InvitUserProps) => {
    const [auth] = useContext(AuthCtx);
    const { t } = useTranslation('admin');
    const [userInvit, setUserInvit] = useState<UserInvit>({
        firstName: '',
        lastName: '',
        username: '',
        roleName: '',
        userSetting: {language: auth.me.userSetting.language},
        siteId: auth.siteId
    });

    const saveUserInvit = async () => {
        if (!emailCheckFormat(userInvit.username)) {
            return toast.error(t('invitUser.error.email') as string, {theme: 'colored'});
        }
        if (userInvit.firstName === '' || userInvit.lastName === '') {
            return toast.error(t('invitUser.error.name') as string, {theme: 'colored'});
        }
        if (userInvit.roleName === '') {
            return toast.error(t('invitUser.error.role') as string, {theme: 'colored'});
        }
        const saveInvited = await postFunctionsApi<UserRole>('invitUser', userInvit)

        if (typeof saveInvited !== 'string') {
            saveInvited.roleName = userInvit.roleName

            addUser(saveInvited);
            toast.success(t('invitUser.success.invit') as string, {theme: 'colored'});
            setUserInvit({firstName: '', lastName: '', username: '', roleName: '', userSetting:{language: auth.me.userSetting.language}, siteId: auth.siteId})
        } else {
            toast.error(t('invitUser.error.invit') as string, {theme: 'colored'});
        }   
    }
    
    return (
        <Card>
          
            <CardTitle title={t('invitUser.title')} />
             
            <ContentCol>

                <InputAlignLeft>

                    <InputText
                        id='username'
                        label={t('invitUser.email')}
                        labelPosition='leftAlign'
                        value={userInvit.username}
                        onChange={(event: any) => setUserInvit({...userInvit, username: event.target.value.toLowerCase().trim()})}
                        autoFocus={true}
                    />
                
                    <InputText
                        id='firstName'
                        label={t('invitUser.firstName')}
                        labelPosition='leftAlign'
                        value={userInvit.firstName}
                        onChange={(event: any) => setUserInvit({...userInvit, firstName: toFirstUpperCase(event.target.value)})}
                    />
                
                    <InputText
                        id='lastName'
                        label={t('invitUser.lastName')}
                        labelPosition='leftAlign'
                        value={userInvit.lastName} 
                        onChange={(event: any) => setUserInvit({...userInvit, lastName: toFirstUpperCase(event.target.value)})}
                    />

                </InputAlignLeft>

                <TextHelp text={t('invitUser.rolesTitle')} help={{page: 'adminUsers', section: 'invit', article: 'roles'}} />

                <RadioList name='role'
                    items={showRoles()}
                    valueSelected={showRoles().find(r => r.name === userInvit.roleName)}
                    setValue={(role: any) => setUserInvit({...userInvit, roleName: role.name})} 
                />

                <ContentRow>

                    <TextHelp text={t('invitUser.language')} help={{page: 'adminUsers', section: 'invit', article: 'language'}} />

                    <Select
                        options={LANG_LIST.filter(l => l.name !== userInvit.userSetting.language)}
                        defaultValue={LANG_LIST.find((l => l.name === userInvit.userSetting.language))}
                        onChange={(lang: any) => setUserInvit({...userInvit, userSetting: {language: lang.name}})}
                        className='w-40'
                    />

                </ContentRow>


                <ButtonFooterBase>
                    <ButtonAction action='save'
                        onClick={() => saveUserInvit()}
                        className='mr-4'
                    />
                    
                    <ButtonAction action='clear'
                        onClick={() => setUserInvit({
                            firstName: '',
                            lastName: '',
                            username: '',
                            roleName: '',
                            userSetting:{language: auth.me.userSetting.language}, siteId: auth.siteId
                        })}
                    />
                </ButtonFooterBase>

            </ContentCol>
    
        </Card>
    );
};

export { InvitUser };