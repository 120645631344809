import { Place } from "../../../../shared/schema";
import { parsePointer } from "./Parse";

const newPlace = (name?: string): Place => {
    const siteId = JSON.parse(localStorage.getItem('s') as string);
    const object: Place = {
        name: name ? name : '',
        text: '',
        site: parsePointer('Site', siteId)
    };

    return object;
}

const newArticle = (title?: string): Place => {
    const siteId = JSON.parse(localStorage.getItem('s') as string);
    const object: Place = {
        title: title ? title : '',
        text: '',
        site: parsePointer('Site', siteId)
    };

    return object;
}

export { newPlace, newArticle }