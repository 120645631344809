import React, { ButtonHTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';
import { onButtonStyle, VariantButtonEnum, ActionButtonEnum, Icon, IconEnum  } from '.';

interface ButtonBaseProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  showIf?: boolean;
  buttonClass?: string;
  children?: React.ReactNode | string;

  action?: ActionButtonEnum;

  icon?: IconEnum;
  iconLeft?: boolean;
  iconClass?: string;

  variant?: VariantButtonEnum;

  onClick?: (a?: any) => void;
};

const ButtonBase = ({ children, action, icon, iconLeft= false, variant,  showIf = true, buttonClass, iconClass, onClick, ...rest}: ButtonBaseProps) => {
  const { t } = useTranslation('button');
  const iconSvg = icon ? <Icon name={icon} className={iconClass}  /> : null;
  const styleClass = (variant || action) ? onButtonStyle(variant, action) : '';

  return ( showIf ?
    <button {...rest}
      onClick={onClick ? () =>  onClick() : undefined}
      className={[styleClass, buttonClass].join(' ')}
    >
      {iconLeft && iconSvg}
      {action ? t(action) : children}
      {!iconLeft && iconSvg} 
    </button>
  : <div></div>);
};

//ButtonAction
interface ButtonActionProps extends ButtonBaseProps {
  showIf?: boolean;
  className?: string;
  action: ActionButtonEnum; 
  onClick?: (a?: any) => void;
};

const ButtonAction = ({action, className, showIf = true, onClick, ...rest}: ButtonActionProps) => {

  return ( <ButtonBase action={action}
    buttonClass={[className].join(' ')}
    onClick={onClick ? () =>  onClick() : undefined}
    showIf={showIf}
    {...rest}
  />)
};

//ButtonText
interface ButtonTextProps extends ButtonBaseProps {
  showIf?: boolean;
  className?: string;
  text: string | React.ReactNode;
  variant?: VariantButtonEnum; 
  onClick?: (a?: any) => void;
};

const ButtonText = ({text, variant, className, showIf = true, onClick, ...rest}: ButtonTextProps) => {

  return ( <ButtonBase children={text} variant={variant}
    buttonClass={[className].join(' ')}
    onClick={onClick ? () =>  onClick() : undefined}
    showIf={showIf}
    {...rest}
  />)
};

//ButtonIcon
interface ButtonIconProps extends ButtonBaseProps {
  showIf?: boolean;
  className?: string;
  icon: IconEnum;
  iconClass?: string;
  onClick?: (a?: any) => void;
};

const ButtonIcon = ({ children, icon, iconClass, className, showIf = true, onClick, ...rest}: ButtonIconProps) => {

  return ( <ButtonBase icon={icon}
    iconClass={iconClass}
    buttonClass={['flex items-center', className].join(' ')}
    onClick={onClick ? () =>  onClick() : undefined}
    showIf={showIf}
    {...rest}
  >
    {children}
  </ButtonBase>
  )
};

//ButtonFooter
interface ButtonFooterBaseProps {
  children?: React.ReactNode;
  className?: string;
  showIf?: boolean;
};
const ButtonFooterBase = ({ children, className, showIf= true }: ButtonFooterBaseProps) => {
  return showIf ?(
    <div className={['w-full flex flex-wrap justify-between mt-2', className].join(' ')}>

      {children}
    
    </div>

  ) : null;
};

//ButtonFooter
interface ButtonFooterProps {
  action: ActionButtonEnum;
  ifAction?: boolean;
  onValid: () => void;
  onCancel: () => void;
  className?: string;
  showIf?: boolean;
};
const ButtonFooter = ({action, onValid, onCancel, className, ifAction=true, showIf}: ButtonFooterProps) => {
  return(
    <ButtonFooterBase className={className} showIf={showIf}>

      <ButtonAction showIf={ifAction} action={action} onClick={onValid} className='mt-1' />

      <ButtonAction action='cancel' onClick={onCancel} className='mt-1'/>
    
    </ButtonFooterBase>
  );
};

export {ButtonBase, ButtonAction, ButtonText, ButtonIcon, ButtonFooterBase, ButtonFooter};
