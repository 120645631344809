import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { memberRelationTypes } from '../../../params';
import { deleteClassApi, emailCheckFormat, getClassApi, postClassApi, putClassApi } from '../../../utils';
import { AuthCtx } from '../../_context/Context';
import { CheckExistResponse, Member, RelationType, SaveMember, TypeCheckMember } from '../../../../../shared/schema/index';
import { newMember, parsePointer } from '../../_object';
import { Card, Pagination, ListSearch, ContentRow, TextBase, getMemberName, FilterOptions, Suspense, ButtonIcon } from '../../../components';
import { requestCheckMember } from './RequestCheckMember';
import { MemberCard } from './MemberCard';
import { MemberEditCard } from './MemberEditCard';

const MemberList = () => {
    const [auth] = useContext(AuthCtx);
    const { t } = useTranslation(['member', 'nav']);
    const relationTypes = memberRelationTypes.map(m =>  {return  {...m, label: t('relationType', {context: m.name})}});
    const filterTags = [
        { label: 'Tout', value: 'all' },
        { label: 'Prénom', value: 'firstName' },
        { label: 'Nom', value: 'lastName' },
        { label: 'Email', value: 'email' },
    ];
    const [memberSearch, setMemberSearch] = useState<string>('');
    const [filterTagSelected, setFilterTagSelected] = useState<FilterOptions>(filterTags[0]);
    const [memberFilterPagination, setMemberFilterPagination] = useState<number>(0); 
    const [memberFilter, setMemberlFilter] = useState<Member[]>([]);

    const [choice, setChoice] = useState<'create' | 'changeEmailing' | 'keepEmailing' | number>()
    
    const [refreshList, setRefreshList] = useState<boolean>(true);
    const [showAddMember, setShowAddMember] = useState<boolean>(false);

    //const [choice, setChoice] = useState<'create' | 'changeEmailing' | 'keepEmailing' | number>()

    const [saving, setSaving] = useState<boolean>(true);
    const nbItemsPerPage: number = 20; 

    useEffect(() => {
        async function fetchData() {
            let queryWhere: any = { };
            
            if (memberSearch.length > 0)
                switch (filterTagSelected.value) {
                    case 'all': queryWhere.$or = [
                        {"firstName": {"$regex": memberSearch, $options: "i"}},
                        {"lastName": {"$regex": memberSearch, $options: "i"}},
                        {"email": {"$regex": memberSearch, $options: "i"}},
                        {"attribute": {"$regex": memberSearch, $options: "i"}}
                    ];
                    break;
                    case 'firstName': queryWhere.firstName = {"$regex": memberSearch, $options: "i"};
                    break;
                    case 'lastName': queryWhere.lastName = {"$regex": memberSearch, $options: "i"};
                    break;
                    case 'email': queryWhere.email = {"$regex": memberSearch, $options: "i"};
                    break;
                };

            //filter where
            const getMembers = await getClassApi<Member>('Member', {
                "where" : JSON.stringify(queryWhere),
                "count": 1,
                "order": 'lastName',
                "limit": nbItemsPerPage,
                "skip": memberFilterPagination*nbItemsPerPage,
                "include" : 'relations.member'
            });
            
            if (typeof getMembers !== 'string') {
                getMembers.results.sort((a: Member, b: Member) => (a.lastName.localeCompare(b.lastName)) || (a.firstName.localeCompare(b.firstName)));
                setMemberlFilter(getMembers.results);
                setRefreshList(false);   
            } else toast.error(t('errorConnection') as string, {theme: 'colored'})
            setSaving(false);        
        }
        fetchData();
    },[memberSearch, filterTagSelected, memberFilterPagination, refreshList, setSaving]);
    
    const onSaveMember = async({type, member, child, relation, isUpdateNameEmail, isUpdateRelation}: SaveMember) => {
        if (member.email !== '' && !emailCheckFormat(member.email)) {
            toast.error(t('error.emailFormat') as string, {theme: 'colored'});
            return
        }

        switch(type) {
            case 'create':
                const check = await checkMember('create', member);
                
                if (typeof check !== 'string') {
                    if (check.response === 'changeEmailing') {
                        const memberExist = Object.assign({}, check.member)
                        memberExist.emailing = false
                        await saveMember(memberExist);
                    }

                    if (check.response === 'keepEmailing') {
                        member.emailing = false
                    }
                    
                    const memberSaved = await saveMember(member);
            
                    if (memberSaved !== "error") {
                        setRefreshList(true);
                        setShowAddMember(false);
                        return memberSaved
                    }
                }
            break;
                
            case 'update':
                if (isUpdateNameEmail) {
                    const check = await checkMember('update', member);
                    if (typeof check !== 'string') {
                        if (check.response === 'changeEmailing') {
                            const memberExist = Object.assign({}, check.member)
                            memberExist.emailing = false
                            await saveMember(memberExist);
                        }
    
                        if (check.response === 'keepEmailing') {
                            member.emailing = false
                        }

                        const memberSaved = await saveMember(member);
                        if (memberSaved !== "error") {
                            setRefreshList(true);
                            return memberSaved
                        }
                    }

                } else {
                    const memberSaved = await saveMember(member);
                    if (memberSaved !== "error") {
                        setRefreshList(true);
                        return memberSaved
                    }
                }
            break;
  
            case 'createRelation':
                if (child) {
                    const childCheck = await checkMember('createRelation', child, member.objectId);
                    if (typeof childCheck !== 'string') {
                        let newChild = Object.assign({}, childCheck.member)

                        if (childCheck.response === 'changeEmailing') {
                            newChild = Object.assign({}, childCheck.member)
                            const memberExist = childCheck.member
                            memberExist.emailing = false
                            await saveMember(memberExist);
                        }
    
                        if (childCheck.response === 'keepEmailing') {
                            newChild.emailing = false
                        }

                        const relationChild = findRelationOpposite(relation);

                        if (relationChild && member.objectId) {
                            const parentPointer = parsePointer('Member', member.objectId);
                            childCheck.member.relations.push({member: parentPointer, relationType: relationChild})
                        }

                        const childSaved = await saveMember(newChild);

                        if (typeof childSaved !== 'string' && childSaved.objectId && member.objectId) {
                            const childPointer = parsePointer('Member', childSaved.objectId);
                            const parentRelation = {member: childPointer, relationType: relation};
                
                            const addRelation = await putClassApi('Member', member.objectId, {relations: {__op: "Add", objects:[parentRelation]}});
                            if (typeof addRelation !== 'string') {
                                setRefreshList(true);
                                return newChild
                            }         
                        }
                    }
                }
            break;
                
            case 'updateRelation':
                const updateChild = Object.assign({}, child); 

                if (child) {
                    if (isUpdateRelation) {
                        const updateParent = Object.assign({}, member);
                        const relationChild = findRelationOpposite(relation);
                        //Relations
                        const parentRelation = updateParent.relations
                        const childRelation = updateChild.relations
                        //Find index relation
                        const parentRelationIndex = parentRelation.findIndex(p => p.member.objectId === updateChild.objectId)
                        const childRelationIndex = childRelation.findIndex(c => c.member.objectId === updateParent.objectId)
                        //Update relationtype
                        parentRelation[parentRelationIndex].relationType = relation
                        childRelation[childRelationIndex].relationType = relationChild
                        //update relation
                        const updateRelationParent = await putClassApi('Member', updateParent.objectId as string, {relations: parentRelation});
                    }

                    if (isUpdateNameEmail) { //only email
                        const emailCheck = await checkEmail(updateChild, member.objectId);
                        if (emailCheck === 'error') {
                            return
                        } else if (emailCheck.exist && emailCheck.member) {
                            const request = await requestCheckMember({
                                type: 'create',
                                request: 'email',
                                member: updateChild,
                                email: emailCheck.member
                            })

                            if (request !== 'cancel') {
                                if (request.response === 'changeEmailing') {
                                    const memberExist = request.member
                                    memberExist.emailing = false
                                    await saveMember(memberExist);
                                }
            
                                if (request.response === 'keepEmailing') {
                                    updateChild.emailing = false
                                }
                            } else return
                        }
                    }
                    //Save Child
                    const chilSaved = await saveMember(updateChild);
                    if (chilSaved !== "error") {
                        setRefreshList(true);
                        return chilSaved
                    }
                }
            break;
        }
    };

    const checkMember = async(type: TypeCheckMember, member: Member, parentId?: string) => { 
        const nameCheck = await checkName(member, parentId);
        if (nameCheck === 'error') {
            return 'error'
        //Name exist 
        } else if (nameCheck.exist) {
            //Member has email
            if (member.email !== '') {
                const emailCheck = await checkEmail(member, parentId);
                if (emailCheck === 'error') {
                    return 'error'
                } else if (emailCheck.exist && emailCheck.member) {
                    switch(type) {
                        case 'create':
                        case 'update':
                        case 'updateRelation':
                            toast.error(t('error.memberExist', {member: getMemberName({t: t, name: auth.siteSetting.memberName})}) as string, {theme: 'colored'});
                            return 'error'
                        case 'createRelation':
                            return {response: 'create', member: emailCheck.member}
                    }
                }
            
            }
            //Member has Not email or email not exist
            switch(type) {
                case 'create':
                    return requestCheckMember({
                        type: 'create',
                        request: 'name',
                        member: member
                    })
                case 'update':
                    return requestCheckMember({
                        type: 'update',
                        request: 'name',
                        member: member
                    })
                case 'createRelation':
                    return requestCheckMember({
                        type: 'createRelation',
                        request: 'name',
                        member: member,
                        members: nameCheck.members
                    })
                case 'updateRelation':
                    return requestCheckMember({
                        type: 'updateRelation',
                        request: 'name',
                        member: member,
                        members: nameCheck.members
                    })
            }

        //Name Not exist 
        } else {
            //Member has email & emailing
            if (member.emailing) {
                const emailCheck = await checkEmail(member, parentId);
                if (emailCheck === 'error') {
                    return 'error'
                } else if (emailCheck.exist && emailCheck.member) {
                    return requestCheckMember({
                        type: 'create',
                        request: 'email',
                        member: member,
                        email: emailCheck.member
                    })
                }
            }
            //Member has Not emailing or email not exist
            return {response: 'create', member: member}
        }
    };

    const checkName = async(member: Member, parentId?: string): Promise<CheckExistResponse | 'error'> => {
        const getMember = await getClassApi<Member>('Member', {
            where: JSON.stringify({
                name: {$text: {
                    $search: {$term: member.firstName + ' ' + member.lastName, $caseSensitive: false}
                }}
            }),
        });

        if (typeof getMember !== 'string') {
            let members = getMember.results;
            if (member.objectId) members = members.filter(m => m.objectId !== member.objectId);
            if (parentId) members = members.filter(m => m.objectId !== parentId);

            return members.length > 0 ? {exist: true, members: members} : {exist: false, member: member}
        }
        toast.error(t('errorRetry') as string, {theme: 'colored'});
        return 'error'
    };

    const checkEmail = async(member: Member, parentId?: string): Promise<CheckExistResponse | 'error'> => {
        const getEmail = await getClassApi<Member>('Member', {
            where: JSON.stringify({
                email: member.email,
                emailing: true,
            })
        });
        
        if (typeof getEmail !== 'string') {
            let members = getEmail.results;
            if (member.objectId) members = members.filter(m => m.objectId !== member.objectId);
            if (parentId) members = members.filter(m => m.objectId !== parentId);

            return members.length > 0 ? {exist: true, member: members[0]} : {exist: false, member: member}
        }
        toast.error(t('errorRetry') as string, {theme: 'colored'}); //error retry
        return 'error'
    };

    const findRelationOpposite = (relation: RelationType | undefined) => {
        switch(relation) {
            case 'spouse': return relation;
            case 'child': return 'parent';
            case 'parent': return 'child';
        }
    };

    const saveMember = async(member: Member) => {
        let error = false
        let saved: Member | undefined = undefined
        if (member.objectId) {
            const memberSaved = await putClassApi<Member>('Member', member.objectId, member);
            if (typeof memberSaved !== 'string') saved = Object.assign({}, member)
        } else {
            const memberSaved = await postClassApi('Member', member);
            if (typeof memberSaved !== 'string')  {
                saved = Object.assign({}, member)
                saved.objectId = memberSaved.objectId
            }
        }
        return saved ?  saved : 'error'
    }


    const deleteMember = async(memberId: string) => {
        const deleteMember = await deleteClassApi('Member', memberId);
        //Search member is relation
        const getMember = await getClassApi<Member>('Member', {
            where: {
                "relations.member.objectId": memberId
            },
        });

        if (typeof getMember !== 'string' && getMember.results.length > 0) {
            const promises = getMember.results.map(async member => {          
                const memberUpdate = {...member}
                if (member.objectId) {
                    const relations = memberUpdate.relations.filter(r => r.member.objectId !== memberId);
                    //type relation ?
                    memberUpdate.relations = relations
                    //batch ?
                    await putClassApi('Member', member.objectId, memberUpdate);
                } 
            })
            
            await Promise.all(promises)
        }

        if (typeof deleteMember !== 'string') {
            setRefreshList(true);
            return true
        }
        toast.error(t('errorRetry') as string, {theme: 'colored'});
        return false;
    };

    const deleteRelation = async(member: Member, childId: string) => {
        const relation = member.relations.find(r => r.member.objectId === childId);
        const parentRelation = member.relations.filter(r => r.member.objectId !== childId);
        const parentRelationPointer = parentRelation.map(r => {
            return {member: parsePointer('Member', r.member.objectId as string), relationType: r.relationType}
        });
        //Delete relation child
        if (relation && relation.relationType !== 'other' && relation.member) {
            const parentId = member.objectId;
            let childRelations = relation.member.relations;
            childRelations = childRelations?.filter(r => r.member.objectId !== parentId);
            const childRelationPointer = childRelations?.map(r => {
                return {member: parsePointer('Member', r.member.objectId as string), relationType: r.relationType}
            });
            await putClassApi('Member', childId, {relations: childRelationPointer})
        }
        const removeRelation = await putClassApi('Member', member.objectId as string, {relations: parentRelationPointer});
        if (typeof removeRelation !== 'string') {   
            setRefreshList(true);
            return parentRelation;
        }
        toast.error(t('errorRetry') as string, {theme: 'colored'});
        return false
    };

    return (

            <Card>

                
                <ListSearch
                    selected={filterTagSelected || filterTags[0]}
                    items={filterTags}
                    onSelect={(value) => setFilterTagSelected(value)}
                    searchValue={memberSearch}
                    onSearch={(text) => setMemberSearch(text)}
                />

                <ContentRow className='justify-end'>

                    <ButtonIcon icon='more' iconClass='w-7 h-7' className='mb-2'
                        onClick={() => setShowAddMember(!showAddMember)}
                    >
                        <TextBase textClass='ml-2' text={t('addMember', {member: getMemberName({t: t, name: auth.siteSetting.memberName})})}/>
                    </ButtonIcon>

                </ContentRow>

                {showAddMember &&
                <MemberEditCard key='newMember'
                    member={newMember(auth.me.userSetting.language)}
                    onSaveMember={onSaveMember}
                    onCancel={() => setShowAddMember(false)}
                />
                }

                {memberFilter.map(member => (
                    <MemberCard key={member.objectId as string}
                        member={member}
                        relationTypes={relationTypes}
                        onSaveMember={onSaveMember}
                        onDelete={deleteMember}
                        onDeleteRelation={deleteRelation}
                    />
                ))}

                <Pagination
                    nbItems={memberFilter.length}
                    nbItemsPerPage={nbItemsPerPage}
                    onChangePage={setMemberFilterPagination}
                    initPagination={memberFilterPagination}
                />



                <Suspense showIf={saving} />

                <div id='modal-check-member'></div>
                        
            </Card>
    );
};

export { MemberList };