import { useEffect, useLayoutEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import '@stripe/stripe-js';
import { getApi, postFunctionsApi, useLocalStorage } from '../../utils';
import  { ProtectedNav, SelectRole, NoRole } from '.';
import { AuthCtx } from '../_context/Context';
import { Auth, User, AuthMe, RolesResponse } from '../../../../shared/schema/index';
import { authObject, authMeObject } from '../_object';
import { Suspense, Theme } from '../../components';
import { CreateSite } from '../adminSubscription/createSite/CreateSite';
import { colorDefault, fontDefault } from '../../params';

type AuthNavView = 'default' | 'select' | 'createSite' | 'noRole';

const ProtectedView = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const {t, i18n } = useTranslation('nav');

  const [token] = useLocalStorage('t');
  const [navStore] = useLocalStorage('n');
  const [meStore, setMeStore] = useLocalStorage('me');
  const [, setSiteStore] = useLocalStorage('s');

  const [auth, setAuth] = useState<Auth>();

  const [roles, setRoles] = useState<RolesResponse[]>([]);

  const [navView, setNavView] = useState<AuthNavView>('default') 
  
  const [color, setColor] = useState<string>();
  const [font, setFont] = useState<string>();

  const [render, setRender] = useState<JSX.Element>(<Suspense/>);

  //Check User Lang -> Change Lang
  useEffect(() => {
    if (auth && auth.me && auth.me.userSetting.language !== i18n.language) i18n.changeLanguage(auth.me.userSetting.language);
  }, [auth, i18n])

  //No token -> Login or Home
  useEffect(() => {
    if (!token && location.pathname === '/') {
      return navStore ? navigate('/login') : navigate(t('home.path'));
    }
  },[t, token, navStore, location.pathname, navigate]);

  //Get Roles
  useEffect(() => {
    async function fetchData() { 
      console.log('protected view fetchData ')
      let me: AuthMe | undefined =  meStore ? authMeObject(meStore) : undefined;
      setMeStore(undefined);
      const roleList: RolesResponse[] = [];
      let roleSelected: RolesResponse | undefined = undefined;
      let createSite: boolean = false;
      let noRole: boolean = false;

      //Get me
      if (!me) {
        const getMe = await getApi<User>('users/me', undefined, {forceInstance: true});
        if (typeof getMe !== 'string') me = authMeObject(getMe)
      }

      if (me) {
        //Get Roles
        const getRoles = await postFunctionsApi<RolesResponse[]>('getRoles');
        if (typeof getRoles !== 'string') {
          if (getRoles) 
          for (let i = 0; i < getRoles.length; i++) {
            const role = getRoles[i];
            if (navStore && role.label === navStore.r) roleSelected = role;
            if (role.label === 'adminTmp') createSite = true;
            if (role.siteSetting || role.label === 'adminTmp') roleList.push(role);
          };
          if (roleList.length === 0) noRole = true;
          if (!roleSelected && !noRole && !createSite) roleSelected = roleList[0];

          setRoles(roleList);
          
          //Set auth
          //if (roleSelected) {
            const newAuth = authObject(roleSelected);
            newAuth.me = me;
            
            setSiteStore(roleSelected?.siteId); 
            setColor(roleSelected?.siteSetting.color ? roleSelected.siteSetting.color  : colorDefault);
            setFont(roleSelected?.siteSetting.font ? roleSelected.siteSetting.font : fontDefault);
          console.log(newAuth)
            setAuth(newAuth);
          //};

          //Create site
          if (createSite) return setNavView('createSite');
          
          //No role
          if (noRole) return setNavView('noRole');
          
          return setNavView('default');
        }
      }

      toast.error("Problème d'identification. Veuillez vous reconnecter.", {theme: 'colored'});
      return navigate('/login');
    };

    if (!auth) fetchData();
  }, [t, auth, navStore, meStore, setMeStore, setSiteStore, navigate]);

  //TODO Create function for NoRole -> adminTmp
  
  useLayoutEffect(() => {
    if (auth) setRender(

      <AuthCtx.Provider value={[auth, setAuth]} >


        <Theme color={color} font={font} />
        

        {navView === 'default' &&
        <ProtectedNav selectRole={setNavView} />
        } 

        {navView === 'select' &&
        <SelectRole roles={roles} selectRole={setNavView} />
        }

        {navView === 'createSite' &&
        <CreateSite />
        }

        {navView === 'noRole' &&
        <NoRole />
        }

      </AuthCtx.Provider>
    )
  }, [auth, roles, navView, color, font])
   
  return (render);

};

export type { RolesResponse, AuthNavView }
export default ProtectedView;
