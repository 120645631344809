import { ContentRow, InputSearch, Select } from '.';

type FilterOptions = {
  label: string;
  value: string;
};

type ListSearchProps = {
  selected: FilterOptions;
  items: FilterOptions[];
  onSelect: (value: FilterOptions) => void;
  searchValue: string;
  onSearch: (e: string) => void;
};

const ListSearch = ({ selected, items, onSelect, searchValue, onSearch }: ListSearchProps) => {

  return (
    <ContentRow>

      <InputSearch
        searchValue={searchValue}
        onSearch={(e: string) => onSearch(e)}
      />

      <Select
        options={items}
        defaultValue={selected}
        onChange={(value: any) => onSelect(value)}
        className='w-fit min-w-[8rem] flex-none'
      />

    </ContentRow>
  );
};


type ListMapProps<T> = {
  component: (item: T, key: number) => React.ReactNode;
  items: T[];
  classContainer?: string;
};

const ListMap = <T,>({ component, items, classContainer }: ListMapProps<T>) => {
  return (
    <div className="w-full flex flex-col border-t border-gray-300">
      <ul>{items.map(component)}</ul>
    </div>)
  ;
};

type LiProps = {
  children:  React.ReactNode;
  className?: string;
};
const Li = ({ children, className }: LiProps) => {
  return (
    <li className={['border-b border-neutre py-2', className].join(' ')}>
      
        {children}

    </li>
  );
};

type LiHeaderProps = {
  children:  React.ReactNode;
  className?: string;
};
const LiHeader = ({ children, className }: LiHeaderProps) => {
  return (
    <div className={['grid gap-3 items-center', className].join(' ')}>
      {children}
    </div>
  );
};

type LiActionsProps = {
  children:  React.ReactNode;
  className?: string;
};
const LiActions = ({ children, className }: LiActionsProps) => {
  //TODO ?
  return (
      <div className='flex items-center text-right'>
        {children} 
      </div>
  );
};

type LiContentProps = {
  children:  React.ReactNode;
  className?: string;
};
const LiContent = ({ children, className }: LiContentProps) => {
  //TODO ?
  return (
    <div>
      {children} 
    </div>
  );
};




export {ListSearch, ListMap, Li, LiHeader, LiActions, LiContent};
export type {FilterOptions}
