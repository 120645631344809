import { useState, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { blobToBase64, postFunctionsApi } from '../../utils';
import { AuthCtx } from '../_context/Context';
import { ButtonAction, Card, CardTitle, ContentCol, ImageCrop } from '../../components';

const BackgroundSetting = () => {
    const [auth, setAuth] = useContext(AuthCtx);
    const { t } = useTranslation(['siteSetting']);
    const [img, setImg] = useState<string>('');
    
    const [openBackground, setOpenBackground] = useState<boolean>(false);
    const [saving, setSaving] = useState<boolean>(false);

    useEffect(() => {
        if (auth.siteBg) {
            setImg(auth.siteBg);
        } 
    },[auth.siteBg]);

    const saveBackground = async(file: File | Blob) => {
        setSaving(true)
        if (file) {
            let data = await blobToBase64(file)
            data = data.replace('data:image/png;base64,', '')   
            const fileSaved = await postFunctionsApi('saveBgSite', {siteId: auth.siteId, data: data});
            if (typeof fileSaved !== 'string') {
                if (auth.siteBg) URL.revokeObjectURL(auth.siteBg);
                const blobUrl = URL.createObjectURL(file);
    
                setAuth({...auth, siteBg: blobUrl});
                setImg(blobUrl)
                setOpenBackground(false);
            } else {
                toast.error(t('common', {ns: 'error'}) as string, {theme: 'colored'});
            }
        }
        setSaving(false);
    };
    
    const removeBackground = async() => {
        await postFunctionsApi('deleteBgSite', {siteId: auth.siteId});
        if (auth.siteBg) URL.revokeObjectURL(auth.siteBg)
        setAuth({...auth, siteBg: undefined})
        setImg('');
    }

    
    const onCancel = () => {
        setOpenBackground(false)
    }
    

    return (

        <Card>
            <CardTitle title={t('background')}>

                <ButtonAction action='modify'
                    showIf={!openBackground}
                    className='ml-2'
                    onClick={() => setOpenBackground(true)}
                />

            </CardTitle>


            <ContentCol showIf={img !== '' && !openBackground} >
                <img src={img} alt={auth.siteName}/>
            </ContentCol>

            <ImageCrop showIf={openBackground}
                removeIf={img !== ''}
                saving={saving}
                imgFormat='square'
                imgSrc={img}
                setImgSrc={setImg}
                onSaveImage={saveBackground}
                onRemoveImage={removeBackground}
                onCancel={onCancel}               
            />

        </Card>

    
    );
};

export { BackgroundSetting };