import { View } from "../../components";



const EmailingView = () => {


  return (
    <View>

      <div className='mb-2'>

        
      </div>

    </View>
  );
};

export { EmailingView };