import { useTranslation } from 'react-i18next';
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
import { PiInvoice } from '../../../../shared/schema/index';


// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: '#FFFFFF',
    padding: 40,
    font: 'courrier'
  },
  section: {
    paddingBottom: 10,
    fontSize: 10,
  },
  invoice: {
    textAlign: 'right',
    fontSize: 25
  },
  bold: {
    fontSize: 15,
    fontWeight: 'bold'
  },
  invitCom: {
    paddingBottom: 20,
    fontSize: 12,
  },
  customer: {
    paddingBottom: 15,
    fontSize: 12,
    marginLeft: 350
  },
  numDate: {
    paddingTop: 20,
    paddingBottom: 20,
    fontSize: 12,
  },
  header: {
    fontSize: 15,
    flex: 'row',
  },
  headerBorder: {
    marginTop:20,
    borderTop: '1 solid grey',
    marginLeft: 20,
    marginRight: 80
  },
  label: {
    marginLeft: 20
  },
  quantity: {
    marginLeft: 280
  },
  price: {
    marginLeft: 350
  },
  product: {
    fontSize: 15,
    paddingTop: 10,
    flex: 'row',
  },
  pageBottom: {
    position: 'absolute',
    fontSize: 10,
    bottom: 10,
    left: 10,
    padding: 20,
  }

});


// Create Document Component
type InvoicePDFProps = {invoice: PiInvoice}

export const InvoicePDF = ({invoice}: InvoicePDFProps) => {
    const {t} = useTranslation('admin');

    return (
        <Document>
            <Page size="A4" style={styles.page}>
                <View style={styles.section}>
                    <Text style={styles.invoice}>{t('invoiceAdmin.invoice')}</Text>
                </View>

                <View style={styles.invitCom}>
                    <Text style={styles.bold}>InvitCom</Text>
                    <Text>14 rue Elise Dreux</Text>
                    <Text>37000 Tours</Text>
                    <Text>FRANCE</Text>
                </View>

                <View style={styles.customer}>
                    <Text>{t('invoiceAdmin.customer')}</Text>
                    <Text style={styles.bold} >{invoice.society ? invoice.society.name : invoice.userPay?.firstName + ' ' + invoice.userPay?.lastName}</Text>
                    <Text>{invoice.address?.street}</Text>
                    <Text>{invoice.address?.addStreet}</Text>
                    <Text>{invoice.address?.city + ' ' + invoice.address?.zipCode}</Text>
                    <Text>{invoice.address?.state}</Text>
                    <Text>{invoice.address?.country}</Text>

                </View>

                <View style={styles.numDate}>
                    <Text style={styles.bold}>{t('invoiceAdmin.date') + ': ' + new Date(invoice.createdAt as string).toLocaleDateString()}</Text>
                    <Text style={styles.bold}>{t('invoiceAdmin.numInvoice') + ': ' + invoice.num}</Text>
                </View>

                <View style={styles.header}>
                    <Text style={styles.label}>{t('invoiceAdmin.label')}</Text>
                    <Text style={styles.quantity}>Q</Text>
                    <Text style={styles.price}>{t('invoiceAdmin.price')}</Text>
                </View>

                <View style={styles.headerBorder} ></View>

                <View style={styles.product}>
                    <Text style={styles.label}>{t('invoiceAdmin.productLabel', {context: invoice.product?.name})}</Text>
                    <Text style={styles.quantity}>{invoice.quantity}</Text>
                    <Text style={styles.price}>{t('amountVal', { val: (invoice.amount as number) /100, formatParams: { val :{ currency: invoice.currency}}})}</Text>
                </View>

                <View style={styles.pageBottom}>
                    <Text>Texte de pied de page  - TODO</Text>
                </View>

            </Page>
        </Document>
    )
};