import {
  ArrowUpOnSquareIcon, ArrowDownOnSquareIcon,
  BellIcon,
  PlusCircleIcon,MinusCircleIcon,
  ArrowDownCircleIcon,
  PlayIcon,
  XCircleIcon,
  TrashIcon,
  PencilIcon,
  EnvelopeIcon,
  QuestionMarkCircleIcon,
  KeyIcon,
  PaperAirplaneIcon,
  PhoneIcon,
  MagnifyingGlassIcon,
  EyeIcon, EyeSlashIcon,
  UserIcon,
  AdjustmentsHorizontalIcon,
  CreditCardIcon,
  GlobeEuropeAfricaIcon,
  GlobeAltIcon
} from '@heroicons/react/24/solid';

type IconEnum = 
  'arrowLeft' | 'arrowRight' |
  'arrowCircleDown' | 'arrowCircleUp' |
  'visibility' | 'visibilityOff' |
  'delete' |
  'help' |
  'notifications' |
  'send' |
  'more' | 'less' |
  'edit' |
  'close' |
  'download' | 'upload' |
  'email' |
  'key' |
  'phone' |
  'search' |
  'user' |
  'setting' |
  'creditCard' |
  'globe' |
  'web';

const onIcon = (icon: IconEnum, className?: string)   => {
  switch (icon) {
    case 'more': return <PlusCircleIcon className={className} />;
    case 'less': return <MinusCircleIcon className={className} />;
    case 'arrowCircleDown': return <ArrowDownCircleIcon className={className} />;
    case 'arrowCircleUp': return <ArrowDownCircleIcon className={['rotate-180', className].join(' ')} />;
    case 'arrowLeft': return <PlayIcon className={className} />;
    case 'arrowRight': return <PlayIcon className={['rotate-180', className].join(' ')} />;
    case 'close': return <XCircleIcon className={className} />;
    case 'delete': return <TrashIcon className={className} />;
    case 'download': return <ArrowDownOnSquareIcon className={className} />;
    case 'edit': return <PencilIcon className={className} />;
    case 'email': return <EnvelopeIcon className={className} />;
    case 'help': return <QuestionMarkCircleIcon className={className} />;
    case 'key': return <KeyIcon className={className} />;
    case 'notifications': return <BellIcon className={className} />;
    case 'phone': return <PhoneIcon className={className} />; 
    case 'search': return <MagnifyingGlassIcon  className={className} />;
    case 'send': return <PaperAirplaneIcon className={className} />;
    case 'upload': return <ArrowUpOnSquareIcon className={className} />;
    case 'visibility': return <EyeIcon className={className} />;
    case 'visibilityOff': return <EyeSlashIcon className={className} />;
    case 'user': return <UserIcon className={className} />;
    case 'setting': return <AdjustmentsHorizontalIcon className={className} />;
    case 'creditCard': return <CreditCardIcon className={className} />;
    case 'globe': return <GlobeEuropeAfricaIcon className={className} />;
    case 'web' : return <GlobeAltIcon className={className} />
  }
}

interface IconProps {
  name: IconEnum;
  className?: string;
  showIf?: boolean; 
};

const Icon = ({ name, className, showIf = true,}: IconProps) => {

  return (
    showIf ?
    <div className={['h-full flex items-center', className].join(' ')}>
        {onIcon(name, className)}
    </div>
    : null );
};

export { Icon, type IconEnum};