import Quill from "quill";
import { Suspense, useEffect, useState } from "react";
import { createRoot } from "react-dom/client";
import { useTranslation } from "react-i18next";
import { toast } from 'react-toastify';
import { TypeLink } from "../../../shared/schema";
import { checkEmailFormat, checkPhoneFormat } from "../utils/checkFormat";
import { ButtonIcon, Icon, InputText, Modal, RadioList, TextImportant } from "../components";


const linkHandler = async(quill: Quill) => {
    const hasFormat = quill.getFormat().link ? true : false;
    const range = quill.getSelection();

    if (hasFormat && range && range.length > 0) return quill.format('link', undefined)
    
    if (range && range.length > 0) {
        const text = quill.getText(range.index, range.length).trim()
        const request = await linkRequest(text)

        const res = request !== 'cancel' ? quill.format('link', request) : null;
        quill.setSelection(range.index + text.length, 0)
        return res
    }
    //No selection -> error
    return linkRequest('', undefined, true)
}


const linkRequest = (text: string, type?: TypeLink,isError?: boolean) => {
    return new Promise<string | 'cancel'>(resolve => {
      const container = document.getElementById('modal-editor');
      const root = createRoot(container!);
  
      const valid = (value: string | 'cancel') => {
          resolve(value)
          root.unmount();
      }
    
      const close = () => { 
        console.log('close')
          resolve('cancel')
          root.unmount();
      }
  
      root.render(
        <Suspense>
          <LinkModal text={text} type={type} onValid={valid} onClose={close} isError={isError} />
        </Suspense>
      );
    })
}


type LinkModalProps = {
    text: string,
    type?: TypeLink,
    onValid: (v: string) => void,
    onClose: () => void
    isError?: boolean
}
  
const LinkModal = ({text, type, onValid, onClose, isError = false}: LinkModalProps) => {
    const { t } = useTranslation(['textEditor']);
    const [link, setLink] = useState<string>(text)
    const [typeLink, setTypeLink] = useState<TypeLink | undefined>()
    const [forceType, setForceType] = useState<boolean>(false)

    if (isError) {
        toast.warning(t('link.selectWarning') as string, {theme: 'colored'})
        setTimeout(() => onClose(), 100)
    }

    const typeLinks = [
        {name: 'url', label: t('link.url')},
        {name: 'mailto:', label: t('link.email')},
        {name: 'tel:', label: t('link.phone')},
    ];

    useEffect(() => {
        if (!isError && !forceType) {
            const l = link.toLowerCase()
            if (type) {
                setTypeLink(type)
                return setForceType(true)
            }
            if (l.startsWith('http')) return setTypeLink('url')
            if (checkPhoneFormat(l)) return setTypeLink('tel:')
            if (checkEmailFormat(l)) return setTypeLink('mailto:')
        }
    }, [forceType, link, setTypeLink]);

    return !isError ? (
      
        <Modal
            open={true}
            title={t('link.modal.create')}
            titleButtonValid={link !== '' && typeLink ? undefined : ''}
            onValid={() => onValid(typeLink === 'url' ? link : typeLink + link)}
            onClose={() => onClose()}
        >
            <InputText
                label={t('link.modal.link')}
                autoFocus={true}
                value={link}
                onChange={(e) => setLink(e.target.value)}
            />

            <TextImportant text={t('link.modal.typeLink')} />

            <RadioList name="typeLink"
                items={typeLinks}
                valueSelected={typeLinks.find(tl => tl.name === typeLink)}
                setValue={(t) => {
                    setTypeLink(t.name)
                    setForceType(true)
                }}
            />

        </Modal>
      
    ) : null
}


type LinkTooltipProps = {
    text: string;
    type: TypeLink
    onShow: () => void;
    onEdit: () => void
    onDelete: () => void
}
  
const LinkTooltip = ({text, type, onShow, onEdit, onDelete}: LinkTooltipProps) => {
    
    const linkShow = () => {
        let icon: JSX.Element | null = null
        switch (type) {
            case 'url':
                icon = Icon({name: 'phone', className: 'h-4 w-4' })
                break
            case 'mailto:': 
                icon = Icon({name: 'email', className: 'h-4 w-4' })
                break
            case 'tel:':
                icon = Icon({name: 'phone', className: 'h-4 w-4' })
                break
        }
  
        return {icon: icon, text: text}
    }
  
    return(
        <div className="h-[76px] w-[140px] absolute flex flex-col border rounded-lg shadow-sm backdrop-blur-sm z-10 p-1">
  
            <div className='w-full flex flex-row' >
                <div className="mr-1">{linkShow().icon}</div>
                <div className="truncate">{linkShow().text}</div>
            </div>
    
            <div className='w-full flex flex-row justify-between'>
                <ButtonIcon icon='visibility' iconClass="h-8 w-8" className="text-info m-1" onClick={onShow} />
                <ButtonIcon icon='edit' iconClass="h-8 w-8"  className="text-success m-1" onClick={onEdit} />
                <ButtonIcon icon='delete' iconClass="h-8 w-8" className="text-danger m-1" onClick={onDelete} />
            </div>
    
        </div>
    )
};


export { linkHandler, linkRequest, LinkTooltip }