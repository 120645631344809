import { useEffect, useState } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { MemberLabel, memberLabelList } from '../params';
import { RadioList, InputText, Radio, ContentCol, ContentWrap, ButtonAction} from '.';

type GetMemberName = {t: TFunction<'nav', undefined>, name: MemberLabel, plural?: boolean}
const getMemberName = ({t, name, plural = false}: GetMemberName) => {
    if (name.other) return plural ? name.plural : name.singular  
    return plural ? t(`memberName.${name.plural}`, {ns: 'nav'}) : t(`memberName.${name.singular}`, {ns: 'nav'}) 
};

type MemberNameList = {name: string, label: string}
  
type ChooseMemberNameProps = {
    memberName: MemberLabel;
    otherMemberName: MemberLabel | undefined;
    setMemberName: (v: MemberLabel) => void;
    setOtherMemberName: (v: MemberLabel) => void;
    showOther: boolean;
    setShowOther: (s: boolean) => void;
    saveSetting: () => void;
};
const ChooseMemberName = ({memberName, setMemberName, otherMemberName, setOtherMemberName, showOther, setShowOther, saveSetting}: ChooseMemberNameProps) => {
    const { t, i18n } = useTranslation(['nav', 'button']);
    const [name, setName] = useState<MemberNameList>(memberName.other ? {name: 'other', label: memberName.plural} : {name: memberName.plural, label: t(`memberName.${memberName.plural}`)});
    const [other, setOther] = useState<MemberLabel>(otherMemberName ? otherMemberName : {singular: '', plural: '', other: true});
    const [otherLabelLang, setOtherLabelLang] = useState<MemberNameList>({
        name: 'other',
        label: (otherMemberName && otherMemberName.plural !== '') ? otherMemberName.plural : t('memberName.other', {ns: 'nav'})
    })
    const lang = i18n.language.split('-')[0];

    const memberLabelLang: MemberNameList[] = [];
    memberLabelList.forEach(ml => {
        if (!(ml.exception && ml.exception.find(e => e.lang === lang))) memberLabelLang.push({name: ml.plural, label: t(`memberName.${ml.plural}`)});
    })
    
    useEffect(() => {
        if (name.name === 'other' && other.singular !== '' && other.plural !== '') return setMemberName(other)
        if (name.name !== 'other' && name.name !== memberName.plural) return setMemberName(memberLabelList.find(ml => ml.plural === name.name) as MemberLabel)
    }, [name, memberName, other, setMemberName])

    const onChangeOther = () => {
        const otherLang = {name: 'other', label: other && other.plural !== '' ? other.plural : t('memberName.other', {ns: 'nav'})}
        if (memberName.other && other.singular !== '' && other.plural !== '') {      
            setOtherMemberName(other);
            setMemberName(other);
            setName(otherLang)
            setOtherLabelLang(otherLang);
            saveSetting();
            return setShowOther(false);
        }
        if (!memberName.other) {
            setOtherMemberName(other);
            setOtherLabelLang(otherLang);
            return setShowOther(false);
        }
        toast.error(t('memberName.errorChangeOther') as string, {theme: 'colored'});
    }

    return (
        <ContentCol>
            
            <RadioList items={memberLabelLang} name='member' valueSelected={name} setValue={setName} col={true} />

            <ContentWrap>

                <Radio name='member' value={otherLabelLang} valueSelected={name} setValue={setName} />
        
                <ButtonAction action='modify'
                    showIf={!showOther}
                    className='ml-2'
                    onClick={() => setShowOther(true)}
                />
                
                <ButtonAction action='valid'
                    showIf={showOther}               
                    className='ml-2'
                    onClick={() => onChangeOther()}
                />
                
            </ContentWrap>

            <ContentCol showIf={showOther}>
                          
                <InputText
                    id='singular'
                    label={t('singular', {ns: 'siteSetting'})}
                    autoFocus={true}
                    value={other?.singular}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setOther({...other, singular: e.target.value})}
                />
            
                <InputText 
                    id='plural'
                    label={t('plural', {ns: 'siteSetting'})}
                    value={other?.plural} 
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setOther({...other, plural: e.target.value})}
                />

            </ContentCol>
            
        </ContentCol>
    );
};

export {ChooseMemberName, getMemberName};