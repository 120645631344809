import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { emailCheckFormat } from '../../utils';
import { ParseDate, UserPassword } from '../../../../shared/schema/index';
import { parseDate } from '../_object';
import { ButtonAction, Checkbox, ContentCol, InputPassword, InputText, Modal } from '../../components';

type FormPasswordProps = {
    user: UserPassword
    setUser: (u: UserPassword) => void;
    showRgpd: boolean;
    onValid: () => void;
};

const FormPassword = ({user, setUser, showRgpd, onValid}: FormPasswordProps) => {
    const { t } = useTranslation('auth');
    const [passwordConfirm, setPasswordConfirm] = useState('');
    const [rgpdValided, setRgpdValided] = useState<ParseDate | undefined>();
    
    const [openRgpdModal, setOpenRgpdModal] = useState(false);

    const checkForm = () => {
        if (user.username === '' && !emailCheckFormat(user.username.trim())) {
            return toast.error("Cette adresse email n'est pas conforme.", { theme: 'colored' });
        };
        if (user.password === '' || user.password !== passwordConfirm) {
            return toast.error("Les mots de passe ne correspondent pas", { theme: 'colored' });
        }
        if (showRgpd && !user.rgpdValided) {
            return toast.error("Vous devez confirmer les conditions d'utilisation", { theme: 'colored' });
        }
        onValid()
    }

    return (
        <form onSubmit={(event) => event.preventDefault()}>

          <ContentCol>
            <InputText id='username'
              label={t('formPassword.id')}
              value={user.username}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setUser({...user, username: e.target.value})}
              placeholder={t('formPassword.idPlaceholder')}
              autoComplete="on"
            />

            <InputPassword
              type='pass'
              value={user.username}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setUser({...user, password: e.target.value})}
            />

            <InputPassword
              type='confirm'
              value={passwordConfirm}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPasswordConfirm(e.target.value)}
            />
          </ContentCol>

          <div className='hidden'>
            <InputText id='attribute'
              label={t('formPassword.attribute')}
              value={user.attribute}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setUser({...user, attribute: e.target.value})}
              placeholder={t('formPassword.attribute')}
              autoComplete="on"
            />
          </div> 


          <Checkbox id='cgu' showIf={showRgpd}
            label={t('formPassword.rgpd.title')}
            checked={rgpdValided ? true : false}
            onChange={() => setOpenRgpdModal(true)}
            variant='info'
          />


            <ButtonAction action='valid'
              onClick={() => checkForm()}
            />

            {showRgpd && openRgpdModal &&
            <Modal
              variant='info'
              open={openRgpdModal}
              onClose={() => {
                  setRgpdValided(undefined);
                  setOpenRgpdModal(false);
              }}
              onValid={() => {
                  const date = parseDate()
                  setRgpdValided(date);
                  setOpenRgpdModal(false);
                  setUser({...user, rgpdValided: date})
              }}
              titleButtonValid={t('formPassword.rgpd.modalConfirm')}
              titleButtonCancel={t('formPassword.rgpd.modalNoconfirm')}
              title={t('formPassword.rgpd.modalTitle')}
            >
                Les conditions....
            </Modal>
            }

        </form>

    );
};

export default FormPassword;
