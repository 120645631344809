import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { User } from '../../../../shared/schema/index';
import { ButtonFooter, Card, CardTitle, ContentCol, InputText, InputAlignLeft } from '../../components';
import { toFirstUpperCase } from '../../utils';

export interface AccountEditProps {
    user: Partial<User>;
    updateUser: (u: Partial<User>) => void;
    onClose: () => void;
};

const EditName = ({user, updateUser, onClose}: AccountEditProps) => {
    const { t } = useTranslation('account');
    const [userName, setUserName] = useState<Partial<User>>({firstName : user.firstName, lastName: user.lastName})

    const saveUserName = () => {
        const u = userName;
        if (u.firstName === '' || u.lastName === '') {
            toast.error(t('form.accountName', {ns: 'error'}) as string, {theme: 'colored'});
            return
        }
        if (u.firstName === user.firstName && u.lastName === user.lastName) {
            return onClose()
        }

        if (u.firstName && u.lastName) updateUser({firstName: u.firstName, lastName: u.lastName})
    }

    return (
        <Card>

            <CardTitle text={t('editName.title')} icon='edit' />

            <ContentCol >

                <InputAlignLeft>
                    <InputText
                        id='firstName'
                        labelPosition='leftAlign'
                        label={t('editName.firstName')}
                        autoFocus={true}
                        value={userName.firstName}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => setUserName({...userName, firstName: toFirstUpperCase(event.target.value)})}
                    />
                
                    <InputText
                        id='lastName'
                        labelPosition='leftAlign'
                        label={t('editName.lastName')}
                        value={userName.lastName} 
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => setUserName({...userName, lastName: toFirstUpperCase(event.target.value)})}
                    />
                </InputAlignLeft>

                <ButtonFooter
                    action='save'
                    onValid={() => saveUserName()}
                    onCancel={onClose}
                    className='mr-4'
                />

            </ContentCol>
            
        </Card>
    );
};

export default EditName;