import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { InvitUser } from './InvitUser';
import { ListUser } from './ListUser';
import { AuthCtx } from '../_context/Context';
import { postFunctionsApi } from '../../utils';
import { UserRole } from '../../../../shared/schema/index';
import { ViewFooter, View, SubView, ButtonText } from '../../components';

type AdminUserNavView = 'list' | 'invit';

const AdminUserView = () => {
    const [auth] = useContext(AuthCtx);
    const { t } = useTranslation(['admin']);
    const [users, setUsers] = useState<UserRole[]>([]);
    const [userCustomerId, setUserCustomerId] = useState<string>();
  
    const [navView, setNavView] = useState<AdminUserNavView>('list');
    
    useEffect(() => {
        async function fetchData() { 
            const getRoles: any = await postFunctionsApi<any>('getUsersRoles', {siteId: auth.siteId});
            if (typeof getRoles !== 'string') {
                const roles = getRoles.usersRoles;
                const newListUser: UserRole[] = [];
                for (let i = 0; i < roles.length; i++) {
                    for (let j = 0; j < roles[i].users.length; j++) {
                        const user: UserRole = roles[i].users[j];
                        user.roleName = roles[i].roleName.split('_')[0];
                        newListUser.push(user);
                    }
                }
                setUsers(newListUser);
                setUserCustomerId(getRoles.userCustomerId);
            }
        }
        fetchData();      
    }, [auth]);

    const showRoles = () => {
        const roleList = []
            //if (auth.roleLabel === 'admin') roleList.push({name: 'admin', label: t('roleList.admin')})
            roleList.push({name: 'admin', label: t('roleList.admin')})
            roleList.push({name: 'collaborater', label: t('roleList.collaborater')});
            roleList.push({name: 'manager', label: t('roleList.manager')});
            if (auth.siteSetting.use.eventView) roleList.push({name: 'animator', label: t('roleList.animator')});
            //if () roleList.push({name: 'animGroups', label: t(roleList.animGroups)});
            if (auth.siteSetting.use.presenceView) roleList.push({name: 'agent', label: t('roleList.agent')});
            //if () roleList.push({name: 'member', label: t('roleList.member')});
        return roleList
    };

    const addUser = (user: UserRole) => {
        const newUsers = users;
        newUsers.push(user);
        setUsers(newUsers);
    }

    return (
        <>
            <View>

                <SubView showIf={navView === 'list'}>
                    <ListUser showRoles={showRoles} users={users} setUsers={setUsers} userCustomerId={userCustomerId} />
                </SubView>

                <SubView showIf={navView === 'invit'}>
                    <InvitUser showRoles={showRoles} addUser={addUser} />
                </SubView>    

            </View>     
            
            <ViewFooter>  
                
                <ButtonText showIf={navView !== 'list'}
                    variant="filled-primary"
                    text={t('listUser.nav')}
                    onClick={() => setNavView('list')}
                />
                
                <ButtonText showIf={navView !== 'invit'}
                    variant='filled-primary'
                    text={t('invitUser.nav')}
                    onClick={() => setNavView('invit')}
                />
                
            </ViewFooter> 

        </>

    );
};

export { AdminUserView };