import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Address, ParseGeoPoint, Place } from '../../../../../shared/schema/';
import { ButtonFooter, ContentCol, InputText, SubCard, ContentRow, Text, ButtonBase, TextEditorSimply } from '../../../components';
import { saveClassApi, toFirstUpperCase } from '../../../utils';
import { newAddress, parsePointer, newPlace, parseGeoPoint, objectWithoutParse } from '../../_object';
import { SearchAddress } from './SearchAddress';
import { toast } from 'react-toastify';


type PlaceEditCardProps = {
    place?: Place;
    onSave: (p: Place) => void
    onCancel: () => void
}

const PlaceEditCard = ({place, onSave, onCancel}: PlaceEditCardProps) => { 
    const { t } = useTranslation(['datasite']);
    const [name, setName] = useState<string>(place?.name ? place.name : '');
    const [address, setAddress] = useState<Address>(place?.address ? place.address : newAddress());
    const [coords, setCoords] = useState<{lat: string, lng: string}>({
        lat: address?.geoPoint?.latitude ? address.geoPoint.latitude.toString() : '',
        lng: address?.geoPoint?.longitude ? address.geoPoint.longitude.toString() : ''
    });
    const [text, setText] = useState<string>('')
    
    const [showSearch, setShowSearch] = useState<boolean>(place ? false : true);

    const checkValidPlace = () => {
        if (name !== '' &&
            address.geoPoint?.latitude !== null &&
            address.geoPoint?.longitude !== null &&
            address.city !== '' 
        ) return true

        return false
    }

    const changeGeopoint = (type: 'lat' | 'lng', value: string) => {
        const coord = parseFloat(value)
        if (!/^-?[0-9]?\d*[.,]?[0-9]?\d*$/.test(value) || (coord && (coord > 180 || coord < -180))) {
            return toast.error(t('place.errorGeoPoint') as string, {theme: 'colored'})
        } else {
            if (value.slice(-1) === '.' || value.slice(-1) === ',') value = coord ? coord + '.' : value
            const geoPoint = address.geoPoint ? address.geoPoint : parseGeoPoint(0, 0)
            if (type === 'lat') {
                geoPoint.latitude = coord ? coord : 0
                setCoords({...coords, lat: value})
            } else {
                geoPoint.longitude = coord ? coord : 0
                setCoords({...coords, lng: value})
            }
            setAddress({...address, geoPoint: geoPoint})
        }
    }

    const saveAddress = (newAddress: Address) => {
        setAddress(newAddress)
        setCoords({lat: (newAddress.geoPoint as ParseGeoPoint).latitude.toString(), lng: (newAddress.geoPoint as ParseGeoPoint).longitude.toString() })
        setShowSearch(false)
    }

    const handleChange = (content: any, delta: any, source: any, editor: any) => {
        setText(editor);
    }

    const onSavePlace = async() => {
        //TODO Optmise code
        let newAddress = undefined
        const savePlace: Place = place ? place : newPlace(name)


        if (JSON.stringify(place?.address) !== JSON.stringify(address)) {
            const savedAddress = await saveClassApi('Address', objectWithoutParse(address), place?.address?.objectId)
            if (typeof savedAddress === 'string') return toast.error(t('errorRetry') as string, {theme: 'colored'});
            newAddress = (!place?.address?.objectId && savedAddress.objectId) ?  parsePointer('Address', savedAddress.objectId) : undefined
        }
        
        if (!place?.address?.objectId && newAddress) {
            savePlace.address = newAddress
        } else {
            savePlace.address = undefined
        }

        if (place?.name !== name) {
            savePlace.name = name
        }

        if (place?.text !== text) {
            savePlace.text = text
        }

        if (place?.objectId) savePlace.site = undefined
        const savedPlace = await saveClassApi('Place', objectWithoutParse(savePlace), place?.objectId)
        if (typeof savedPlace !== 'string') {
            savePlace.address = address
            return onSave(savePlace)
        }
        
        toast.error(t('errorRetry') as string, {theme: 'colored'})
    }

    return (
        <>
        {showSearch &&
            <SearchAddress
                address={address}
                onValid={saveAddress}
                onCancel={() => setShowSearch(false)}
            />
        }


        {!showSearch &&
        <SubCard>

            <ContentCol>

                <InputText placeholder={t('place.name')}
                    label={t('place.name')}
                    labelPosition='left'
                    value={name}
                    onChange={(e) => setName(toFirstUpperCase(e.target.value))}
                    autoFocus containerClass='font-semibold bg-white'
                    isRequired={true}
                />

                <Text textClass='text-base font-medium pl-2 mr-2' text={t('place.address.address')} />
                <SubCard>

                    <ButtonBase
                        onClick={() => setShowSearch(true)}
                        variant='filled-success-lg'
                        buttonClass='mb-2'
                    >
                        {t('place.address.searchAddressCoords')}
                    </ButtonBase>

                    <Text textClass='text-base font-medium pl-2 mr-2' text={t('place.address.coords')} />
                    <ContentRow>

                        <ContentRow className='mb-0'>
                            <InputText placeholder={t('place.address.latitude')}
                                value={coords.lat}
                                onChange={(e) => changeGeopoint('lat', e.target.value)}
                                containerClass='mr-1 w-full font-semibold bg-white'
                                inputClass='truncate'
                                isRequired={true}
                            />

                            <InputText placeholder={t('place.address.longitude')}
                                value={coords.lng}
                                onChange={(e) => changeGeopoint('lng', e.target.value)}
                                containerClass='w-full font-semibold bg-white'
                           
                                inputClass='truncate'
                                isRequired={true}
                            />
                        </ContentRow>

                    </ContentRow>

                    <InputText placeholder={t('place.address.street')}
                        label={t('place.address.street')}
                        labelPosition='left'
                        value={address.street}
                        onChange={(e) => setAddress({...address, street: e.target.value})}
                        containerClass='font-semibold bg-white'
                    />

                    <InputText placeholder={t('place.address.addStreet')}
                        label={t('place.address.addStreet')}
                        labelPosition='left'
                        value={address.addStreet}
                        onChange={(e) => setAddress({...address, addStreet: e.target.value})}
                        containerClass='font-semibold bg-white'
                    />

                    <InputText placeholder={t('place.address.city')}
                        label={t('place.address.city')}
                        labelPosition='left'
                        value={address.city}
                        onChange={(e) => setAddress({...address, city: toFirstUpperCase(e.target.value)})}
                        containerClass='font-semibold bg-white'
                        isRequired={true}
                    />

                    <InputText placeholder={t('place.address.zipCode')}
                        label={t('place.address.zipCode')}
                        labelPosition='left'
                        value={address.zipCode}
                        onChange={(e) => setAddress({...address, zipCode: e.target.value})}
                        containerClass='font-semibold bg-white'
                    />

                    <InputText placeholder={t('place.address.state')}
                        label={t('place.address.state')}
                        labelPosition='left'
                        value={address.state}
                        onChange={(e) => setAddress({...address, state: toFirstUpperCase(e.target.value)})}
                        containerClass='font-semibold bg-white'
                    />

                    <InputText placeholder={t('place.address.country')}
                        label={t('place.address.country')}
                        labelPosition='left'
                        value={address.country}
                        onChange={(e) => setAddress({...address, country: toFirstUpperCase(e.target.value)})}
                        containerClass='font-semibold bg-white'
                    />

                </SubCard>

                <Text textClass='text-base font-medium pl-2 mr-2' text={t('place.dataText')} />
                <SubCard>

                    <TextEditorSimply
                        value={text}
                        onChange={handleChange}
                        className='h-47'
                    />

                </SubCard>


            </ContentCol>

            <ButtonFooter action='save'
                ifAction={checkValidPlace()}
                onValid={() => onSavePlace()}
                onCancel={() => onCancel()}
            />


        </SubCard>
        }
    </>
    );
};


export { PlaceEditCard };