import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { View, SubView, ButtonText, ViewFooter } from '../../components';
import { MemberList } from './List/MemberList';
import { FileMember } from './File/FileMember';


const MemberView = () => {
    const { t } = useTranslation(['member', 'nav']);
    const [navView, setNavView] = useState<'list' | 'file'>('list');  

    return (
        <>
            <View>

                <SubView showIf={navView === 'list'}>
                    <MemberList />
                </SubView>

                <SubView showIf={navView === 'file'}>
                    <FileMember />
                </SubView>
   

            </View>     
            
            <ViewFooter>  
                
                <ButtonText showIf={navView !== 'list'}
                    variant="filled-primary"
                    text={t('list.nav')}
                    onClick={() => setNavView('list')}
                />
                
                <ButtonText showIf={navView !== 'file'}
                    variant='filled-primary'
                    text={t('file.nav')}
                    onClick={() => setNavView('file')}
                />
                
            </ViewFooter> 

        </>
    );
};

export { MemberView };