import { useState } from "react";

type UseLocalStorage = [
  storedValue: any,
  setValue: (v: any) => void
]
 
const useLocalStorage = (key: string, initialValue?: string): UseLocalStorage => { 
  const [storedValue, setStoredValue] = useState(() => {
    if (typeof window === "undefined") { return initialValue }
    try {
      const item = window.localStorage.getItem(key);
      return item ? JSON.parse(item) : initialValue;
    }
    catch (error) { return initialValue;}
  });

  const setValue = (value: any) => {
    try {
      const valueToStore = value instanceof Function ? value(storedValue) : value;
      setStoredValue(valueToStore);
      if (typeof window !== "undefined") { window.localStorage.setItem(key, JSON.stringify(valueToStore))}
    }
    catch (error) { console.log(error) }
  };

  return [storedValue, setValue];
};

export {useLocalStorage};
