import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ContactPi } from '../../../../shared/schema/index';
import { ButtonIcon, Card, Content, TextEditorSimply, ModalConfirm, Text, Title, LiActions, Li, ListMap, LiHeader, SubCard, ContentCol, ContentWrap, ButtonAction, TextEditorReader } from '../../components';
import { saveClassApi } from '../../utils';

type ContactPiListProps = {
    requests: ContactPi[]
    setRequests: (c: ContactPi[]) => void
}

const ContactPiList = ({requests, setRequests}: ContactPiListProps) => {
    const { t } = useTranslation(['contactpi', 'button']);

    const [showMessageNb, setShowMessageNb] = useState<string | undefined>();
    const [modalRequestDelete, setModalRequestDelete] = useState<ContactPi | boolean>();

    const showSubject = (request: ContactPi): string => {
        if (request.reason === 'survey') return t('request.surveytable', {note : request.note})
        if (request.reason === 'suggestion') return t('request.suggestion');
        return request.subject;
    }

    const deleteRequest = async(request: ContactPi) => {
        const deleteRequest = await saveClassApi('ContactPi', {show: false}, request.objectId as string)
        if (typeof deleteRequest !== 'string') {
            setRequests(requests.filter(r => r.objectId !== request.objectId));
            setModalRequestDelete(undefined);
        } else {
            //TODO
        }
    }

    return (        

        <Card>
            
            {requests.map((request) => (
            <SubCard key={request.objectId} >

                <Content className='flex justify-between'>

                    <ContentCol className='text-lg'>

                        <Title containerClass='truncate' text={showSubject(request)}/>

                        <ContentWrap>

                            <Text containerClass='text-left' text={new Date(request.createdAt as string).toLocaleDateString()}/>
                        

                        </ContentWrap>

                    </ContentCol>

                    <Content className='w-8'>

                        <ButtonIcon
                            icon={showMessageNb === request.objectId ? 'visibilityOff' : 'visibility'  }
                            className='h-8 w-8 flex justify-center items-center rounded-full bg-primary text-secondary mb-2'
                            iconClass='w-5 h-5'
                            onClick={() => showMessageNb === request.objectId ? setShowMessageNb(undefined) : setShowMessageNb(request.objectId)}
                        />

                        <ButtonIcon
                            icon='delete'
                            className='h-8 w-8 flex justify-center items-center rounded-full bg-danger text-white'
                            iconClass='w-5 h-5'
                            onClick={() => setModalRequestDelete(request)}
                        />
                        
                    </Content>

                </Content>

                {showMessageNb === request.objectId &&
                <SubCard>
                    <TextEditorReader value={request.message as string} className='h-fit'/>
                </SubCard>
                }

            </SubCard>
            ))}

            <ModalConfirm
                data={modalRequestDelete}
                onClose={() => setModalRequestDelete(undefined)}
                onValid={(request) => deleteRequest(request)}
            />

        </Card>
            

    );
};

export { ContactPiList };
