import { useEffect, useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import FormPassword from './FormPassword';
import { postFunctionsApi } from '../../utils';
import { UserPassword } from '../../../../shared/schema/index';
import { userPasswordObject } from '../_object';
import { LANG_LIST } from '../../params';


const RegisterView = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation(['auth', 'nav']);
  const [user, setUser] = useState<UserPassword>(userPasswordObject());
  const [step, setStep] = useState(1);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const username = params.get('username')
    const token = params.get('token')
    if (username && token && user.username ==='') setUser({...user, username: username, token: token})
  }, [user, location])

  const register = async () => {
    if (user.attribute !== 'Y') return navigate('/');
    window.localStorage.removeItem('t');
    const language = i18n.language.split('-')[0];
    const lang = LANG_LIST.find(l => l.name === language);
    const newUser = user;
    newUser.userSetting = {language: lang ? lang.name : 'en' }

    const register: any = await postFunctionsApi('register', newUser, {forceInstance: true});
    if (typeof register !== 'string') return setStep(2) 

    if (register === 'error register') return toast.error(t('register.error.email') as string, { theme: 'colored' });
    
    toast.error(t('register.error.retry') as string, { theme: 'colored' });
  };


  return (
    <div className="w-full h-full bg-piPrimary">
      <div className="w-full h-full flex flex-col items-center bg-login-pattern bg-cover bg-no-repeat overflow-auto text-white">
        <div className="w-80 sm:w-96 flex justify-center bg-primary/80 flex-col px-1 my-12">
          <header className="w-80 sm:w-96 mb-10 pr-4 pt-4">
            <h1 className='text-center text-2xl mt-2'>
              {t('siteName')}
            </h1>
            <h2 className='text-center text-xl italic mt-4'>
                {t('register.title')}
            </h2>
          </header>
          {step === 1 &&
            <FormPassword user={user} setUser={setUser} showRgpd={true} onValid={register} />
          }

          {step === 2 &&
          <div className='flex flex-col justify-center text-center text-lg'>
            <div className='my-2'>{t('register.step2Ddescription1')}</div>
            <div className='my-2'>{t('register.step2Ddescription2')} {user.username}.</div>
            <div className='my-2 font-bold'>{t('register.step2Ddescription3')}</div>
          </div>
          }

          <div className="mt-6 flex justify-center">
            <Link to={t('home.path', {ns: 'nav'})} className="text-white font-bold text-lg transition duration-300 hover:opacity-80">
              {t('backHome', {ns: ['auth']})}
            </Link>
          </div>

        </div>

      </div>
                        
    </div>

  );
};

export default RegisterView;
