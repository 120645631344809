import { useRef } from "react";
import { HexColorPicker } from "react-colorful";
import { colorListDefault } from '../params'
import { ButtonBase } from "./Button";


type ColorPickerType = {
  color: string;
  onChange: (c: string) => void;
  className?: string;
}

const ColorPicker = ({ color, onChange, className }: ColorPickerType) => {
  const popover = useRef<HTMLDivElement>(null)

  return (
    <div className="picker w-full flex justify-center">

      <div className="mt-2" ref={popover}>
        <HexColorPicker color={color} onChange={onChange} />
        <div className="picker__swatches">
          {colorListDefault.map((presetColor) => (
            <ButtonBase
              key={presetColor}
              className="picker__swatch"
              style={{ backgroundColor: presetColor }}
              onClick={() => onChange(presetColor)}
            />
          ))}
        </div>
      </div>
      
    </div>
  )
};

export default ColorPicker;
