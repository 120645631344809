import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { cardExp, postFunctionsApi } from '../../utils';
import { ParseDate, SiteSub } from '../../../../shared/schema/index';
import { ButtonText, ButtonIcon, Card, ContentCenter, ContentCol, CardTitle, TextImportant, Text, ModalConfirm, ContentWrap } from '../../components';
import { AddPiProductModal } from './AddPiProductModal';
import { StopPlanModal } from './StopPlanModal';

type SiteAdminProps = {
    siteSubs: SiteSub[];
    setSiteSubs: (s: SiteSub[]) => void;
};

const SiteAdmin = ({siteSubs, setSiteSubs}: SiteAdminProps) => {
    const { t } = useTranslation(['admin', 'nav']);

    const [siteSubSeleted, setSiteSubSeleted] = useState<SiteSub>(siteSubs[0]);
    const [statePlan, setStatePlan] = useState<'free' | 'sub' | 'noSub'>();
    const [stateStopPlan, setStateStopPlan] = useState<'stop' | 'cancel'>(siteSubSeleted.planEndAt ? 'cancel' : 'stop');
    const [productSelected, setProductSelected] = useState<'plan' | 'email' | 'extend'>('plan');
    const [stateCard, setStateCard] = useState< 'ok' | 'expiring' | 'expired' | 'noCard'>(cardExp(siteSubSeleted.piCustomer?.paymentMethod))

    const [modalProduct, setModalProduct] = useState<boolean>(false);
    const [modalStopPlan, setModalStopPlan] = useState<boolean>(false);
    const [modalChangePlanCancel , setModalChangePlanCancel] = useState<boolean>(false);

    useEffect(() => {
        
        setStateCard(cardExp(siteSubSeleted.piCustomer?.paymentMethod))

        setStateStopPlan(siteSubSeleted.planEndAt ? 'cancel' : 'stop')
        
        if (siteSubSeleted.deletionAt) return setStatePlan('noSub');
        if (siteSubSeleted.plan?.type === 'free') return setStatePlan('free');
        return setStatePlan('sub');

    }, [siteSubSeleted]);

    const titleSite = (sub: SiteSub): string => {
        const customer = sub.piCustomer;
        if (customer) {
            if (customer.society) return customer.society.name + ' (' + t('siteAdmin.structure') + ')';
            if (customer.user) return (customer.user).firstName + ' ' + (customer.user).lastName + ' (' + t('siteAdmin.individual') + ')';
        } 
        return ''
    };

    const saveSubscriptions = (newSub: SiteSub) => {
        const newSubscriptions = siteSubs;
        const index = siteSubs.findIndex(s => s.objectId === newSub.objectId);
        newSubscriptions[index] = newSub
        setSiteSubs(newSubscriptions)
    };

    const planChangeCancel = async() => {
        const addProduct = await postFunctionsApi<{canceled: boolean}>('changePlanCancel', {siteId: siteSubSeleted.site?.objectId});
        if (typeof addProduct !== 'string') {
            siteSubSeleted.planChange = undefined;
            siteSubSeleted.planChangeAt = undefined;
            siteSubSeleted.planEndAt = undefined;
            setStateStopPlan('stop')
            setModalChangePlanCancel(false);
        }
    }

    //AddSite TODO


    return (
        <ContentCol >

            {siteSubs.map(sub => 
            <Card key={sub.objectId}>

                <CardTitle
                    title={t('siteAdmin.title')}
                    text={sub.site?.name}
                >
                    <ButtonIcon icon='visibility' showIf={sub.objectId !== siteSubSeleted.objectId}
                        onClick={() => setSiteSubSeleted(sub)}
                        iconClass="text-info ml-4"
                    />
                </CardTitle>

                <ContentCol showIf={sub.objectId === siteSubSeleted.objectId}>

                    <CardTitle levelTitle={2}
                        title={t('siteAdmin.account')}
                        text={titleSite(sub)}
                    />

                    <TextImportant showIf={stateCard === 'noCard'}
                        text={t('siteAdmin.noCard')}
                        textClass='text-danger'
                    />

                    <TextImportant showIf={stateCard === 'expired'}
                        text={t('siteAdmin.expiredCard')}
                        textClass='text-danger'
                    />

                    <TextImportant showIf={stateCard === 'expiring'}
                        text={t('siteAdmin.experinfCard')}
                        textClass='text-warning'
                    />

                    <CardTitle levelTitle={2}
                        title={t('siteAdmin.plan')}
                        text={t('piPay.label', { context: sub.plan?.name })}
                    />

                    <TextImportant showIf={statePlan === 'sub'}
                        text={t('piPay.priceLabel', {price: sub.plan?.price, currency: sub.plan?.price.currency, duration: sub.plan?.option.duration})}
                    />
                    
                    <TextImportant showIf={statePlan === 'sub' && sub.planEndAt === undefined}
                        text={t('siteAdmin.billAt', {context: sub.billMonth ? 'month' : 'day' ,day: sub.billDay, month: sub.billMonth})}
                    />
                    
                    {sub.planEndAt &&
                    <>
                    <TextImportant showIf={statePlan !== 'noSub' && sub.planChange === undefined}
                        text={t('siteAdmin.planEndAt', {
                            date: new Date((sub.planEndAt as string)), formatParams: {date: { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }}
                        })}
                        textClass='text-warning'
                    />

                    <TextImportant showIf={statePlan === 'sub' && sub.planChange !== undefined}
                        text={t('siteAdmin.planChange', {
                            plan: sub.planChange,
                            date: new Date((sub.planEndAt as string)), formatParams: {date: { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }}
                        })}
                        textClass='text-warning'
                    />
                    </>
                    }

                    <CardTitle levelTitle={2} showIf={statePlan === 'noSub'}
                        title={t('siteAdmin.extendAt', {date: sub.extendAt?.iso})}
                    >

                    </CardTitle>

                    <ContentWrap>

                        <ButtonText showIf={sub.planChange === undefined}
                            text={statePlan !== 'sub' ? t('siteAdmin.purchasePlan') :  t('siteAdmin.changePlan')}
                            onClick={() => {setProductSelected('plan'); setModalProduct(true)}}
                            variant="filled-primary-lg" className='w-fit mt-2 mr-4'
                        />

                        <ButtonText showIf={sub.planChange !== undefined}
                            text={t('siteAdmin.changePlanCancel')}
                            onClick={() => setModalChangePlanCancel(true)}
                            variant="filled-primary-lg" className='w-fit mt-2 mr-4'
                        />

                        <ButtonText showIf={statePlan === 'sub' && sub.planChange === undefined}
                            text={t(stateStopPlan === 'stop' ? 'siteAdmin.stopPlan' : 'siteAdmin.stopPlanCancel')}
                            onClick={() => setModalStopPlan(true)}
                            variant='filled-neutre-lg' className="w-fit mt-2 mr-4"
                        />

                        <ButtonText showIf={statePlan === 'noSub'}
                            text={t('siteAdmin.extendData')}
                            onClick={() => {setProductSelected('extend'); setModalProduct(true)}}
                            variant="filled-primary-lg" className='w-fit mt-2 mr-4'
                        />

                    </ContentWrap>


                    <CardTitle levelTitle={2} title={t('siteAdmin.email')} />

                    <TextImportant showIf={statePlan !== 'noSub'} text={t('siteAdmin.emailConsume', {email: sub.emailConsume ? sub.emailConsume : 0})}/>

                    <TextImportant showIf={statePlan !== 'noSub'} text={t('siteAdmin.emailStock', {email: sub.emailStock ? sub.emailStock : 0})} />

                    <TextImportant showIf={statePlan !== 'free'} text={t('siteAdmin.emailPurchase', {email: sub.emailPurchase ? sub.emailPurchase : 0})} />
                    
                    {sub.emailPurchasedAt &&
                    <Text showIf={sub.emailPurchase !== undefined && sub.emailPurchase > 0}
                        text={t('siteAdmin.emailPurchaseAt', {
                            date: new Date((sub.emailPurchasedAt as string)), formatParams: {date: { year: 'numeric', month: 'short', day: 'numeric' }}
                        })}
                        textClass='italic'
                    />
                    }

                    <ButtonText showIf={statePlan === 'sub'}
                        text={t('siteAdmin.emailPurchase')}
                        onClick={() => {setProductSelected('email'); setModalProduct(true)}}
                        variant="filled-primary-lg" className='w-fit'
                    />

                </ContentCol>
                         
            </Card>
            )}

            <Card showIf={false} className="bg-opacity-70 hover:bg-opacity-100 mt-4">

                <ContentCenter>

                    <ButtonIcon icon='more' iconClass='h-8 w-8 opacity-80' className='flex-col hover:text-success' iconLeft={true}>
                        {t('siteAdmin.addSiteTitle')}
                    </ButtonIcon>

                </ContentCenter>

            </Card>

            <AddPiProductModal
                siteSub={siteSubSeleted}
                productSelected={productSelected}
                setSiteSub={saveSubscriptions}
                open={modalProduct}
                onClose={() => setModalProduct(false)}
            />

            <StopPlanModal
                siteSub={siteSubSeleted}
                open={modalStopPlan}
                state={stateStopPlan}
                setState={setStateStopPlan}
                onClose={() => {
                    setModalStopPlan(false)
                }}
            />

            <ModalConfirm
                open={modalChangePlanCancel}
                onValid={planChangeCancel}
                onClose={() => setModalChangePlanCancel(false)}
            />

        </ContentCol>

    );
};

export { SiteAdmin };