import { useContext, useEffect, useMemo, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Header } from '.';
import { HelpView } from '../Help/HelpView';
import { AuthCtx, HelpCtx, NavCtx } from '../_context/Context';
import { b64toBlob, postFunctionsApi, useLocalStorage } from '../../utils';
import { Help, Nav, Permission } from '../../../../shared/schema/index';
import { navObject } from '../_object';
import { AuthNavView } from './ProtectedView';

type navK = {k: string, permission: string | boolean}
const navKey: navK[] = [
    {k: "d0", permission: true},
    {k: "w1", permission: 'presenceView'},
    {k: "w2", permission: 'eventView'},
    {k: "w3", permission: 'emailingView'},
    {k: "w4", permission: 'memberView'},
    {k: "w5", permission: 'dataSiteView'},
    {k: "u1", permission: true},
    {k: "u2", permission: 'none'},
    {k: "u3", permission: 'adminSubView'},
    {k: "u4", permission: 'adminUsersView'},
    {k: "u5", permission: 'adminConfigView'},
    {k: "u6", permission: true},
    {k: "u7", permission: 'galleryView'}
]; //selectRole

type ProtectedNavProps = {
    selectRole: (s: AuthNavView) => void;
};

const ProtectedNav = ({selectRole}: ProtectedNavProps) => {
    const [auth, setAuth] = useContext(AuthCtx);
    const { t } = useTranslation('nav');
    const navigate = useNavigate();
    const location = useLocation();

    const [navStore ,setNavStore] = useLocalStorage('n');
    const [,setLocationSearchStore] = useLocalStorage('ls');

    function initNav() {
        const menuPath = navKey.find(n => t('path', {context: n.k}) === location.pathname)?.k;
        if (location.search) setLocationSearchStore(location.search)
        if (location.pathname === '/' && navStore) return navStore;
        if (location.pathname !== '/' && menuPath) { 
            return menuPath === navStore.k ? navStore : navObject({k: menuPath})
        }
        return navObject({})
    }

    const [nav, setNav] = useState<Nav>(initNav);
    const [help, setHelp] = useState<Help | undefined>();

    const createMenu = useMemo(() => {
        let menuKey: string[] = [];
        const permission: Permission = auth.permission;
        for (let i = 0; i < navKey.length; i++) {
            let keyPermission = navKey[i].permission
            if (typeof keyPermission === 'string') keyPermission = permission[keyPermission]
            if (keyPermission || navKey[i].k === "u2" ) //&& auth.siteNb > 1) 
            menuKey.push(navKey[i].k) 
        }
        return menuKey;
    },[auth]);
    const [menu] = useState<string[]>(createMenu);

    useEffect(() => {
        const menuSelected = menu.find(mk => mk === nav.k);
        if (menuSelected) {
            if (nav?.b?.k !== menuSelected && menuSelected[0] === 'w') setNav({...nav, b: nav});
            navigate(t('path', {context: menuSelected}));
        } else { navigate('/') }
    },[t, menu, nav, navigate, setNav]);

    useEffect(() => {
        setNavStore(nav);
    },[nav, setNavStore]);

    useEffect(() => {
        async function fetchImage() {
            const data  = await postFunctionsApi<string>('getBgSite', {siteId: auth.siteId})
      
            if (data !== 'error') {
              const blob = b64toBlob(data);
              const blobUrl = URL.createObjectURL(blob);

              setAuth({...auth, siteBg: blobUrl});
            }
          }
          if (!auth.siteBg) fetchImage()
    },[auth, setAuth, auth.siteBg]);

    return (
        <NavCtx.Provider value={[nav, setNav]} >
            <HelpCtx.Provider value={{help, setHelp}} >
       
                <Header
                    menu={menu} selectRole={selectRole}
                />

                <div className='w-full fixed top-16 bottom-0'>

                    <HelpView  />
                    
                    <main 
                        className={[
                            'w-full relative',
                            auth.siteBg ? 'bg-cover bg-center' : 'bg-primary bg-opacity-10',
                            help ? 'h-1/2' : 'h-full'
                        ].join(' ')}
                        style={auth.siteBg ? {backgroundImage: `url(${auth.siteBg})`} : {}}
                        id={auth.siteBg ? auth.siteName : ''}
                    >
                        <Outlet />
                    </main>
                    
                </div>

            </HelpCtx.Provider>
        </NavCtx.Provider>
    );
};

export { ProtectedNav};
