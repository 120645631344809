import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Address, NominatimAddress } from '../../../../../shared/schema/index';
import { ButtonFooter, ContentCol, InputBase, SubCard, LeafletMap, ContentRow, ButtonIcon, TextBase } from '../../../components';
import { postFunctionsApi } from '../../../utils';
import { addressToText, nominatimAddress } from '../../_object/Address';
import { parseGeoPoint } from '../../_object/Parse';
import { toast } from 'react-toastify';


type MemberCardProps = {
    address?: Address;
    onValid: (a: Address) => void;
    onCancel: () => void
}

const SearchAddress = ({address, onValid, onCancel}: MemberCardProps) => { 
    const { t } = useTranslation(['datasite']);

    const [newAddress, setNewAddress] = useState<Address>();
    const [coords, setCoords] = useState<{lat: number, lng: number}>({lat: 0, lng: 0})

    const [searchAdress, setSearchAddress] = useState<string>('');
    const [searchCoords, setSearchCoords] = useState<{lat: string, lng: string}>({lat: '', lng: ''});
    
    const [zoomMap, setZoomMap] = useState<number>(5)
    const [boundingbox, setBoundingbox] = useState<number[]>([])

    const findCoords = async(address: string) => {
        const getAddress = await postFunctionsApi<{lat: string, lng: string, display_name: string, boundingbox: number[]}>('searchAddress', {address: address})
        if (typeof getAddress !== 'string') return getAddress
        toast.error(t('place.errorSearch') as string, {theme: 'colored'})
    }

    const findAddress = async(lat: number, lng: number) => {
        const getCoords = await postFunctionsApi<{address: NominatimAddress, boundingbox: number[]}>('searchCoords', {lat: lat, lng: lng})
        if (typeof getCoords !== 'string') return getCoords
        toast.error(t('place.errorSearch') as string, {theme: 'colored'})
    }

    useEffect(() => {
        async function fetchCoords(address?: Address) {
            if (address && (address.street || address.city || address.state || address.country)) {
                const addressText = addressToText(address)
                const addressCoords = await postFunctionsApi<{lat: string, lng: string, display_name: string, boundingbox: number[]}>('searchAddress', {address: addressText})
                if (addressCoords && typeof addressCoords !== 'string') {
                    setSearchAddress(addressText)
                    setSearchCoords({lat: addressCoords.lat, lng: addressCoords.lng})
                    setCoords({lat: parseFloat(addressCoords.lat), lng: parseFloat(addressCoords.lng)})
                    setZoomMap(16)
                    return
                }
                toast.error(t('place.errorSearch') as string, {theme: 'colored'})
            }
            const lang = navigator.languages[0].split('-')
            const countryCoords = await postFunctionsApi<{lat: string, lng: string, display_name: string, boundingbox: number[]}>('searchAddress', {address: lang[1]})
            if (countryCoords && typeof countryCoords !== 'string') {
                setSearchCoords({lat: countryCoords.lat, lng: countryCoords.lng})
                setCoords({lat: parseFloat(countryCoords.lat), lng: parseFloat(countryCoords.lng)})
                setSearchAddress(countryCoords.display_name)
                if (countryCoords.display_name.toLowerCase() === 'france') {
                    setBoundingbox([41.2611155, 51.3055721, -5.4517733, 9.8282225])
                } else if (countryCoords.display_name.toLowerCase() === 'united states') {
                    setBoundingbox([26.0000002,76.0000002,-134,-84])
                } else {
                    setBoundingbox(countryCoords.boundingbox)
                }
                return
            }
            toast.error(t('errorRetry') as string, {theme: 'colored'})
        }

        if (address && address.geoPoint) {
            setSearchAddress(addressToText(address))
            setSearchCoords({lat: address.geoPoint.latitude.toString(), lng: address.geoPoint.longitude.toString()})
            setCoords({lat: address.geoPoint.latitude, lng: address.geoPoint.longitude})
            setZoomMap(16)
            return
        }

        fetchCoords(address)
    },[address])

    const changeGeopoint = (type: 'lat' | 'lng', value: string) => {
        const coord = parseFloat(value)
        if (!/^-?[0-9]?\d*[.,]?[0-9]?\d*$/.test(value) || (coord && (coord > 180 || coord < -180))) {
            return toast.error(t('place.errorGeoPoint') as string, {theme: 'colored'})
        } else {
            if (value.slice(-1) === '.' || value.slice(-1) === ',') value = coord ? coord + '.' : value
            type === 'lat'
            ? setSearchCoords({...searchCoords, lat: value})
            : setSearchCoords({...searchCoords, lng: value})
        }
    }

    const showSearchAddress = async() => {
        if (searchAdress !== '') {
            const getCoords = await findCoords(searchAdress)
            if (getCoords) {
                setSearchCoords(getCoords)
                setBoundingbox(getCoords.boundingbox)
                setNewAddress(undefined);
                setCoords({lat: parseFloat(getCoords.lat), lng: parseFloat(getCoords.lng)})
            }
        }
    }

    const showSearchCoords = async() => {
        if (searchCoords.lat !== '' && searchCoords.lng !== '') {
            const getAddress = await findAddress(parseFloat(searchCoords.lat), parseFloat(searchCoords.lng))
            if (getAddress) {
                const nominatim = nominatimAddress(getAddress.address);
                setSearchAddress(addressToText(nominatim));
                setNewAddress(nominatim);
                setBoundingbox(getAddress.boundingbox)
                setCoords({lat: parseFloat(searchCoords.lat), lng: parseFloat(searchCoords.lng)})
            }
        }
    }

    const showSearchMap = async(lat: number, lng: number) => {
        setSearchCoords({lat: lat.toString(), lng: lng.toString()})
        const getAddress = await findAddress(lat, lng)
        if (getAddress) {
            const nominatim = nominatimAddress(getAddress.address);
            setSearchAddress(addressToText(nominatim));
            setNewAddress(nominatim);
            setBoundingbox([])
            setCoords({lat: lat, lng: lng})
        }
    }

    const checkValidAddress = () => {
        return searchCoords.lat !== '' && searchCoords.lng !== '' ? true : false
    }

    const valid = async() => {
        if (searchCoords.lat !== '' && searchCoords.lng !== '') {
            let address = newAddress
            if (!newAddress) {
                const getAddress = await findAddress(parseFloat(searchCoords.lat), parseFloat(searchCoords.lng))
                if (getAddress) {
                    const nominatim = nominatimAddress(getAddress.address);
                    address = nominatim
                } else return 'error'
            }
            if (address) {
                address.geoPoint = parseGeoPoint(parseFloat(searchCoords.lat), parseFloat(searchCoords.lng))
                return onValid(address) 
            }
        }
        toast.error(t('place.errorNoCoords') as string, {theme: 'colored'})
    }

    return (
        <SubCard>
            <ContentCol>

                <ContentRow>

                    <InputBase placeholder={t('place.address.searchText')}
                        type='search'
                        value={searchAdress}
                        onChange={(e) => setSearchAddress(e.target.value)}
                        inputContainerClass='font-semibold bg-white mr-1'
                        onKeyDown={(e) => e.key === 'Enter' ? showSearchAddress() : null}
                    />

                    <ButtonIcon
                        icon='search'
                        className='w-9 h-9  bg-success text-white rounded-lg justify-center'
                        iconClass='w-6 h-6'
                        onClick={() => showSearchAddress()}
                    />

                </ContentRow>

                <ContentRow>

                    <ContentRow>
                        <InputBase placeholder={t('place.address.latitude')}
                            type='search'
                            value={searchCoords.lat}
                            onChange={(e) => changeGeopoint('lat', e.target.value)}
                            inputContainerClass='font-semibold bg-white mr-1'
                            inputClass='truncate'
                        />

                        <InputBase placeholder={t('place.address.longitude')}
                            type='search'
                            value={searchCoords.lng}
                            onChange={(e) => changeGeopoint('lng', e.target.value)}
                            inputContainerClass='font-semibold bg-white mr-1'
                            inputClass='truncate'
                            onKeyDown={(e) => e.key === 'Enter' ? showSearchCoords() : null}
                        />
                    </ContentRow>

                    <ButtonIcon
                        icon='search'
                        className='w-9 h-9  bg-success text-white rounded-lg justify-center mb-2'
                        iconClass='w-6 h-6'
                        onClick={() => showSearchCoords()}
                    />

                </ContentRow>

                {searchCoords.lat !== '' && searchCoords.lng !== '' &&
                <>
                <TextBase text={t('place.clickAddPlace')} textClass='italic' />

                <LeafletMap
                    coords={{lat: coords.lat, lng: coords.lng}}
                    zoom={zoomMap}
                    boundingbox={boundingbox}
                    className='h-[320px] w-full relative rounded-xl'
                    coordsSearchMap={showSearchMap}
                />
                </>
                }

            </ContentCol>

            <ButtonFooter action='valid'
                ifAction={checkValidAddress()}
                onValid={() => valid()}
                onCancel={() => onCancel()}
            />


        </SubCard>
    );
};


export { SearchAddress };