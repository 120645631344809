import { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { AuthCtx } from '../../_context/Context';
import { deleteClassApi, getClassApi } from '../../../utils';
import { Article } from '../../../../../shared/schema';
import { ButtonIcon, Card, CardTitle, ContentRow, InputSearch, ModalConfirm, Pagination, Suspense, TextBase } from '../../../components';
import { ArticleEditCard } from './ArticleEditCard';
import { ArticlePostCard } from './ArticlePostCard';

const ArticleList = () => {
    const [auth] = useContext(AuthCtx);
    const { t } = useTranslation(['datasite']);

    const [articleSearch, setArticleSearch] = useState<string>('');
    const [articleFilter, setArticleFilter] = useState<Article[]>([]);
    const [articleFilterPagination, setArticleFilterPagination] = useState<number>(0); 
    const [refreshList, setRefreshList] = useState<boolean>(false);
    const [nbArticle, setNbArticle] = useState<number>(0)
    
    const [showAddArtcile, setShowAddArticle] = useState<boolean>(false);
    const [showEditArticle, setShowEditArticle] = useState<Article>();
    const [showModalDelete, setShowModalDelete] = useState<Article>();

    const [saving, setSaving] = useState<boolean>(true);
    const nbItemsPerPage: number = 20; 

    useEffect(() => {
        async function fetchData() {
            let queryWhere: any = {};
            if (articleSearch.length > 0) queryWhere.title = {"$regex": articleSearch, $options: "i"}

            const getArticles = await getClassApi<Article>('Article', {
                "where" : JSON.stringify(queryWhere),
                "count": 1,
                "order": '-updatedAt',
                "limit": nbItemsPerPage,
                "skip": articleFilterPagination*nbItemsPerPage,
            });
            
            if (typeof getArticles !== 'string') {
                setArticleFilter(getArticles.results);
                setNbArticle(getArticles.count)
                setRefreshList(false); 
            }
            console.log(getArticles)
            setSaving(false);        
        }
        fetchData();
    },[articleSearch, articleFilterPagination, refreshList, setSaving]);


    const onSaveArticle= (article: Article)  => {
        setRefreshList(true); 
        setShowAddArticle(false)
        setShowEditArticle(undefined)
    }


    const deleteArticle = async(article: Article) => {
        if (article.objectId) {
            await deleteClassApi('Article', article.objectId)
            setRefreshList(true); 
        }
        
        setShowEditArticle(undefined)
    }


    return (

        <Card>

            <CardTitle title={showAddArtcile ? t('article.addTitle') : t('article.listTitle')} />

            {!showAddArtcile && !showEditArticle &&
            <>
            <InputSearch
                searchValue={articleSearch}
                onSearch={(text) => setArticleSearch(text)}
            />
            <TextBase text={t('article.nbArticle') + nbArticle} />
            </>
            }

            {!showEditArticle &&
            <ContentRow className='justify-end -mt-4'>

                <ButtonIcon icon={showAddArtcile ? 'close' : 'more'} iconClass='w-7 h-7' className='mb-2'
                    onClick={() => setShowAddArticle(!showAddArtcile)}
                >
                    <TextBase textClass='ml-2' text={t('article.add')}/>
                </ButtonIcon>

            </ContentRow>
            }

            {(showAddArtcile || showEditArticle) &&
            <ArticleEditCard
                article={showEditArticle}
                onSave={onSaveArticle}
                onCancel={() => {
                    setShowAddArticle(false)
                    setShowEditArticle(undefined)
                }}
            />
            }

            {!showEditArticle && !showAddArtcile &&
            articleFilter.map(article => (
                <ArticlePostCard
                    key={article.objectId as string}
                    article={article}
                    onDelete={setShowModalDelete}
                    onEdit={setShowEditArticle}
                />
            ))}

            {! showEditArticle && !showAddArtcile &&
            <Pagination
                nbItems={articleFilter.length}
                nbItemsPerPage={nbItemsPerPage}
                onChangePage={setArticleFilterPagination}
                initPagination={articleFilterPagination}
            />
            }

            <ModalConfirm
                data={showModalDelete}
                onValid={(article) => {
                    deleteArticle(article);
                    setShowModalDelete(undefined);
                }}
                onClose={() => setShowModalDelete(undefined)}
            />


            <Suspense showIf={saving} />
                
        </Card>
    );
};

export { ArticleList };