import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { postFunctionsApi } from '../../utils';
import { Suspense } from '../../components';

const ChangeEmail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation('auth');


  useEffect(() => {
    async function fetchData() {
        const changeEmail = await postFunctionsApi<boolean>('changeEmail', {username: params.get('username'), email: params.get('email'), token: params.get('token')})
        typeof changeEmail !== 'string'
        ? toast.success(t('changeEmail.success') as string, {theme: 'colored'})
        : toast.error(t('changeEmail.retry') as string, {theme: 'colored'});
        navigate('/login', {state: {username: params.get('email')}});
    }
    const params = new URLSearchParams(location.search);
    if (params.get('username') && params.get('email') && params.get('token')) fetchData()
  },[t, navigate, location]);

  return ( <Suspense /> );
};

export default ChangeEmail;