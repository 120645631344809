import { useContext } from 'react';
import { AuthCtx } from '../_context/Context';
import { Card, CardContent } from '../../components';


const RgpdView = () => {
    const [auth] = useContext(AuthCtx);

    return (

        <div className="w-screen grid grid-auto">

            {auth.permission?.presenceView &&
            <Card className="mb-2">
                <CardContent>
                    <div>Presence</div>
                </CardContent>
            </Card>
            }

    
        </div>
    

    );
};

export default RgpdView;