import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { cardExp, PiProductTranslate, postFunctionsApi } from '../../utils';
import { ParseDate, PiProduct, SiteSub } from '../../../../shared/schema/index';
import { ButtonFooter, ContentCol, ModalBase, Description, InputNumber, TextImportant, Title, Text, RadioList, Suspense } from '../../components';

type AddPiProductModalProps = {
    siteSub: SiteSub
    productSelected: 'plan' | 'email' | 'extend';
    setSiteSub: (p: SiteSub) => void;
    currency?: string;
    store?: string;
    open: boolean;
    onClose: () => void;
};

//PurchasePiProduct

const AddPiProductModal = ({siteSub, productSelected, currency='EUR', store='web', setSiteSub, open, onClose}: AddPiProductModalProps) => {
    const { t } = useTranslation('admin');
    const [piProducts, setPiProducts] = useState<PiProduct[]>([]);
    const [piProductSelected, setPiProductSelected] = useState<PiProduct>();
    const [quantity, setQuantity] = useState<number>(1);

    const [saving, setSaving] = useState<boolean>(false);

    useEffect(() => {


        if (open) {
            const products = piProducts.filter(p => p.name.split('-')[0] === productSelected);

            if (productSelected === 'plan') {
                const ps: PiProduct[] = []
                for (let p of products) {
                    if (p.type === 'free') continue;
                    if (productSelected === 'plan' && !siteSub.deletionAt && siteSub.plan?.code === p.code) continue;
                    ps.push(PiProductTranslate(p))
                }
                setPiProducts(ps)
            } else {
                setPiProductSelected(PiProductTranslate(products[0]))
            }
        }
    },[t, open, siteSub, productSelected])

    const onAddProduct = async() => {
        setSaving(true);   
        const addProduct = await postFunctionsApi<SiteSub>('addPiProductSub', { 
            productCode: piProductSelected?.code,
            quantity: quantity,
            siteId: siteSub.site.id,
        });
        if (typeof addProduct !== 'string') {
       
            if (productSelected === 'plan' && addProduct.plan) { 
                siteSub.plan = addProduct.plan;
                siteSub.planEndAt = undefined;
                siteSub.planPurchaseAt = addProduct.planPurchaseAt;
                siteSub.emailConsume = addProduct.emailConsume;
                siteSub.emailStock = addProduct.emailStock;  
                if (addProduct.billDay) siteSub.billDay = addProduct.billDay;
                if (addProduct.billMonth) siteSub.billMonth = addProduct.billMonth
            }
            if (productSelected === 'plan' && addProduct.planChange) { 
                siteSub.planChange = addProduct.planChange;
                siteSub.planChangeAt = addProduct.planChangeAt;
                siteSub.planEndAt = addProduct.planChangeAt as ParseDate;
            }
            if (productSelected === 'email') {
                siteSub.emailPurchase = addProduct.emailPurchase;
                siteSub.emailPurchasedAt = addProduct.emailPurchasedAt;
            }
            if (productSelected === 'extend') siteSub.deletionAt = addProduct.deletionAt

            toast.success(t('siteAdmin.success.changeProduct') as string, {theme: 'colored'});
            setSiteSub(siteSub);
            setSaving(false)
            return onClose()
        } 
        setSaving(false)
        toast.error(t('siteAdmin.error.changeProduct') as string, {theme: 'colored'});
        return false
    }

    return (
        <>
        {open && saving && <Suspense />}
        
        {!saving &&
        <ModalBase open={open} onClose={onClose} containerClass='w-80 sm:sm:w-3/4 mx-2 p-2 sm:p-4' >

            <ContentCol>

                {productSelected === 'plan' && piProducts &&
                <>
                    <Title text={t('siteAdmin.addProductPlan')} />

                    <RadioList
                        items={piProducts}
                        name='plan'
                        valueSelected={piProductSelected}
                        setValue={setPiProductSelected}
                        col={true}
                    />
                </>
                }
   
                {productSelected !== 'plan' && piProductSelected &&
                <>
                <Title text={productSelected === 'email' ? t('siteAdmin.addProductEmail') : t('siteAdmin.addProductExtend')} />

                <Description text={piProductSelected.description} />

                <InputNumber id='quanity'
                    label={t('piPay.quantity')}
                    min={1} max={100}
                    setValue={setQuantity}
                    value={quantity}
                />

                <TextImportant text={t('piPay.priceEmailLabel', {
                    price: quantity * piProductSelected.price.amount, count: quantity * (piProductSelected.option.email as number), duration: piProductSelected.option.duration, formatParams: {price: { currency: piProductSelected.price.currency }} 
                })} />
                </>
                }

                <ContentCol showIf={cardExp(siteSub.piCustomer?.paymentMethod) !== 'noCard'}
                    className={cardExp(siteSub.piCustomer?.paymentMethod) !== 'expired' ? 'text-danger' : ''}
                >
                    
                    <Text text={siteSub.piCustomer?.paymentMethod?.brand?.toUpperCase() + ' ****....' + siteSub.piCustomer?.paymentMethod?.last4} />
                    
                    <Text text={'Exp : '  + siteSub.piCustomer?.paymentMethod?.exp_month + '/' + siteSub.piCustomer?.paymentMethod?.exp_year?.toString().slice(2)}/>
                
                </ContentCol>

                <TextImportant showIf={cardExp(siteSub.piCustomer?.paymentMethod) === 'noCard'}
                    text={t('siteAdmin.noCard')}
                    textClass='text-danger'
                />

                <ButtonFooter
                    action='payment'
                    ifAction={cardExp(siteSub.piCustomer?.paymentMethod) !== 'expired' && cardExp(siteSub.piCustomer?.paymentMethod) !== 'noCard' }
                    onValid={() => onAddProduct()}
                    onCancel={onClose}
                />
            
            </ContentCol>
            
        </ModalBase>
        }
        </>
    );
};

export {AddPiProductModal};