import { ParseObject, Site, SiteSub, SiteSetting } from '../../../../shared/schema/index';
import {  } from ".";
import { colorDefault, colorA11yDefault, fontDefault, memberNameDefault } from '../../params';

const siteObject = (data?: ParseObject): Partial<Site> => {
  const object: Partial<Site> = {};
  for (const property in data) {
    switch (property) {
      case 'objectId':
      case 'name':
      case 'siteSetting':
        object[property] = data[property];
        break;
      default: break;          
    }
  }
  return object;
}

const siteSettingObject = (data?: ParseObject): SiteSetting => {
  return {
    use: data?.use ? data.use : {presenceView: true, eventView: true},
    color: data?.color ? data.color : colorDefault,
    colorA11y: data?.colorA11y ? data.colorA11y : colorA11yDefault,
    font: data?.font ? data.font : fontDefault,
    memberName: data?.memberName ? data.memberName : memberNameDefault,
    otherMemberName: data?.otherMemberName
  };
}

const siteSubObject = (data?: ParseObject): Partial<SiteSub>  => {
  const object: Partial<SiteSub> = {};
  for (const property in data) {
    switch (property) {
      case 'objectId':
      case 'piCustomer':
      case 'stripeSUBid':
      case 'stripeItemId':
      case 'site':
      case 'plan':
      case 'planPurchaseAt':
      case 'planEndAt':
      case  'billDay':
      case 'billMonth':
      case 'emailConsume':
      case 'emailStock':
      case 'emailPurchase':
      case 'emailConsumeOlds':
      case 'emailStockOlds':
      case 'emailPurchaseAt':
      case 'planChange':
      case 'planChangeAt':
      case 'canceled':
      case 'deletionAt':
        object[property] = data[property];
        break;
      default: break;          
    }
  }
  return object;
}

export { siteObject, siteSubObject, siteSettingObject }