import { LangList, PiProduct } from "../../shared/schema"

export const STRIPE_KEY = 'pk_test_51JE7ryBGvhDnVNXw7EDvCedYwcoD98Fojanj45g5wE4Ws6MjZ5dt5Cv1KS4B15yDTVm0HY59Ri74MuhjDE5QA3cB00fOjK1BBz';

//PI Plan Product
const emailMonthDefault = 2500;
const emailMonthFree = 100;
const emailMonthPurchase = 2500;


export const piProducts: PiProduct[] = [
    {code: 0, name: 'plan', type: 'free', period: 'month', price: {amount: 0, currency: 'eur'}, store: 'web', option: {email: emailMonthFree, nbMonth: 1}},
    {code: 1, name: 'plan', type: 'subscription', price: {amount: 7, currency: 'eur', stripeProductId: 'price_1MJb7RBGvhDnVNXwwUOXLPwH'}, store: 'web', option: {email: emailMonthDefault, nbMonth: 1} },
    {code: 2, name: 'plan', type: 'subscription', price: {amount: 70, currency: 'eur', stripeProductId: 'price_1MJb7RBGvhDnVNXwn8g99dqj'}, store: 'web', option: {email: emailMonthDefault *12, nbMonth: 12}},
    {code: 100, name: 'email', type: 'purchase', price: {amount: 5, currency: 'eur'}, store: 'web', option: {email: emailMonthPurchase, nbMonth: 12}},
    {code: 200, name: 'storage', type: 'purchase', price: {amount: 3, currency: 'eur'}, store: 'web', option: {nbMonth: 3}}
];

//Roles
export const ROLES = [
    "admin",
    "collaborater",
    "manager",
    "animator",
    //"animGroups",
    "agent",
    //"member"
];

export const TIME_MIN_LOGIN = 400;
export const TIME_MIN_REGISTER = 2000;

//Fonts
export const fontDefault = 'Roboto';
export const fontList = ["Roboto", "Montserrat", "Merriweather", "Cormorant", "Eczar", "DancingScript"] 

//Lang
export const langDefault = 'en';
export const LANG_LIST: LangList[] = [
    {name: 'de', label: "Deutsch", flag: ''},
    {name: 'en', label: "English", flag: ''},
    {name: 'es', label: "Español", flag: ''},
    {name: 'fr', label: "Français", flag: ''},
    {name: 'it', label: "Italiano", flag: ''},
    {name: 'pt', label: "Português", flag: ''},
];

//Color
export const colorDefault = "#6231af";
export const colorA11yDefault = "#FFFFFF";
export const colors = {
    piPrimary: "#90D3ED",
    piPrimaryContent: "#ffffff",
    piSecondary: "#6231af",
    piSecondaryContent: "#ffffff",
    neutre: "#27272a",
    neutreContent: "#f4f4f5",
    info: "#2094f3",
    success:  "#5e7127",
    warning: "#ff9900",
    danger: "#ff5724",
}
export const colorListDefault = [
    "#6231af",
    "#db2777",
    "#f59e0b",
    "#2dd4bf",
    "#06b6d4",
    "#10b981",
];

// membres, amis, adhérents, joueurs, invités, clients,
export const memberNameDefault: MemberLabel = {singular : "member", plural : "members"};

export type MemberLabel = { singular: string, plural: string, exception?: {lang: string, equal: string}[], other?: boolean, labelLang?: string }
export const memberLabelList: MemberLabel[] = [
    {singular : "member", plural : "members"},
    {singular: "friend", plural: "friends"},
    {singular: "memberA", plural: "memberAs", exception: [{lang: "en", equal: "member"}]},
    {singular: "player", plural: "players"},
    {singular: "guest", plural: "guests"},
    {singular: "client", plural: "clients"},   
];

export const memberRelationTypes: {name: string, label: string}[] = [
    {name: 'child', label: ''},
    {name: 'parent', label: ''},
    {name: 'spouse', label: ''},
    {name: 'other', label: ''},
]

export const countries = [
    "AD",
    "AE",
    "AF",
    "AG",
    "AI",
    "AL",
    "AM",
    "AO",
    "AP",
    "AQ",
    "AR",
    "AS",
    "AT",
    "AU",
    "AW",
    "AX",
    "AZ",
    "BA",
    "BB",
    "BD",
    "BE",
    "BF",
    "BG",
    "BH",
    "BI",
    "BJ",
    "BL",
    "BM",
    "BN",
    "BO",
    "BQ",
    "BR",
    "BS",
    "BT",
    "BV",
    "BW",
    "BY",
    "BZ",
    "CA",
    "CC",
    "CD",
    "CF",
    "CG",
    "CH",
    "CI",
    "CK",
    "CL",
    "CM",
    "CN",
    "CO",
    "CR",
    "CU",
    "CV",
    "CW",
    "CX",
    "CY",
    "CZ",
    "DE",
    "DJ",
    "DK",
    "DM",
    "DO",
    "DZ",
    "EC",
    "EE",
    "EG",
    "EH",
    "ER",
    "ES",
    "ET",
    "EU",
    "FI",
    "FJ",
    "FK",
    "FM",
    "FO",
    "FR",
    "GA",
    "GB",
    "GD",
    "GE",
    "GF",
    "GG",
    "GH",
    "GI",
    "GL",
    "GM",
    "GN",
    "GP",
    "GQ",
    "GR",
    "GS",
    "GT",
    "GU",
    "GW",
    "GY",
    "HK",
    "HM",
    "HN",
    "HR",
    "HT",
    "HU",
    "ID",
    "IE",
    "IL",
    "IM",
    "IN",
    "IO",
    "IQ",
    "IR",
    "IS",
    "IT",
    "JE",
    "JM",
    "JO",
    "JP",
    "KE",
    "KG",
    "KH",
    "KI",
    "KM",
    "KN",
    "KP",
    "KR",
    "KW",
    "KY",
    "KZ",
    "LA",
    "LB",
    "LC",
    "LI",
    "LK",
    "LR",
    "LS",
    "LT",
    "LU",
    "LV",
    "LY",
    "MA",
    "MC",
    "MD",
    "ME",
    "MF",
    "MG",
    "MH",
    "MK",
    "ML",
    "MM",
    "MN",
    "MO",
    "MP",
    "MQ",
    "MR",
    "MS",
    "MT",
    "MU",
    "MV",
    "MW",
    "MX",
    "MY",
    "MZ",
    "NA",
    "NC",
    "NE",
    "NF",
    "NG",
    "NI",
    "NL",
    "NO",
    "NP",
    "NR",
    "NU",
    "NZ",
    "OM",
    "PA",
    "PE",
    "PF",
    "PG",
    "PH",
    "PK",
    "PL",
    "PM",
    "PN",
    "PR",
    "PS",
    "PT",
    "PW",
    "PY",
    "QA",
    "RE",
    "RO",
    "RS",
    "RU",
    "RW",
    "SA",
    "SB",
    "SC",
    "SD",
    "SE",
    "SG",
    "SH",
    "SI",
    "SJ",
    "SK",
    "SL",
    "SM",
    "SN",
    "SO",
    "SR",
    "SS",
    "ST",
    "SV",
    "SX",
    "SY",
    "SZ",
    "TC",
    "TD",
    "TF",
    "TG",
    "TH",
    "TJ",
    "TK",
    "TL",
    "TM",
    "TN",
    "TO",
    "TR",
    "TT",
    "TV",
    "TW",
    "TZ",
    "UA",
    "UG",
    "UM",
    "US",
    "UY",
    "UZ",
    "VA",
    "VC",
    "VE",
    "VG",
    "VI",
    "VN",
    "VU",
    "WF",
    "WS",
    "YE",
    "YT",
    "ZA",
    "ZM",
    "ZW"
] 
