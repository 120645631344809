import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, ContentRow, ButtonText } from '../../../components';
import { ImportList } from './ImportList';
import { ImportMember } from './ImportMember';
import { ExportMember } from './ExportMember';


const FileMember = () => {
    const { t } = useTranslation(['member']);
    const [nav, setNav] = useState<'list' | 'import' | 'export'>('list')

    return (


        <Card>

            <ContentRow className='justify-center'>

                <ButtonText showIf={nav !== 'list'}
                    text={t('file.list')}
                    variant='filled-primary-lg'
                    onClick={() => setNav('list')}
                    className='mx-1'
                />

                <ButtonText showIf={nav !== 'import'}
                    text={t('file.import')}
                    variant='filled-primary-lg'
                    onClick={() => setNav('import')}
                    className='mx-1'
                />

                <ButtonText showIf={nav !== 'export'}
                    text={t('file.export')}
                    variant='filled-primary-lg'
                    onClick={() => setNav('export')}
                    className='mx-1'
                />

            </ContentRow>

            {nav === 'list' &&
            <ImportList />
            }

            {nav === 'import' &&
            <ImportMember />
            }

            {nav === 'export' &&
            <ExportMember />
            }
            
        </Card>

    );
};

export { FileMember };