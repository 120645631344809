import { InputHTMLAttributes, useRef, useState } from "react";
import ReactSelect from 'react-select';
import Compressor from 'compressorjs';
import tw from 'twin.macro';
import { Description, checkboxStyle, VariantCheckboxEnum, ButtonAction, Text } from ".";

/*
list : 
- button / submit / reset
- checkbox -> spécial
- color -
- date / datTime
- file / image -> spécial
- number -> spécial ? text

- radio -> spécial
- range -> spécial
- toogle -> spécial
*/

//const inputcontainerStyle = 'w-full border border-neutre rounded-lg outline-none ml-2 mr-2'
type LabelPosition = 'left' | 'leftAlign' | 'right';

const containerStyle = ''
const labelStyle = 'text-lg text-right pl-2';
const inputContainerStyle = 'flex items-center rounded-lg border border-neutreLight focus-within:border-neutre'
const inputStyle = 'w-full text-lg outline-none rounded-lg px-2 py-1'
const iconClass= ''


//Radio
const radioInputStyle = 'w-4 h-4 outline-none ml-2 my-2'
const radioLabelStyle = 'pl-1'

interface RadioBaseProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  valueSelected: any;
  value: any;
  setValue: (v: any) =>void;
  label: string;
  showIf?: boolean;
  containerlass?: string;
  labelClass?: string;
  inputClass?: string;
}
const RadioBase = ({name, valueSelected, label, value, setValue, containerlass, labelClass, inputClass, showIf = true, ...rest }: RadioBaseProps) => {
  return showIf ? (
    <div className={['flex flex-col focus-within cursor-pointer', containerlass].join(' ') } onClick={() => setValue(value)}>
      <div className="flex items-center mr-4 font-semibold">
        <input {...rest}
          id={value}
          name={name}
          type='radio'
          value={value}
          checked={valueSelected === value}
          className={[radioInputStyle, inputClass].join(' ')}
          readOnly
        />
        <label htmlFor={label} className={['ml-2', labelClass].join(' ')}>{label}</label>
      </div>
    </div>
  ) : null;
};


interface RadioProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  valueSelected?: any;
  value?: any;
  setValue: (v: any) => void;
  nameLabel?: string;
  font?: boolean;
  description?: string | React.ReactNode;
  description2?: string | React.ReactNode;
  showIf?: boolean;
  radioClass?: string;
  labelClass?: string;
  inputClass?: string;
}
const Radio = ({name, valueSelected, nameLabel = 'label', value, setValue, radioClass, labelClass, inputClass, showIf = true, font= false, description, description2, ...rest }: RadioProps) => {
  const label = typeof value === 'string' ? value : value[nameLabel]
  const labelContainer =  <label htmlFor={label} className={[radioLabelStyle, font ? "font-" + value : '', labelClass].join(' ')}>{label}</label>;
 
  return showIf ? (
    <div className={
      ['flex flex-col focus-within cursor-pointer', radioClass].join(' ')
      }
      onClick={() => setValue(value)}
    >
      <div className="flex items-center mr-4 font-semibold">
        <input {...rest}
          id={label}
          name={name}
          type='radio'
          value={value}
          checked={JSON.stringify(valueSelected) === JSON.stringify(value)}
          className={[radioInputStyle, inputClass].join(' ')}
          readOnly
        />
        {labelContainer}
      </div>
      <div className="ml-5 font-medium">
        {description ? <Description text={description}/> : null}
        {description2 ? <Description text={description2}/> : null}
      </div>
    </div>
  ) : null;
};

//Radio List
interface RadioListProps extends RadioProps {
  items: any[];
  name: string;
  valueSelected?: any,
  nameLabel?: string;
  font?: boolean;
  description?: string | React.ReactNode;
  col?: boolean;
  containerClass?: string;
}

const RadioList = ({ items = [], name, nameLabel = 'label', valueSelected, setValue, containerClass, className, showIf = true, font= false, col, description, ...rest }: RadioListProps) => {

  return showIf ? (
    <div className={[col ? 'w-full flex flex-col py-1' : 'w-full flex flex-wrap py-2 mr-2', containerClass].join(' ')}>
      {items.map((item, index) =>
        <Radio {...rest}
          key={index}
          name={name}
          valueSelected={valueSelected}
          value={item}
          setValue={setValue}
          nameLabel={nameLabel}
          font={font}
          description={item.description}
          description2={item.description2}
        />
      )}
    </div>
  ) : null;
};

//Checkbox
interface CheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string | React.ReactNode;
  id?: string;
  showIf?: boolean;
  variant?: VariantCheckboxEnum;
  containerClass?: string;
}

const Checkbox = ({ id, label, checked, variant= 'info', containerClass, className, showIf = true, ...rest }: CheckboxProps) => {

  return (
    showIf ? (
    <div className='flex'>
      <div className={['ml-4', containerClass].join(' ')}>
        <input {...rest}
          id={id}
          type='checkbox'
          checked={checked}
          className={['h-4 w-4 cursor-pointer mt-1', checkboxStyle(variant), className ].join(' ')}
        />
      </div>
      <label
        htmlFor={id}
        className={'pl-2'}
      >
        {label}
      </label>
    </div>
    ): null
  );
};

//LoadFile
type TypeFile = 'img' | 'doc';

interface LoadFileProps extends InputHTMLAttributes<HTMLInputElement> {
  typeFile?: TypeFile;
  multiple?: boolean;
  quality?: number;

  setFiles: (f: any) => void;
}

const LoadFile = ({typeFile = 'img', quality =0.8, multiple = false, setFiles, ...rest}: LoadFileProps) => {
  const ref = useRef<HTMLInputElement>(null)
  const handleClick = () => ref.current?.click();
  const accept = typeFile === 'img' ? 'image/*' : '.xlsx,.csv'
  //const capture -> TODO

  const selectFiles = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      let files = Array.from(event.target.files).map(file => {
        if (typeFile === 'img') {
          return new Promise(resolve => {
            new Compressor(file, {
              quality: quality,
              height: 1200,
              success: (compressedResult) => {
                let reader = new FileReader()
                reader.onload = () => resolve(reader.result?.toString());
                reader.readAsDataURL(compressedResult);
              },
            });
          })
        } else {
          return new Promise(resolve => {
            const reader = new FileReader()
            reader.onload = () => resolve(reader.result?.toString());
            reader.readAsDataURL(file);
          });
        }
      })

      let res = await Promise.all(files)
      multiple ?  setFiles(res) : setFiles(res[0])
    } 
  };

  return (
    <div className="">
      <input {...rest} ref={ref}
        type="file"
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => selectFiles(event)}
        className='hidden'
        multiple={multiple}
        accept={accept}
      />
      <ButtonAction action={typeFile === 'img' ? 'loadImage' : 'loadFile'} onClick={handleClick} />
    </div>

  );
};


//Input Select
type SelectProps = {
    options: any[];
    defaultValue?: any;
    onChange?: (s: any) => void;
    className?: string;
    variant?: string;
    isClearable?: boolean;
    isSearchable?: boolean;
    placeholder?: string;
}


const style = {
    //indicatorsContainer: (base: any , state:any) => (''),
    option: (base: any , state:any) => (state.isFocused ? tw`w-full bg-primary text-secondary py-3 pl-2` : tw`w-full bg-white text-neutre py-3 pl-2`),
    control: (base: any , state:any) => (tw`flex text-base text-neutre border border-neutreLight rounded-lg focus-within:border-neutre py-0`)
};

const Select = ({options, defaultValue, onChange, isClearable=false, isSearchable=false, className, variant, ...rest}: SelectProps) => (
  <ReactSelect {...rest}
    options={options}
    defaultValue={defaultValue}
    onChange={onChange}
    className={className}
    isClearable={isClearable}
    isSearchable={isSearchable}
    styles={style}
  />
);

//Toggle
type ToggleProps = {
  label?: string;
  checked?: boolean;
  onChange: (c: boolean) => void;
  containerClass?: string
  labelClass?: string;
  showIf?: boolean
};

const Toggle = ({label, checked, onChange, containerClass, labelClass, showIf=true}: ToggleProps) => {
  const stateBgStyle = () => {   
    switch(checked) {
      case true: return 'flex justify-start items-center bg-success'
      case false: return 'flex justify-end items-center bg-danger'
      default: return 'flex justify-center items-center bg-neutreLight'
    }
  }
  
  return (showIf ? (
    <div
      className={['flex items-center cursor-pointer mb-2', containerClass].join(' ')}
      onClick={() => onChange(checked ? !checked : true)}
    >
      
      <Text text={label} containerClass={['mr-2', labelClass].join(' ')} />
      
      <div
        className={['h-6 w-12 relative border rounded-lg', stateBgStyle()].join(' ')}
      >
        <div className='h-6 w-6 border border-neutre rounded-lg bg-white transition ease-in-out duration-200' />
      </div>


    </div>
  ) : null );
};



export {
  RadioBase,
  Radio,
  RadioList,
  Checkbox,
  LoadFile,
  Select,
  Toggle
}
