import { TextSizeEnum } from ".";

interface ContentContainerProps {
  children?: React.ReactNode | string;
  className?: string;
  textSize?: TextSizeEnum;
  showIf?: boolean;
};

const Content = ({ children, className, textSize, showIf= true }: ContentContainerProps) => {
  return showIf ? <div className={[textSize, className].join(' ')}>{children}</div> : null;
};

const ContentRow = ({ children, className, textSize, showIf }: ContentContainerProps) => {
  return <Content children={children} textSize={textSize} showIf={showIf} className={['w-full flex items-center mb-2', className].join(' ') }/>;
};

const ContentCol = ({ children, className, textSize, showIf }: ContentContainerProps) => {
  return <Content children={children} textSize={textSize} showIf={showIf} className={['w-full flex flex-col mb-2', className].join(' ') }/>;
};

const ContentCenter = ({ children, className, textSize, showIf }: ContentContainerProps) => {
  return <Content children={children} textSize={textSize} showIf={showIf} className={['w-full flex flex-col items-center justify-center mb-2', className].join(' ') }/>;
};

const ContentWrap = ({ children, className, textSize, showIf }: ContentContainerProps) => {
  return <Content children={children} textSize={textSize} showIf={showIf} className={['w-full flex flex-wrap items-center mb-2', className].join(' ') }/>;
};

const ContentBetween = ({ children, className, textSize, showIf }: ContentContainerProps) => {
  return <Content children={children} textSize={textSize} showIf={showIf} className={['w-full flex items-center justify-between mb-2', className].join(' ') }/>;
};


interface OtherContainerProps {
  children?: React.ReactNode | string;
  title?: React.ReactNode | string;
  text?: string;
  className?: string;
  showIf?: boolean;
  labelTop?: boolean;
  col?: boolean;
};


//Map
interface MapGroupProps<T> extends OtherContainerProps {
  items: T[];
  component: (item: T, key: number) => React.ReactNode;
  classContainer?: string;
}
const MapItems = <T,>({ items, component, classContainer, showIf= true, col = false }: MapGroupProps<T>) => {
  return showIf ?
    <div className={[col ? 'w-full flex flex-col py-1' : 'w-full flex flex-wrap py-2 mr-2', classContainer].join(' ')}>
      {items.map(component)}
    </div>
   : null;
};

const ButtonFooter = ({ children, className, showIf= true }: OtherContainerProps) => {
  return showIf ? <div className={['w-full flex justify-between mt-4', className].join(' ')}>{children}</div> : null;
};

export {
  Content,
  ContentRow,
  ContentCol,
  ContentCenter,
  ContentWrap,
  ContentBetween,

  MapItems,

  ButtonFooter
};
