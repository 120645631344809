import { InputHTMLAttributes, KeyboardEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import { ButtonIcon, Icon, IconEnum } from ".";

const inputContainerStyle = 'w-full flex items-center rounded-lg border border-neutrLight focus-within:border-neutre';
const inputContainerRequiredStyle = (isRequired: boolean): string => isRequired ? 'bg-indigo-50' : ''
const inputStyle =  'w-full bg-transparent text-lg outline-none rounded-lg px-2 py-1'
const iconStyle = 'h-6 w-6';
const iconLeftStyle = (iconLeft: boolean) =>  iconLeft ? '' : 'ml-2'


interface InputBaseProps extends InputHTMLAttributes<HTMLInputElement> {
  type?: 'text' | 'email' | 'tel' | 'url' | 'search';
  id?: string;

  icon?: IconEnum;
  iconLeft?: boolean;
  iconClass?: string;

  isRequired?: boolean;
  inputContainerClass?: string
  inputClass?: string;
}
const InputBase = ({type= 'text', id, icon, iconLeft= false, iconClass, inputContainerClass, inputClass, isRequired= false, ...rest }: InputBaseProps) => {
  const iconSvg = icon ? <Icon name={icon} className={[iconStyle, iconLeftStyle(iconLeft), iconClass ].join(' ')}  /> : null;
  return (
      <div className={[inputContainerStyle, inputContainerRequiredStyle(isRequired), inputContainerClass].join(' ')} >
        {iconLeft && iconSvg}
        <input {...rest} type={type} className={[inputStyle, inputClass].join(' ')} />
        {!iconLeft && iconSvg}
      </div>
    );
};

//const inputcontainerStyle = 'w-full border border-neutre rounded-lg outline-none ml-2 mr-2'
type LabelPosition = 'top' | 'left' | 'leftAlign' | 'right';

const containerStyle = 'overflow-hidden mb-2';
const labelStyle = 'text-base font-medium pl-2';
const labelPositionStyle = (labelPosition: LabelPosition): string =>  {
  switch(labelPosition) {
    case 'top':
      return 'w-full mt-2'
    case 'leftAlign':
     return 'text-right'
    case 'left':
      return 'mr-2'
    case 'right':
      return 'text-right ml-2'
  }
};


interface LabelContainerProps {
  children?: React.ReactNode | null;
  label?: string | React.ReactNode;
  id?: string;
  variant?: string;
  showIf?: boolean;
  //isRequired?: boolean;
  labelPosition?: LabelPosition;
  labelClass?: string;
  containerClass?: string;
};

const LabelContainer = ({id, children, label, variant, containerClass, labelClass, showIf= true, labelPosition='top' }: LabelContainerProps) => {
  const labelContainer =  label ? <label htmlFor={id} className={[labelStyle, labelPositionStyle(labelPosition), labelClass].join(' ')}>{label}</label> : null;
  const content = <>{labelPosition !== 'right' && labelContainer} {children} {labelPosition === 'right' && labelContainer}</>

  return showIf ? (
    labelPosition !== 'leftAlign' ? <div className={[containerStyle,  containerClass].join(' ')}>{content}</div> : <>{content}</>
  ) : null;
};

//Text
interface InputTextProps extends LabelContainerProps, InputHTMLAttributes<HTMLInputElement> {
  type?: 'text' | 'email' | 'tel' | 'url' | 'search';
  id?: string;

  icon?: IconEnum;
  iconLeft?: boolean;
  iconClass?: string;
  isRequired?: boolean;
  labelClass?: string;
  inputContainerClass?: string
  inputClass?: string;
}
const InputText = ({type= 'text', id, label, labelPosition, labelClass, icon, iconLeft= false, iconClass, containerClass, inputContainerClass, inputClass, isRequired= false, showIf = true, ...rest }: InputTextProps) => {
  const iconSvg = icon ? <Icon name={icon} className={[iconStyle, iconLeftStyle(iconLeft), iconClass ].join(' ')}  /> : null;
  return (
    <LabelContainer  showIf={showIf} label={label} labelPosition={labelPosition} containerClass={containerClass} labelClass={labelClass} >
      <div className={[inputContainerStyle, inputContainerRequiredStyle(isRequired), inputContainerClass].join(' ')} >
        {iconLeft && iconSvg}
        <input {...rest} type={type} className={[inputStyle, inputClass ].join(' ')} />
        {!iconLeft && iconSvg}
      </div>
    </LabelContainer>
    );
};
 
//Input Mail
const InputMail = ({id, label, labelPosition, labelClass, icon, iconLeft= false, iconClass, containerClass, inputContainerClass, inputClass, isRequired= false, showIf = true, ...rest }: InputTextProps) => {
  return (
    <InputText type="email" id={id}
      label={label} labelPosition={labelPosition} labelClass={labelClass}
      icon={icon} iconLeft={iconLeft} iconClass={iconClass}
      containerClass={containerClass} inputContainerClass={inputContainerClass} inputClass={inputClass}
      isRequired={isRequired} showIf={showIf} {...rest}
    />);
};

//Input Tel
const InputTel = ({id, label, labelPosition, labelClass, icon, iconLeft= false, iconClass, containerClass, inputContainerClass, inputClass, isRequired= false, showIf = true, ...rest }: InputTextProps) => {
  return (
    <InputText type="tel" id={id}
      label={label} labelPosition={labelPosition} labelClass={labelClass}
      icon={icon} iconLeft={iconLeft} iconClass={iconClass}
      containerClass={containerClass} inputContainerClass={inputContainerClass} inputClass={inputClass}
      isRequired={isRequired} showIf={showIf} {...rest}
    />);
};

//Input Url
const InputUrl = ({id, label, labelPosition, labelClass, icon, iconLeft= false, iconClass, containerClass, inputContainerClass, inputClass, isRequired= false, showIf = true, ...rest }: InputTextProps) => {
  return (
    <InputText type="url" id={id}
      label={label} labelPosition={labelPosition} labelClass={labelClass}
      icon={icon} iconLeft={iconLeft} iconClass={iconClass}
      containerClass={containerClass} inputContainerClass={inputContainerClass} inputClass={inputClass}
      isRequired={isRequired} showIf={showIf} {...rest}
    />);
};

//Input Search
interface InputSearchProps {
  searchValue: string;
  onSearch: (s: string) => void;
  showIf?: boolean;
}
const InputSearch = ({searchValue, onSearch, showIf = true, ...rest }: InputSearchProps) => {
  return (
    <InputBase type="search"
      value={searchValue}
      onChange={(event: React.ChangeEvent<HTMLInputElement>) => onSearch(event.target.value)}
      icon='search' iconLeft={true}
      inputContainerClass={'w-full mr-1 pl-1'}
     {...rest}
    />);
};

//Password
interface InputPasswordProps extends LabelContainerProps, InputHTMLAttributes<HTMLInputElement> {
  type: 'old' | 'confirm' | 'new' | 'pass';
  value?: string;
  isRequired?: boolean;
  setValue?: (v: string) =>void;
  inputContainerClass?: string
  inputClass?: string;
}

const InputPassword = ({type = 'pass', id, value, setValue, labelPosition, containerClass, labelClass, inputContainerClass, inputClass, isRequired= false, showIf = true, ...rest}: InputPasswordProps) => {
  const { t } = useTranslation(['auth']);
  const [passwordType, setPasswordType] = useState<'password' | 'text'>('password');

  const onType = () => {
    if (type === 'old') return t('formPassword.oldPassword');
    if (type === 'confirm') return t('formPassword.passwordConfirm');
    if (type === 'new') return t('formPassword.newPassword');
    return t('formPassword.password')
  }

  return (
    <LabelContainer showIf={showIf} label={onType()} labelPosition={labelPosition} labelClass={labelClass}  >
      <div className={['relative flex items-center', inputContainerStyle, inputContainerClass ].join(' ')} >
        <input {...rest}
          id={type}
          type={passwordType}
          className={[inputStyle, inputClass].join(' ')}
          placeholder={onType()}
        />
      
        <button
          className="flex items-center"
          onClick={() => setPasswordType(passwordType === 'password' ? 'text' : 'password')}
        >
          <Icon name={passwordType === 'password' ? 'visibility' : 'visibilityOff'} className="h-6 w-6 absolute right-0 justify-center mr-2"/>
        </button>
      </div>
    </LabelContainer>
  );
};

//Input Number
interface InputNumberProps extends LabelContainerProps, InputHTMLAttributes<HTMLInputElement> {
  id?: string;
  isRequired?: boolean;
  value: number
  setValue: (v: number) => void;
  min?: number;
  max?: number;
  inputContainerClass?: string
  inputClass?: string;
}
const InputNumber = ({id, label, value, setValue, min=0, max=100, labelClass, containerClass, inputContainerClass, inputClass, isRequired= false, showIf = true, ...rest }: InputNumberProps) => {
  const checkValue = (val: number) => {
    if (val >= max) val = max;
    if (val <= min) val = min;
    setValue(val);
  }
  const handleKeyUp = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'keyup') setValue(value >= max ? value : value + 1)
  }

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'keydown') setValue(value <= min ? value : value - 1)
  }

  return (
    <LabelContainer showIf={showIf} label={label} labelPosition='left' containerClass={['flex items-center w-30', containerClass].join(' ')} labelClass={labelClass} >
      
      <div className={[
        'w-32 flex items-center rounded-lg border border-neutre focus-within:border-2',
        inputContainerRequiredStyle(isRequired),
        inputContainerClass
        ].join(' ')}
      >
        
        <input {...rest}
          type='number'
          value={value}
          onChange={(event: any) => checkValue(event.target.value)}
          className={[inputStyle, inputClass ].join(' ')}
          onKeyUp={handleKeyUp}
          onKeyDown={handleKeyDown}
        />
        
        <ButtonIcon icon="more"
          iconClass={["h-8 w-8", value >= max ? 'opacity-60' : null].join(' ')}
          onClick={() => setValue(value >= max ? value : value + 1)}
        />
        
        <ButtonIcon icon="less"
          iconClass={["h-8 w-8", value <= min ? 'opacity-60' : null].join(' ')}
          onClick={() => setValue(value <= min ? value : value - 1)}
        />
     
     </div>

    </LabelContainer>
  );
};

//Container
interface InputAlignLeftProps {
  children?: React.ReactNode | string;
  className?: string;
  showIf?: boolean;
  labelTop?: boolean;
};

const InputAlignLeft = ({ children, showIf= true}: InputAlignLeftProps) => {
  return showIf ?
    <div className='relative grid grid-cols-[auto_1fr] items-center gap-2 my-4' > {children}</div>
   : null;
};


export {
  InputBase,
  InputText,
  InputMail,
  InputTel,
  InputUrl,
  InputPassword,
  InputSearch,
  InputNumber,

  InputAlignLeft
};
