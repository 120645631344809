import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Address, PiCustomer, User, UserLabel } from '../../../../shared/schema/index';
import { AddressCard } from '../_shared';
import { CustomerList } from './AdminSubView';
import { ButtonAction, ButtonIcon, ButtonText, Card, CardTitle, ContentCol, Text, TextImportant } from '../../components';
import { ChangeTypeModal } from './ChangeTypeModal';
import { ChangeAddressModal } from './ChangeAddressModal';
import { ChangeUserRefModal } from './ChangeUserRefModal';
import { ChangePaymentMethod } from './ChangePaymentMethod';
import { cardExp } from '../../utils';

type CustomerAdminProps = {
    customerList: CustomerList[];
};

const CustomerAdmin = ({customerList}: CustomerAdminProps) => {
    const { t } = useTranslation('admin');
    
    const [piCustomerSeleted, setPiCustomerSeleted] = useState<PiCustomer>(customerList[0].customer);

    const [modalType, setModalType] = useState<boolean>(false);
    const [modalUserRef, setModalUserRef] = useState<boolean>(false);
    const [modalAddress, setModalAddress] = useState<boolean>(false);
    const [modalPaymentMethod, setModalPaymentMethod] = useState<boolean>(false);

    return (
        <ContentCol>

            {customerList.map((c: CustomerList) => (

            <Card key={c.customer.objectId}>
                    
                <CardTitle
                    title={t('customerAdmin.title')}
                    text={c.customer.typeCustomer === 'society' ? c.customer.society?.name : (c.customer.user as User).firstName + ' ' + (c.customer.user as User).lastName} 
                >
                    <ButtonIcon icon='visibility' showIf={c.customer.objectId !== piCustomerSeleted.objectId}
                        onClick={() => setPiCustomerSeleted(c.customer)}
                        iconClass="text-info ml-4"
                    />
                </CardTitle>
                
                <ContentCol showIf={c.customer.objectId === piCustomerSeleted.objectId}>

                    <CardTitle levelTitle={2}
                        title={t('customerAdmin.typeCustomerTitle')}
                        text={t('piPay.typeCustomer', {context: piCustomerSeleted.typeCustomer})}
                    >
                        <ButtonAction action='modify' onClick={() => setModalType(true)}/>
                    </CardTitle>

                    <CardTitle levelTitle={2} showIf={piCustomerSeleted.society?.vatNumber !== undefined}
                        title={t('customerAdmin.societyVatNumber')}
                        text={piCustomerSeleted.society?.vatNumber}
                    />

                    <CardTitle levelTitle={2} showIf={piCustomerSeleted.society?.companyIdNumber !== undefined}
                        title={t('customerAdmin.societyCompanyIdNumber')}
                        text={piCustomerSeleted.society?.companyIdNumber}
                    />

                    <CardTitle levelTitle={2}
                        title={t('customerAdmin.userRef')}
                        text={(piCustomerSeleted.user as User).label}
                    >
                        <ButtonAction action='modify'showIf={c.admins.length > 1} onClick={() => setModalUserRef(true)}/>
                    </CardTitle>                       

                    <AddressCard address={piCustomerSeleted.address as Address} />
                    <ButtonText text={t('customerAdmin.changeAddress')} variant='filled-primary-lg' onClick={() => setModalAddress(true)} className='w-fit'/>

                    <CardTitle levelTitle={2}
                        title={t('customerAdmin.paymentMethod')}
                    >
                        <ButtonAction action='modify' onClick={() => setModalPaymentMethod(true)}/>
                    </CardTitle>

                    <CardTitle levelTitle={2} showIf={piCustomerSeleted.paymentMethod !== undefined}
                        title={piCustomerSeleted.paymentMethod?.brand?.toUpperCase() + ' ****....' + piCustomerSeleted.paymentMethod?.last4}
                        text={ 'Exp : '  + piCustomerSeleted.paymentMethod?.exp_month + '/' + piCustomerSeleted.paymentMethod?.exp_year?.toString().slice(2)}
                        className=''
                    />

                    <Text text={t('customerAdmin.cardExpMonth')} showIf={cardExp(piCustomerSeleted.paymentMethod) === 'expiring'} textClass='text-warning italic' />

                    <Text text={t('customerAdmin.cardExp')} showIf={cardExp(piCustomerSeleted.paymentMethod) === 'expired'} textClass='text-danger font-semibold' />

                    <TextImportant showIf={cardExp(piCustomerSeleted.paymentMethod) === 'noCard'}
                        text={t('siteAdmin.noCard')}
                        textClass='text-danger'
                    />

                </ContentCol>

            </Card>

            ))}

            <ChangeTypeModal
                open={modalType}
                piCustomer={piCustomerSeleted}
                onClose={() => setModalType(false)} 
            />

            <ChangeUserRefModal
                open={modalUserRef}
                piCustomer={piCustomerSeleted}
                admins={customerList.find(cl => cl.customer.objectId === piCustomerSeleted.objectId)?.admins as UserLabel[]}
                onClose={() => setModalUserRef(false)} 
            />

            <ChangeAddressModal
                open={modalAddress}
                piCustomer={piCustomerSeleted}
                onClose={() => setModalAddress(false)}
            />

            <ChangePaymentMethod
                open={modalPaymentMethod}
                piCustomer={piCustomerSeleted}
                onClose={() => setModalPaymentMethod(false)}
            />

                   
        </ContentCol>

    );
};

export { CustomerAdmin };