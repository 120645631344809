import { useEffect, useState } from "react";
import { MapContainer, TileLayer, Marker, useMapEvents, useMap } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L, { LatLng } from "leaflet";
import { CoordsMap, Place } from "../../../shared/schema";

const invitComIcon = L.icon({
    iconUrl: '../images/map_marker.png',
    iconSize: [24, 24], 
    iconAnchor: [12, 24],
    shadowUrl: undefined,
    shadowAnchor: undefined,
    shadowSize: [24, 24],
    popupAnchor: [0, 0] 
});

type LeafletMapProps = {
    places?: Place[],
    coords?: CoordsMap
    zoom: number,
    boundingbox?: number[],
    className: string,
    coordsSearchMap?: (lat: number, lng: number) => void;
    showPlace?: (p: Place) => void;
    editPlace?: (p: Place) =>void
    deletePlace?: (p: Place) => void
}

const LeafletMap = ({places, coords, zoom, boundingbox, className, coordsSearchMap, showPlace}: LeafletMapProps) => { 
  
    return (
        <MapContainer
            className={className}
            center={coords ? [coords.lat, coords.lng] : [0, 0]}
            zoom={zoom}
            scrollWheelZoom={true}
        >
        
            <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />


            {places &&
                <AddMarkers places={places} showPlace={showPlace}/>
            }

            {coords &&
            <AddMarker coords={coords} boundingbox={boundingbox} coordsSearchMap={coordsSearchMap} />
            }
       
        </MapContainer>
  );
}

type AddMarkerProps = {
    coords?: CoordsMap,
    boundingbox?: number[],
    coordsSearchMap?: (lat: number, lng: number) => void
}

const AddMarker = ({coords, boundingbox, coordsSearchMap}: AddMarkerProps) => {
    const [position, setPosition] = useState<LatLng>(coords as LatLng);
    
    const map = useMap()

    useEffect(() => {
        addMarker(coords as LatLng)
        if (boundingbox && boundingbox.length > 0) {
            const c1 = L.latLng(boundingbox[0], boundingbox[2])
            const c2 = L.latLng(boundingbox[1], boundingbox[3])
            const bounds = L.latLngBounds(c1, c2)
            map.flyToBounds(bounds)
        }
    },[coords, coords?.lat, coords?.lng, boundingbox, map])

    useMapEvents({
        click(e) {
            if (coordsSearchMap) {
                addMarker(e.latlng)
                coordsSearchMap(e.latlng.lat, e.latlng.lng)
                map.flyTo(e.latlng, map.getZoom())
            }
        }
    })

    const addMarker = (newCoords: LatLng) => {
        setPosition(newCoords);
        L.marker([newCoords.lat, newCoords.lng], {icon: invitComIcon});
    }

    return (
        <Marker position={position} icon={invitComIcon} />
    )
  
}

type AddMarkersProps = {
    places: Place[];
    showPlace?: (p: Place) => void;
}

const AddMarkers = ({places, showPlace}: AddMarkersProps) => {
    const position = null;
    const map = useMap()
    const markers = L.layerGroup()
    //TODO refresh map

    useEffect(() => {
        if (places.length > 0) {
            let latMin = 360
            let latMax = 0
            let lngMin = 360
            let lngMax = 0

            places.forEach(p => {
                
                if (p.address && p.address.geoPoint) {
                    const lat = p.address.geoPoint.latitude
                    const lng = p.address.geoPoint.longitude
        
                    const lat180 = lat + 180
                    const lng180 = lng + 180
                    if (lat180 < latMin) latMin = lat180
                    if (lat180 > latMax) latMax = lat180
                    if (lng180 < lngMin) lngMin = lng180
                    if (lng180 > lngMax) lngMax = lng180
        
                    L.marker(
                        [lat, lng],
                        {icon: invitComIcon}
                    )
                    .addEventListener('click', () => {
                        map.flyTo(L.latLng(lat, lng), map.getZoom())
                        showPlace?.(p)
                        
                    }).addEventListener('dblclick', () => {
                        map.flyTo(L.latLng(lat, lng), 16)
                    })
                    .bindTooltip(p.name as string, {offset: [12, -12]})
                    .addTo(markers)
                }
            })

            const c1 = L.latLng(latMax -180, lngMin -180)
            const c2 = L.latLng(latMin -180, lngMax -180)
            if (JSON.stringify(c1) === JSON.stringify(c2)) {
                map.flyTo(c1, 16)
            } else {
                const bounds = L.latLngBounds(c1, c2)
                map.flyToBounds(bounds)
            }

            markers.addTo(map)
        }
    }, [places, map, markers, showPlace])

    return position === null ? null : (
        <Marker position={position} icon={invitComIcon} />
    ) 
  
}

export { LeafletMap }