import { useContext, useEffect, createRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import helpPages from './HelpPages';
import { HelpCtx } from '../_context/Context';
import { HelpPage } from '../../../../shared/schema/index';
import { ButtonAction, ButtonIcon, Select } from '../../components';

const HelpView = ()  => {
    const { t } = useTranslation('nav');
    const {help, setHelp} = useContext(HelpCtx);
    const [page, setPage] = useState<HelpPage>();
    const [pageSelected, setPageSelected] = useState<string>();
    const [idSelected, setIdSelected] = useState<string>('a');
    const [helpFull, setHelpFull] = useState<boolean>(false);
    
    useEffect(() => {
        if (help) {
            const helpPage = helpPages.find(hp => hp.name === help.page);
            if (helpPage) {
                setPage(helpPage)
                if (help.article) return setIdSelected(help.article);
                if (help.section) return setIdSelected(help.section);
            }
        }
        if (pageSelected) {
            //TODO
        }
    },[help, pageSelected])

    const refs = [{id: 'members'}, {id: 'publication'}].reduce((acc: any, value: any) => {
        acc[value.id] = createRef();
        return acc;
    }, {});;

    const handleClick = (id: string) =>
        refs[id].current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
    });

        
    const onListPage = () => {
        const list = helpPages.map(hp => {return {name: hp.name, label: hp.label}})
        //TODO Insert "Menu utilisateur" & "Tableau de bord"
        //TODO sort ?
        //Page Selected checked (color)
        return list
    }

    return (
        <>
            {help &&
            <div className={[
                'relative w-full bb-primary bg-opacity-20 z-40',
                helpFull ? 'h-full' : 'h-1/2'
            ].join(' ')}>

                <div className='h-12 w-full flex justify-between items-center bg-gray-200 p-4'>
                    <div className='relative z-50'>
                        <Select
                            options={onListPage()}
                            defaultValue={pageSelected}
                            onChange={(p) => setPageSelected(p)}
                            className='w-60 sm:w-80'
                        /> 
                    </div>

                    <ButtonAction action='close' onClick={() => setHelp(undefined)} />
            
                </div>

                <div className='w-full absolute top-12 bottom-0 overflow-y-auto flex flex-col text-lg text-secondary  p-2 sm:p-4'>
    
                    {page &&            
                    <div className='text-secondary'>
                        {page.intro && 
                        <div className='italic text-base mb-4'>
                            {page.intro}
                        </div>
                        }

                        {page.sections && page.sections.length > 1 &&
                        <div className='mb-6'>
                            {page.sections.map((s, k) =>
                            <div key={k} className='text-lg font-bold mb-2 text-info hover:text-opacity-80' >   
                                <button className='' onClick={() => handleClick(s.name)}>{s.label}</button>
                            </div>    
                            )}
                        </div>
                        }

                        {page.articles && page.articles.length > 0 && page.articles.map((a, k) =>
                        <div key={k} ref={refs[a.name]} className='mb-6' >   
                            <div className='text-lg'>{a.label}</div>
                            <div className='text-base'>{a.content}</div>  
                        </div>    
                        )}

                        {page.sections && page.sections.length > 0 && page.sections.map((s, ks) =>
                        <div key={ks} ref={refs[s.name]} className='mb-4 border-t-2 border-t-secondary' >
                            <div className='text-lg font-semibold'>{s.label}</div>
                            {s.intro ? <div className='text-base italic mb-2'>{s.intro}</div> : null}
                            {s.articles && s.articles.length > 0 && 
                            s.articles.map((a, ka) =>
                            <div key={ka} ref={refs[a.name]} className='mb-2 ml-2' >   
                                <div className='text-lg'>{a.label}</div>
                                <div className='text-base'>{a.content}</div>  
                            </div>    
                            )}
                        </div>    
                        )}
                    </div>   
                    }
                    
                </div>
            

                <div className={[
                    helpFull ? 'fixed bottom-2 right-6' : 'fixed bottom-1/2 right-6 -mb-6'
                ].join(' ')}>
                    <ButtonIcon icon={helpFull ? 'arrowCircleUp' : 'arrowCircleDown'}
                        iconClass='h-8 w-8 text-neutre '
                        onClick={() => setHelpFull(!helpFull)}
                    />
       
                </div>
            
            </div>
            }
        </>
    );
};

export { HelpView };