import { Root, createRoot } from "react-dom/client";
import Quill, { BoundsStatic } from "quill";
import Emitter from 'quill';
import { TypeLink } from "../../../shared/schema";
import { linkRequest, LinkTooltip } from "./QLink";
import { ImageTooltip, imageEdit } from "./QImage";
import { postFunctionsApi } from "../utils";


const SnowTheme = Quill.import("themes/snow");
const icons = Quill.import("ui/icons");

class Range {
    index: number;
    length: number
    constructor(index: number, length = 0) {
        this.index = index;
        this.length = length;
    }
}

class PiTheme extends SnowTheme {
    constructor(quill: typeof Quill, options: any) {
        super(quill, options);
        this.quill.container.classList.add('ql-snow');
    }

    extendToolbar(toolbar: any) {
        toolbar.container.classList.add('ql-snow');
        
        this.buildButtons([].slice.call(toolbar.container.querySelectorAll('button')), icons);
        
        this.buildPickers([].slice.call(toolbar.container.querySelectorAll('select')), icons);
        
        this.tooltip = new PiTooltip(this.quill, this.options.bounds);
        
        if (toolbar.container.querySelector('.ql-link')) {
            this.quill.keyboard.addBinding(
                { key: 'K', shortKey: true },
                (range: Range, context: any) => {
                    toolbar.handlers['link'].call(toolbar, !context.format.link);
                }
            );
        }
    }
}


class PiTooltip {
    quill: Quill
    boundsContainer: any;
    root: any;
    rootContainer: Root | undefined
    linkRange?: Range;
    clickData?: any
 
    constructor(quill: Quill, boundsContainer: any) {
        this.quill = quill;
        this.boundsContainer = boundsContainer || document.body;
        this.root = quill.addContainer('ql-tooltip');
        this.root.setAttribute('id', 'pi-tooltip')
        this.listenClick();
        this.listenSelection();
        this.hide();
    }

    listenClick() {
        this.quill.root.addEventListener('click', (event: any) => {
            console.log('click')
            if (event.target.tagName === 'IMG') {
                console.log(event.target)
                this.clickData = {tagName: 'IMG', url: event.target.src, name: event.target.alt}
                this.quill.setSelection(event.target.getAttribute('start'), 1, 'user')
                return
            }
            delete this.clickData
        })
    }
  
    listenSelection() {
        const container = document.getElementById('pi-tooltip');
        this.quill.root.addEventListener('scroll', () => {
            this.hide()
        });

        this.quill.on('selection-change', (range: Range, oldRange: Range, source: any) => {

            console.log('selection-change')
            console.log(range)

            console.log('this.clickData')
            console.log(this.clickData)

            if (range && range.length === 1 && this.clickData?.tagName === 'IMG') {
                console.log('select IMG')
                this.position(this.quill.getBounds(range.index, 1));
                this.rootContainer = createRoot(container!);

                this.rootContainer.render(
                    <ImageTooltip
                        onCancel={() => {}}
                        onEdit={() => this.editImage(this.clickData.url, this.clickData.name)}
                        onDelete={() => {}}
                    />
                );

                return
            }

            if (range && range.length === 0 && source === Emitter.sources.USER) {                
                const format = this.quill.getFormat()
                
                if (format.link) { 
                    console.log('select Link')                 
                    let [leaf, offset] = this.quill.getLeaf(range.index)

                    if (this.rootContainer && oldRange) {
                        let [oldLeaf] = this.quill.getLeaf(oldRange.index)
                        if (oldLeaf.text === leaf.text) return
                        this.rootContainer.unmount()
                    }
                    
                    this.linkRange = new Range(range.index - offset, leaf.text.length);

                    const {text, type} = this.linkType(format.link)
                    this.position(this.quill.getBounds(range.index - offset, leaf.text.length));
                    this.rootContainer = createRoot(container!);

                    this.rootContainer.render(
                        <LinkTooltip
                            text={text}
                            type={type}
                            onShow={() => this.showLink(format.link)}
                            onEdit={() => this.editLink(text, type, format.link)}
                            onDelete={() => this.deleteLink()}
                        />
                    );
                    
                    return
                }
            }
            
            this.hide();
        });       
    }

    position(reference: BoundsStatic) {
        let top = reference.top - 90 //+ this.quill.root.scrollTop;
        let left = reference.left + ((reference.right - reference.left)/2) - 70 //- this.root.offsetWidth/2;
        
        this.root.style.top = top + 'px';
        this.root.style.left = left + 'px';
    }

    linkType(link: string): {type: TypeLink, text: string} {
        if (link.startsWith('tel:')) return {type: 'tel:' ,text: link.split('tel:')[1]}
        if (link.startsWith('mailto:')) return {type: 'mailto:' ,text: link.split('mailto:')[1]}
        return {type: 'url', text: link}
    }

    showLink(link: string) {
        window.open(link, '_blank');
    }
    
    async editLink(text: string, type: TypeLink, oldLink: string) {
        const request = await linkRequest(text, type)
        if (request !== oldLink && this.linkRange)
            this.quill.formatText(this.linkRange.index, this.linkRange.length ,'link', request, Emitter.sources.USER)
        this.hide()
    }

    deleteLink() {
        if (this.linkRange) {
            this.quill.formatText(this.linkRange.index, this.linkRange.length ,'link', false, Emitter.sources.USER)
            this.hide()
        }
    }

    async editImage(src: string, name: string) {
        console.log(src)
        console.log(name)
        const request = await imageEdit(src, name)
        console.log(request)
        /*
        const image = await postFunctionsApi<string>('getImage', {name: name, siteId: JSON.parse(localStorage.getItem('s') as string)})
        if (typeof image !== 'string') {
            const src = URL.createObjectURL(new Blob([image], {type: 'image/png'}))
            console.log(src)
            const request = await imageEdit(src, name)
        }
        */
    }

    deleteImage() {

    }
    
    hide() {
        console.log('hide')
        if (this.rootContainer) this.rootContainer.unmount()
        this.rootContainer = undefined
        delete this.linkRange;
        delete this.clickData
    }
}

export { PiTheme }