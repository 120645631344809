import Quill from "quill";
import tw from 'twin.macro';
import { fontList } from "../params";
import { PiTheme } from "../quillConfig/PiTheme";



const textColors = [
    tw`text-black`, tw`text-gray-700`, tw`text-gray-500`,
    tw`text-white`, tw`text-gray-200`, tw`text-gray-400`,
    tw`text-red-600`, tw`text-red-300`,tw`text-red-800`,
    tw`text-orange-400`, tw`text-orange-200`,tw`text-orange-500`,
    tw`text-yellow-300`, tw`text-yellow-100`,tw`text-yellow-500`, 
    tw`text-green-600`, tw`text-green-300`,tw`text-green-800`, 
    tw`text-blue-600`, tw`text-blue-300`,tw`text-blue-800`, 
    tw`text-purple-600`, tw`text-purple-300`,tw`text-purple-800`, 
    tw`text-primary`, 
]
  
const bgColors = [
    tw`bg-black`, tw`bg-gray-500`, tw`bg-gray-300`,
    tw`bg-white`, tw`bg-gray-100`, tw`bg-gray-200`,
    tw`bg-red-500`, tw`bg-red-200`,tw`bg-red-700`,
    tw`bg-orange-300`, tw`bg-orange-100`,tw`bg-orange-400`,
    tw`bg-yellow-200`, tw`bg-yellow-50`,tw`bg-yellow-400`, 
    tw`bg-green-500`, tw`bg-green-200`,tw`bg-green-700`, 
    tw`bg-blue-500`, tw`bg-blue-200`,tw`bg-blue-700`, 
    tw`bg-purple-500`, tw`bg-purple-200`,tw`bg-purple-700`, 
    tw`bg-primary`,
] 

const fonts: {value: string, label: string}[] = fontList.map (f => {return { value: f.toLowerCase(), label: f}})

// Add Font to whitelist and register them
const Font = Quill.import('formats/font');
Font.whitelist = fontList.map(f => f.toLowerCase());
Quill.register(Font, true);


// Add sizes to whitelist and register them
const Size = Quill.import("formats/size");
Size.whitelist = ["extra-small", "small", "medium", "large"];
Quill.register(Size, true);

Quill.register("themes/snow", PiTheme);


export { textColors, bgColors, fonts }