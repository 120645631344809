import { useState } from "react";
import { ButtonText } from "./Button";

type RatingProps = {
    rating: number
    setRating: (r: number) => void
}

const Rating = ({rating, setRating}: RatingProps) => {
    const [hover, setHover] = useState<number>(0);

    return (
      <div className="flex justify-center mb-4">
        {[...Array(5)].map((star, index) => {
          index += 1;
          return (
            <ButtonText text={<span className="star">&#9733;</span>}
              key={index}
              className={index <= (hover || rating) ? " text-3xl text-yellow-400 px-2" : "text-2xl text-zinc-300 px-2"}
              onClick={() => setRating(index)}
              onMouseEnter={() => setHover(index)}
              onMouseLeave={() => setHover(rating)}
            />
          );
        })}
      </div>
    );
};

export default Rating