import { ParseObject, Address, NominatimAddress } from '../../../../shared/schema/index';
import { parsePointer } from './Parse';

const addressObject = (data?: ParseObject): Partial<Address> => {
    const siteId = JSON.parse(localStorage.getItem('s') as string);
    return {
        objectId: data?.objectId,
        country: data?.country,
        city: data?.city ? data.city : '',
        zipCode: data?.zipCode ? data.zipCode : '',
        street: data?.street ? data.street : '',
        addStreet: data?.addStreet ? data.addStreet : '',
        state: data?.state ? data.state : '',
        geoPoint: data?.geoPoint,
        site: data?.site
    };
}

const newAddress = (): Address => {
    const siteId = JSON.parse(localStorage.getItem('s') as string);
    return {
        street: '',
        addStreet: '',
        
        city: '',
        zipCode: '',

        state:'',
        country: '',
        site: parsePointer('Site', siteId),
    };
}

const nominatimAddress = (address: NominatimAddress) => {
    const a = newAddress()

    let street = ''
    if (address.house_number) street = address.house_number + ' '    
    if (address.road) street = street + address.road
    a.street = street

    let city = ''
    if (address.municipality) city = address.municipality
    if (address.city) city = address.city
    if (address.town) city = address.town
    if (address.village) city = address.village
    a.city = city

    if (address.postcode) a.zipCode = address.postcode

    let state = ''
    //if (address.county) state = address.county
    //if (address.state_district) state = address.state_district
    //if (address.region) state = address.region
    if (address.state) state = address.state
    a.state = state

    if (address.country) a.country = address.country

    return a
}

const addressToText = (address: Address) => {
    let text = ''
    if (address.street !== '') text = address.street + ', '
    if (address.city !== '') text = text + address.city + ', '
    if (address.postcode) text = text + address.postcode + ', '
    if (address.state !== '') text = text + address.state + ', '
    if (address.country) text = text + address.country

    return text
}

export { addressObject, newAddress, nominatimAddress, addressToText }