import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AuthCtx } from '../_context/Context';
import { postFunctionsApi } from '../../utils';
import { PiCustomer, SiteSub, Site, UserLabel } from '../../../../shared/schema/index';

import { SiteAdmin } from './SiteAdmin';
import { InvoiceAdmin } from './InvoiceAdmin';
import { CustomerAdmin } from './CustomerAdmin';

import { ButtonText, SubView, View, ViewFooter } from '../../components';

type AdminSubscriptionNavView = 'site' | 'invoice' | 'customer';
type CustomerList = {admins: UserLabel[], customer: PiCustomer, siteId?: string};

const AdminSubView = () => {
    const [auth] = useContext(AuthCtx);
    const { t } = useTranslation('admin');
    const [customerList, setCustomerList] = useState<CustomerList[]>([])
    const [siteSubs, setSiteSubs] = useState<SiteSub[]>([]);

    const [navView, setNavView] = useState<AdminSubscriptionNavView>('site');
    
    useEffect(() => {
        async function fetchData() {
            const getCustomer = await postFunctionsApi<{customerRef: PiCustomer[], customerList: {admins: UserLabel[], sub: SiteSub, stripePIStatus: string}[]}>('getCustomer');
            const customerList: CustomerList[] = [];
            const subs: SiteSub[] = [];
            const gcSites: Site[] = [];
            if (typeof getCustomer !== 'string') {
                getCustomer.customerList.forEach(c => {
                    const cl: CustomerList = {
                        admins: c.admins.map(admin => admin = {...admin, label: admin.firstName + ' ' + admin.lastName + ' (' + admin.username + ')' }),
                        customer: c.sub.piCustomer as PiCustomer,
                        siteId: c.sub.site?.objectId
                    };
                    const subUser = c.sub.piCustomer?.user as UserLabel;
                    subUser.label = subUser.firstName + ' ' + subUser.lastName + ' (' + subUser.username + ')';
                    (c.sub.piCustomer as PiCustomer).user = subUser
                    if (c.sub.site?.objectId === auth.siteId) {
                        customerList.unshift(cl);
                        subs.unshift(c.sub);
                        gcSites.unshift(c.sub.site as Site);
                    } else {
                        customerList.push(cl);
                        subs.push(c.sub);
                        gcSites.push(c.sub.site as Site);
                    }
                });
                getCustomer.customerRef.forEach(cr => {
                    if (customerList.find(c => c.customer.objectId === cr.objectId) === undefined) {
                        const cl: CustomerList = {admins: [auth.me as UserLabel], customer: cr}
                        customerList.push(cl);
                    }
                })

                setSiteSubs(subs);
                setCustomerList(customerList);
            }        
        }
        fetchData();    
    }, [auth.me, auth.siteId]);
    
    return (
        <>

            <View showIf={siteSubs?.length > 0 && customerList?.length > 0} >
    
                <SubView showIf={navView === 'site'}>   
                    <SiteAdmin siteSubs={siteSubs} setSiteSubs={setSiteSubs} />
                </SubView> 

                <SubView showIf={navView === 'invoice'}>              
                    <InvoiceAdmin customerList={customerList} />
                </SubView> 

                <SubView showIf={navView === 'customer'}>            
                    <CustomerAdmin customerList={customerList} />
                </SubView>  

            </View>   
                
            <ViewFooter> 
            
                <ButtonText variant="filled-primary"
                    showIf={navView !== 'site'}
                    text={t('siteAdmin.nav')}
                    onClick={() => setNavView('site')}
                />
                
         
                <ButtonText variant="filled-primary"
                    showIf={navView !== 'invoice'}
                    text={t('invoiceAdmin.nav')}
                    onClick={() => setNavView('invoice')}
                />
                
                <ButtonText variant="filled-primary"
                    showIf={navView !== 'customer'}
                    text={t('customerAdmin.nav')}
                    onClick={() => setNavView('customer')}
                />
                          
            </ViewFooter> 

        </>
        
    );
};

export { AdminSubView };
export type {CustomerList, UserLabel};