import { ParseObject, User, UserSetting, UserPassword } from '../../../../shared/schema/index';

const userObject = (data: ParseObject, noClassName?: boolean): Partial<User> => {
    const object: Partial<User> = {
        className: noClassName ? '_User' : undefined
    };
    for (const property in data) {
        switch (property) {
            case 'userSetting':
                userSettingObject(data[property]);
                break;
            case 'objectId':
            case 'email':
            case 'emailTmp':
            case 'username':
            case 'firstName':
            case 'lastName':
            case 'password':
            case 'banished':
            case 'rgpdValided':
                object[property] = data[property];
                break;
            default: break;          
        }
    }

    return object;
} 

const userSettingObject = (data?: ParseObject) : UserSetting => {
    return {
        language: data?.language ? data.language : 'en'
    }
};



const userPasswordObject = (data?: UserPassword) : UserPassword => {
    return {
        username: data?.username ? data.username : '',
        password: data?.password ? data.password : '',
        passwordConfirm: data?.passwordConfirm ? data.passwordConfirm : '',
        attribute: data?.attribute ? data.attribute : 'Y',
        token: data?.token,
        rgpdValided: data?.rgpdValided,
        userSetting: data?.userSetting ? data.userSetting : userSettingObject()
    }
};

export  { userObject, userSettingObject, userPasswordObject };

