import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getClassApi } from '../../../utils';
import { AuthCtx } from '../../_context/Context';
import { Member, MemberImport } from '../../../../../shared/schema/index';
import { Card, Pagination, ContentRow, Suspense, ButtonText, SubCard, Text, getMemberName } from '../../../components';


const ImportMember = () => {
    const [auth] = useContext(AuthCtx);
    const { t } = useTranslation(['member']);

    const [loading, setLoading] = useState<boolean>(false);


    
    return (


        <SubCard className='mt-4'>

            <Text text={t('fileImport.title', {member: getMemberName({t:t, name: auth.siteSetting.memberName, plural: true})})} textClass='font-semiBold' />



            <Suspense showIf={loading} />
                    
        </SubCard>

    );
};

export { ImportMember };