import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ButtonIcon } from ".";

type PaginationProps = {
    nbItems: number;
    nbItemsPerPage: number;
    onChangePage: (n: number) => void;
    initPagination?: number; 
};

const Pagination = ({nbItems, nbItemsPerPage, onChangePage, initPagination}: PaginationProps) => {
    const { t } = useTranslation('button');
    const nbPage: number = Math.ceil(nbItems/nbItemsPerPage) ? Math.ceil(nbItems/nbItemsPerPage) : 0;
    const [numPageActive, setNumPageActive] = useState<number>(1);
  
    useEffect(() => {
        if(initPagination) setNumPageActive(initPagination + 1);
    }, [initPagination]);

    const onPrevious = () => {
        if (numPageActive > 1) {
            onChangePage(numPageActive -2)
            setNumPageActive(numPageActive - 1) 
        }
    }

    const onNext = () => {
        if (numPageActive < nbPage) {
            onChangePage(numPageActive)
            setNumPageActive(numPageActive + 1)
        }
    }

    return (
        
        <div className="flex justify-center items-center text-neutre font-semibold text-lg">
            
            {nbPage === 0 && nbItems === 0  && <div className="my-6">{t('noResult')}</div>}

            {nbPage > 1 && 
            <div className="my-8">
                <ButtonIcon icon='arrowRight'
                    iconClass={numPageActive > 1 ? "w-8 text-primary" : "w-8 text-neutre"}
                    onClick={() => onPrevious()}
                />
       
                <span className="mx-2">{t('paginationPage', {numPageActive: numPageActive, nbPage: nbPage})}</span>
                
                <ButtonIcon icon='arrowLeft'
                    iconClass={numPageActive < nbPage ? "w-8 text-primary" : "w-8 text-neutre"}
                    onClick={() => onNext()}
                />
            </div>
            }
        </div> 
    );
    

};

export default Pagination;