import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { toFirstUpperCase } from '../../../utils'
import { AuthCtx, HelpCtx } from '../../_context/Context';
import { SaveMember, Gender, Member, MemberRelation, RelationType } from '../../../../../shared/schema/index';
import { newMember } from '../../_object/Member';
import { ButtonFooter, ButtonIcon, Checkbox, ContentCol, ContentRow, ContentBetween, InputText, InputMail, InputTel, ModalConfirm, SubCard, Text, Textarea, Toggle, getMemberName } from '../../../components';
import { MemberRelationCard } from './MemberRelationCard';
import { MemberRelationEditCard } from './MemberRelationEditCard';
import { objectWithoutParse } from '../../_object';

type MemberEditCardProps = {
    key?: React.Key;
    member: Member,
    relationTypes?: {name: string, label:String}[],
    onSaveMember: (s: SaveMember) => Promise<Member | undefined>,
    onDeleteRelation?: (member: Member, childId: string) => Promise<MemberRelation[] | false>
    onCancel: () => void;
}

const MemberEditCard = ({member, relationTypes, onSaveMember, onDeleteRelation, onCancel}: MemberEditCardProps) => { 
    const { t } = useTranslation(['member']);
    const [auth] = useContext(AuthCtx);
    const {help, setHelp} = useContext(HelpCtx);
    const [memberEdit, setMemberEdit] = useState<Member>(member);
    const [showEditRelations, setShowEditRelations] = useState<string[]>([]);
    const [showAddRelation, setShowAddRelation] = useState<boolean>(false);
    const [showForceEmailing, setShowForceEmailing] = useState<boolean>(false);

    const checkValidMember = (): boolean => {
        if (JSON.stringify({...member, relations: undefined}) === JSON.stringify({...memberEdit, relations: undefined})) return false;
    
        if (memberEdit.firstName.length !== 0 &&
            memberEdit.lastName.length !== 0 
        ) return true

        return false
    };

    const addGender = (gender: Gender) => {
        setMemberEdit({...memberEdit, gender: memberEdit.gender !== gender ? gender : null})
    };

    const addEmail = (email: string) => {
        switch(email.length) {
            case 0:
                setMemberEdit({...memberEdit, email: email, emailing: false})
                break;
            case 1:
                if (!memberEdit.noEmailing) setMemberEdit({...memberEdit, email: email, emailing: true})
                break
            default: setMemberEdit({...memberEdit, email: email})
        }
    };

    const forceEmailing = () => {
        setMemberEdit({...memberEdit,
            noEmailing: false,
            emailing: true
        })
        setShowForceEmailing(false);
    };

    const detectChange = (): boolean => {
        let name = false
        let email = false
        
        if (member.firstName !== memberEdit.firstName || member.lastName !== memberEdit.lastName) name = true;
        if (member.email !== memberEdit.email || (member.emailing !== memberEdit.emailing && memberEdit.emailing)) email = true;

        return (name || email) ? true : false
    }

    const saveMember = async() => {
        const updateNameEmail = detectChange()
        const memberSaved = await onSaveMember({type: member.objectId ? 'update' : 'create', member: memberEdit, isUpdateNameEmail: updateNameEmail})
        if (memberSaved) setMemberEdit(memberSaved)
    }

    const saveRelation = async(child: Member, relationTypeParent: RelationType, isUpdateNameEmail: boolean, isUpdateRelation: boolean) => {
        //check relation exist
        const memberExist = memberEdit.relations.find(r => 
            r.member.firstName?.toLowerCase() === child.firstName.toLowerCase() &&
            r.member.lastName?.toLowerCase() === child.lastName.toLowerCase()
        );
        
        if (!child.objectId && memberExist) return toast.error(t('error.relationExist') as string, {theme: 'colored'});

        //const updateNameEmail = detectChange()

        const memberSaved = await onSaveMember({
            type: child.objectId? 'updateRelation' : 'createRelation',
            member: Object.assign({}, memberEdit),
            child: Object.assign({}, objectWithoutParse(child)),
            relation: relationTypeParent,
            isUpdateNameEmail: isUpdateNameEmail,
            isUpdateRelation: isUpdateRelation
        });

        if (memberSaved) {         
            const showEdit = showEditRelations.filter(r => r !== child.objectId);
            setShowEditRelations(showEdit)
            setMemberEdit({...memberEdit, relations: memberSaved.relations});
            setShowAddRelation(false);
        }
    }

    const deleteRelation = async(childId: string) => {
        const relations = await onDeleteRelation?.(memberEdit, childId);
        if (relations) setMemberEdit({...memberEdit, relations: relations})
    }

    return (
        
        <SubCard >

            <ContentBetween>

                <ContentRow>
                    <Checkbox label={t('gender_m')} checked={memberEdit.gender === 'm'} onChange={e => addGender('m')} />
                    <Checkbox label={t('gender_f')} checked={memberEdit.gender === 'f'} onChange={e => addGender('f')} />
                </ContentRow>

                <ButtonIcon icon='edit' className='h-8 w-8 flex justify-center items-center rounded-full bg-warning text-white mb-2' iconClass='w-5 h-5'
                    onClick={onCancel}
                />

            </ContentBetween>

            <ContentCol>

                <InputText placeholder={t('firstName')}
                    value={memberEdit.firstName}
                    onChange={(e) => setMemberEdit({...memberEdit, firstName: toFirstUpperCase(e.target.value)})}
                    autoFocus containerClass='font-semibold bg-white'
                />

                <InputText placeholder={t('lastName')}
                    value={memberEdit.lastName}
                    onChange={(e) => setMemberEdit({...memberEdit, lastName: toFirstUpperCase(e.target.value)})}
                    containerClass='font-semibold bg-white'
                />

                <ContentRow>
                    <Toggle label={t('isMember', {member: getMemberName({t: t, name: auth.siteSetting.memberName})})}
                            checked={memberEdit.isMember ? memberEdit.isMember : false}
                            onChange={() => setMemberEdit({...memberEdit, isMember: !memberEdit.isMember})}
                    />
                    <ButtonIcon icon='help' iconClass={'text-info h-full w-full m-auto'}
                        onClick={() => setHelp({page: 'member', section: 'edit', article: 'isMember'})}
                        className={'h-6 w-6 mb-2 ml-3'}
                    />
                </ContentRow>

                <InputMail placeholder={t('email')}
                    value={memberEdit.email}
                    onChange={(e) => addEmail(e.target.value)}
                />

                <Text showIf={false} text={t('noReceiveEmailing')} containerClass='text-warning mb-2' />

                <ContentRow>
                    <Toggle label={t('emailing')}
                        showIf={memberEdit.email !== ''}
                        checked={memberEdit.emailing}
                        onChange={() => setMemberEdit({...memberEdit, emailing: !memberEdit.emailing})}
                    />
                    <ButtonIcon icon='help' iconClass={'text-info h-full w-full m-auto'}
                    showIf={memberEdit.email !== ''}
                        onClick={() => setHelp({page: 'member', section: 'edit', article: 'emailing'})}
                        className={'h-6 w-6 mb-2 ml-3'}
                    />
                </ContentRow>

                <InputTel placeholder={t('phone')}
                    value={memberEdit.phone ? memberEdit.phone : ''}
                    onChange={(e) => setMemberEdit({...memberEdit, phone: e.target.value})}
                />

                <ButtonIcon showIf={member.objectId !== undefined}
                    icon='more'
                    className='mb-2 ml-1'
                    iconClass='w-7 h-7'
                    onClick={() => {
                        setShowAddRelation(!showAddRelation);
                    }}
                >
                    {t('addRelation')}
                </ButtonIcon>

            
                {showAddRelation && relationTypes &&
                <MemberRelationEditCard
                    relation={{member: newMember(auth.me.userSetting.language)}}
                    lastNameParent={memberEdit.lastName}
                    relationTypes={relationTypes}
                    onSaveRelation={saveRelation}
                    onCancel={() => setShowAddRelation(false)}
                />
                }

                {relationTypes && memberEdit.relations.map((relation) => (
                <MemberRelationCard key={'r-' + relation.member.objectId as string}
                    relation={relation}
                    memberParent={memberEdit}
                    relationTypes={relationTypes}
                    onSaveRelation={saveRelation}
                    onDeleteRelation={() => deleteRelation(relation.member.objectId as string)}
                    showEdit={showEditRelations}
                    setShowEdit={setShowEditRelations}
                />
                ))}

                <Textarea placeholder={t('comment')}
                    value={memberEdit.comment ? memberEdit.comment : ''} 
                    onChange={(e) => setMemberEdit({...memberEdit, comment: e.target.value})}
                />

            </ContentCol>

            <ButtonFooter action='save' showIf={!showAddRelation && showEditRelations.length === 0}
                ifAction={checkValidMember()}
                onValid={() => saveMember()}
                onCancel={() => {
                    setMemberEdit(member)
                    onCancel()
                }}
            />

            <ModalConfirm
                title={t('modal.forceEmailing')}
                open={showForceEmailing}
                onClose={() => setShowForceEmailing(false)}
                onValid={forceEmailing}
            />

        </SubCard>
    );
};


export { MemberEditCard };