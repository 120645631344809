import Suspense from "./Suspense";

//View
interface ViewContainerProps {
  children: React.ReactNode | string;
  showIf?: boolean;
  withFooter?: boolean;
};

const View = ({ children, withFooter=true, showIf= true }: ViewContainerProps) => {
  return (showIf ? (
    <div className={withFooter ? 'w-full absolute top-0 bottom-16 left-0 flex justify-center overflow-y-auto p-4' : 'w-full h-full flex justify-center overflow-y-auto p-4'} >
      <div className='w-full sm:w-2/3 lg:w-1/2 '>
        {children}
      </div>
    </div>
  ) : <Suspense />)
};

const SubView = ({ children, showIf= true}: ViewContainerProps) => {
  return  showIf ? <>{children}</> : null;
};

const ViewFooter = ({ children }: ViewContainerProps) => {
  return <div className={'absolute flex items-center justify-center bottom-0 left-0 right-0 h-16 bg-white bg-opacity-40 z-20'}>{children}</div>;
};


export {
  View,
  SubView,
  ViewFooter,
};
