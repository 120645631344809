import { useTranslation } from 'react-i18next';
import { countries } from '../../params';
import { Address } from '../../../../shared/schema/index';
import { InputText, ContentCol, ContentWrap, Text, TextImportant, CardTitle, ContentCenter, Select } from '../../components';

interface AddressCardProps {
    address?: Address;
    showIf?: boolean;
};

const AddressCard = ({address, showIf= true}: AddressCardProps) => {
    const { t } = useTranslation(['admin', 'countries']);

    return (
        <ContentCol showIf={showIf}>
            <CardTitle levelTitle={2} title={t('address.title')} />

            {address &&
            <ContentCol>
                <Text text={address.street} />
                <Text text={address.addStreet} />
                <Text text={address.state} />
                <ContentWrap>
                    <Text text={address.zipCode} />
                    <Text text={address.city} />
                </ContentWrap>

                <TextImportant text={t(address.country?.toUpperCase() as string, {ns: 'countries'})} />
            </ContentCol>
            }

            <ContentCenter showIf={!address}  >
                <TextImportant text={t('address.noAddress')} />
            </ContentCenter>
            
        </ContentCol>
    )
}

type AddressInputProps = {
    address: Address;
    setAddress: (a : Address) => void;
    showIf?: boolean;
};
const AddressInput = ({address, setAddress, showIf= true}: AddressInputProps) => {
    const { t } = useTranslation(['admin', 'countries']);
    const countryList = countries.map(country => {return {value: country, label: t(country, {ns: 'countries'})}});

    return (

        <ContentCol showIf={showIf}>

            <Select
                options={countryList}
                defaultValue={address.country ? {value: address.country, label: t(address.country, {ns: 'countries'})} : undefined}
                onChange={(country: {value: string, label: string}) => setAddress({...address, country: country.value})}
                isSearchable={true}
                placeholder={t('address.countrySelect')}
                className='mb-4'
            /> 

            <ContentCol showIf={address.country !== undefined}>

                <InputText
                    id='street'
                    value={address.street}
                    onChange={(e) => setAddress({...address, street: e.target.value})}
                    placeholder={t('address.street')}
                    isRequired={true}
                /> 

                <InputText
                    id='addStreet'
                    value={address.addStreet}
                    onChange={(e) => setAddress({...address, addStreet: e.target.value})}
                    placeholder={t('address.addStreet')}
                /> 

                <InputText showIf={true}
                    id='state'
                    value={address.state}
                    onChange={(e) => setAddress({...address, state: e.target.value})}
                    placeholder={t('address.state')}
                /> 
                
                <ContentWrap className='gap-x-4'>
                    <InputText
                        id='city'
                        value={address.city}
                        onChange={(e) => setAddress({...address, city: e.target.value})}
                        placeholder={t('address.city')}
                        containerClass='grow'
                        isRequired={true}
                    /> 

                    <InputText
                        id='zipCode'
                        value={address.zipCode}
                        onChange={(e) => setAddress({...address, zipCode: e.target.value})}
                        placeholder={t('address.zipCode')}
                        containerClass='w-40'
                        isRequired={true}
                    />
                </ContentWrap>


            </ContentCol>

        
        </ContentCol>

    );
};

export { AddressCard, AddressInput };