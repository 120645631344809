import { useState } from 'react';
import { ButtonText, View } from '../../components';

export interface PresenceNav {
  action: 'list' | 'add' | 'default',
};

const PresenceView = () => {

  const [navView, setNavView] = useState<PresenceNav>({action: 'default'});

  return (
    <View>

      <div className='mb-2'>

        <div className='flex items-center justify-between p-2 sm:p-4'>
        
          <ButtonText showIf={navView.action === 'add'}
            text={<span className="material-icons-round text-4xl text-primary ml-4">calendar_month</span>}
            variant='filled-neutre'
            onClick={() => setNavView({action: 'list'})}
          />   

        </div>
        
      </div>


    </View>
  );
};

export { PresenceView };